import React from "react";
import { useDropzone } from "react-dropzone";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Progress,
  Textarea,
  useDisclosure,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import FileLinkIcon from "../../assets/icons/FileLinkIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
import UploadIcon from "../../assets/icons/UploadIcon";
import { PlusIcon } from "../../assets/icons/PlusIcon";
import { useUploadFilesHooks } from "src/hooks/useUploadFilesHooks";

const DnD: React.FC = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { t } = useTranslation();
  const {
    files,
    uploading,
    description,
    setDescription,
    successMessage,
    errorMessage,
    acceptedFileTypes,
    onDrop,
    handleUpload,
    handleCancelUpload,
  } = useUploadFilesHooks(); // Use the custom hook

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
  });

  return (
    <>
      <Button
        className="bg-success text-background"
        endContent={<PlusIcon />}
        size="sm"
        onPress={onOpen}
      >
        {t("UploadNew")}
      </Button>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange} scrollBehavior="inside">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {t("SelectFilesToUpload")}
              </ModalHeader>
              <ModalBody>
                <div
                  {...getRootProps()}
                  className="block w-full py-2 px-3 relative bg-[#F4F4F5] appearance-none border-2 border-[#d9d9d9] border-dashed rounded-md hover:shadow-outline-gray"
                >
                  <input
                    {...getInputProps()}
                    className="absolute inset-0 z-50 m-0 p-0 w-full h-full outline-none opacity-0"
                  />
                  <div className="flex flex-col space-y-2 items-center justify-center p-9">
                    <UploadIcon fillColor="" width="40" height="50" />
                    <p className="text-gray-700">
                      {t('DragYourFilesHereOrClickInThisArea')}
                    </p>
                  </div>
                </div>
                <Textarea
                  label={t('Description')}
                  labelPlacement="outside"
                  placeholder={t('EnterYourDescription')}
                  value={description}
                  maxLength={200} // Limiting the text area to 200 characters
                  onChange={(e) => {
                    // Additional check to ensure text area does not exceed 200 characters
                    const inputValue = e.target.value;
                    if (inputValue.length <= 200) {
                      setDescription(inputValue);
                    }
                  }}
                  className="my-4 w-full"
                />
                {files.length > 0 &&
                  files.map((fileWithProgress) => (
                    <div key={fileWithProgress.id} className="flex items-center space-x-2 mb-2">
                      <div className="flex items-center">
                        <FileLinkIcon width="30" />
                        <span className="text-xs">{fileWithProgress.file.name}</span>
                      </div>
                      <Progress value={fileWithProgress.progress} />
                      <div onClick={() => handleCancelUpload(fileWithProgress.id)}>
                        <TrashIcon color="red" size={20}/>
                      </div>
                    </div>
                  ))}
                {successMessage && (
                  <div className="text-green-500 text-sm mt-2">
                    {successMessage}
                  </div>
                )}
                {errorMessage && (
                  <div className="text-red-500 text-sm mt-2">
                    {errorMessage}
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  {t("Close")}
                </Button>
                <Button color="primary" onPress={handleUpload} disabled={uploading}>
                  {t("Upload")}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default DnD;
