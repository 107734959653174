import React from 'react';

interface ArjIconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly as colors are hardcoded in SVG
}

const ArjIcon: React.FC<ArjIconProps> = ({ width = 40, height = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34 50H6C4.4087 50 2.88258 49.3679 1.75736 48.2426C0.63214 47.1174 0 45.5913 0 44V11L9 0H34C35.5913 0 37.1174 0.632141 38.2426 1.75736C39.3679 2.88258 40 4.4087 40 6V44C40 45.5913 39.3679 47.1174 38.2426 48.2426C37.1174 49.3679 35.5913 50 34 50Z"
      fill="#576D7E"
    />
    <path
      d="M9 9.556C9 9.93897 8.84787 10.3063 8.57706 10.5771C8.30626 10.8479 7.93897 11 7.556 11H0L9 0V9.556Z"
      fill="#8CA0AF"
    />
    <path
      d="M19.6625 30.8328C19.6625 31.9188 18 31.9563 18 30.8578V23.7625C18 23.325 18.3391 23 18.75 23H22.0734C25.2453 23 25.8953 27.2484 23.2469 28.3094L24.9078 30.3328C25.5578 31.2953 24.2594 32.2188 23.5078 31.2703L21.6219 28.5719H19.6625V30.8328ZM19.6625 27.1344H22.0734C23.6969 27.1344 23.7984 24.5109 22.0734 24.5109H19.6625V27.1344Z"
      fill="white"
    />
    <path
      d="M8.50009 31.7141C8.10009 31.4875 7.8501 31.0391 8.1001 30.5141L11.6845 23.5063C12.022 22.8438 12.922 22.8188 13.2345 23.5063L16.7563 30.5141C17.2688 31.4875 15.7563 32.2625 15.3079 31.2891L14.7563 30.1891H10.147L9.60947 31.2891C9.41103 31.7141 8.94853 31.8032 8.50009 31.7141ZM13.872 28.5407L12.461 25.4547L10.9251 28.5407H13.872Z"
      fill="white"
    />
  </svg>
);

export default ArjIcon;
