// src/hooks/useSaveShare.ts

import useShareStore from "src/store/api/useShareStore";

 
const useSaveShare = () => {
  const { saveShare, isLoading, error, successMessage } = useShareStore(state => ({
    saveShare: state.saveShare,
    isLoading: state.isLoading,
    error: state.error,
    successMessage: state.successMessage,
  }));

  return { saveShare, isLoading, error, successMessage };
};

export default useSaveShare;
