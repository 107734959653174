import useFolderStore from 'src/store/api/useFolderStore';
import { useSidebarStore } from 'src/store/layout/useSidebarStore';

const useRefreshListAllFolders = () => {
  const { selectedItemId } = useSidebarStore((state) => ({
    selectedItemId: state.selectedItemId,
  }));
  const { refeshListAllFoldersFiles, isLoading, error, successMessage, items, currentPage, totalPages, totalItems } = useFolderStore((state) => ({
    refeshListAllFoldersFiles: state.refeshListAllFoldersFiles,
    isLoading: state.isFetching,
    error: state.errorFetching,
    successMessage: state.responseMessage,
    items: state.items,
    currentPage: state.currentPage, 
    totalPages: state.totalPages,
    totalItems: state.totalItems,
  }));

  return { refeshListAllFoldersFiles, isLoading, error, successMessage, items, currentPage, totalPages, totalItems, ID: selectedItemId };
};

export default useRefreshListAllFolders;
