import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  useDisclosure,
} from '@nextui-org/react';
// Icons
import { VerticalDotsIcon } from '../../../assets/icons/VerticalDotsIcon';
import { useTranslation } from 'react-i18next';

// File Type Icons
import AiIcon from "../../../assets/icons/Files Types/AiIcon";
import DocIcon from "../../../assets/icons/Files Types/DocIcon";
import PngIcon from "../../../assets/icons/Files Types/PngIcon";
import PdfIcon from "../../../assets/icons/Files Types/PdfIcon";
import TxtIcon from "../../../assets/icons/Files Types/TxtIcon";
import PsdIcon from "../../../assets/icons/Files Types/PsdIcon";
import JpgIcon from "../../../assets/icons/Files Types/JpgIcon";
import JpegIcon from "../../../assets/icons/Files Types/JpegIcon";
import PsbIcon from "../../../assets/icons/Files Types/PsbIcon";
import SvgIcon from "../../../assets/icons/Files Types/SvgIcon";
import Mp4Icon from "../../../assets/icons/Files Types/Mp4Icon";
import AviIcon from "../../../assets/icons/Files Types/AviIcon";
import MovIcon from "../../../assets/icons/Files Types/MovIcon";
import WebmIcon from "../../../assets/icons/Files Types/WebmIcon";
import XlsxIcon from "../../../assets/icons/Files Types/XlsxIcon";
import CsvIcon from "../../../assets/icons/Files Types/CsvIcon";
import PptIcon from "../../../assets/icons/Files Types/PptIcon";
import InddIcon from "../../../assets/icons/Files Types/InddIcon";
import RarIcon from "../../../assets/icons/Files Types/RarIcon";
import ArjIcon from "../../../assets/icons/Files Types/ArjIcon";
import ZipIcon from "../../../assets/icons/Files Types/ZipIcon";
   import { sharedItem } from 'src/types/api/Share';
import PublicFileDetailsModal from 'src/components/modals/PublicFileDetailsModal';

const typesIconMap: Record<string, React.ReactNode> = {
  ai: <AiIcon height={75} width={70} />,
  docx: <DocIcon height={75} width={70} />,
  png: <PngIcon height={80} width={70} />,
  pdf: <PdfIcon height={20} width={70} />,
  txt: <TxtIcon height={75} width={70} />,
  psd: <PsdIcon height={75} width={70} />,
  jpg: <JpgIcon height={75} width={70} />,
  jpeg: <JpegIcon height={75} width={70} />,
  psb: <PsbIcon height={75} width={70} />,
  svg: <SvgIcon height={75} width={70} />,
  mp4: <Mp4Icon height={75} width={70} />,
  avi: <AviIcon height={75} width={70} />,
  mov: <MovIcon height={75} width={70} />,
  webm: <WebmIcon height={75} width={70} />,
  xlsx: <XlsxIcon height={75} width={70} />,
  csv: <CsvIcon height={75} width={70} />,
  pptx: <PptIcon height={75} width={70} />,
  indd: <InddIcon height={75} width={70} />,
  zip: <ZipIcon height={75} width={70} />,
  rar: <RarIcon height={75} width={70} />,
  arj: <ArjIcon height={75} width={70} />,
};

interface FileCardProps {
  file: sharedItem;
}

const PublicFileCard: React.FC<FileCardProps> = ({ file }) => {
   const { t } = useTranslation();
   
  const fileIcon = typesIconMap[file.file_type?.toLocaleLowerCase() || ''];
  const { isOpen: isFolderDetailsOpen, onOpen: onOpenFolderDetails, onClose: onCloseFolderDetails } = useDisclosure();

  const handleView = () => onOpenFolderDetails();

  const handleDownload = (s3_redirect_url: null | string) => {
    if (!s3_redirect_url) {
      console.error('No download URL available');
      return;
    }
    window.open(s3_redirect_url, '_blank');
  };

  return (
    <Card className="lg:w-[23%] w-[20rem] hover:scale-105" key={file.id}>
      <CardHeader className="justify-between">
        <Dropdown>
          <DropdownTrigger>
            <Button isIconOnly radius="full" size="sm" variant="light">
              <VerticalDotsIcon className="text-default-400" />
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownItem onClick={handleView}>{t('ViewDetails')}</DropdownItem>
            <DropdownItem onClick={() => handleDownload(file.s3_redirect_url || '')}>{t('View')}</DropdownItem>
            </DropdownMenu>
        </Dropdown>
      </CardHeader>
        <CardBody className="px-3 py-0 text-small text-center text-default-400">
          <div className="flex justify-center items-center">
            {fileIcon}
          </div>
          <span className="pt-2 capitalize text-gray-900 text-sm font-bold">
          {file.original_filename && file.original_filename.length >= 60
                  ? `${file.original_filename.slice(0, 60)}.....`
                  : file.original_filename || ""}
          </span>
          <span className="pt-2 capitalize">{file.created_at}</span>
          <span className="pt-2 capitalize">
          {file.description && file.description.length >= 60
                  ? `${file.description.slice(0, 60)}.....`
                  : file.description || ""}
          </span>
        </CardBody>
        <CardFooter className="gap-3 w-full">
          <div className='flex justify-between w-full text-gray-400'>
            <div className="pt-2 capitalize">
              <div className='flex justify-center items-start'>
              </div>
            </div>
            <div className="pt-2 capitalize">
              <div className='flex justify-center items-start'>
              </div>
            </div>
          </div>
        </CardFooter>
       <PublicFileDetailsModal isOpen={isFolderDetailsOpen} onClose={onCloseFolderDetails} fileType={file.file_type || ''} fileName = {file.original_filename || ''} fileOwner = {file.full_name_en ==='' ? file.full_name_ar : file.full_name_en || ''} fileSize={file.file_size || 0} fileDescription = {file.description || ''}/>
     </Card>
  );
};

export default PublicFileCard;
