import React from "react";
import Logo from "../../assets/images/mysaff.jpeg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeaderLinks from "./HeaderLinks";
import { useAuthStore } from "src/store/api/useAuthStore";

interface NavBarProps {
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
}

const NavBar: React.FC<NavBarProps> = ({ sidebarOpen, setSidebarOpen }) => {
  const { t } = useTranslation();
  const isSaff = useAuthStore((state) => state.isSaff);
  
  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="relative bg-white shadow dark:bg-gray-800 border">
      <div className="container px-6 py-4 mx-auto">
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="flex items-center justify-between">
            <Link to={isSaff ? "/homepage" : "/Shared"}>
              <div className="flex justify-center items-center gap-5">
                <img className="w-auto h-14 max-sm:h-10 border rounded-xl" src={Logo} alt="Logo" />
                <div className="text-[1.8rem] max-sm:text-2xl max-sm:mt-2 font-bold">{t("SAFFCLOUD")}</div>
              </div>
            </Link>

            <div className="flex lg:hidden">
              <button
                onClick={handleToggle}
                type="button"
                className="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none 
                  focus:text-gray-600 dark:focus:text-gray-400"
                aria-label="toggle menu"
              >
                {!isOpen ? (
                  <svg
                    onClick={(e) => {
                      e.stopPropagation();
                      setSidebarOpen(!sidebarOpen);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 8h16M4 16h16"
                    />
                  </svg>
                ) : (
                  <svg
                    onClick={(e) => {
                      e.stopPropagation();
                      setSidebarOpen(!sidebarOpen);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>

          <div
            className={`absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white lg:mt-0 lg:p-0 lg:top-0 
            lg:relative lg:bg-transparent lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center ${
              isOpen
                ? "translate-x-0 opacity-100"
                : "opacity-0 -translate-x-full hidden"
            }`}
          >
            <HeaderLinks />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
