import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import useFolderStore from "src/store/api/useFolderStore";
import useShareStore from "src/store/api/useShareStore";
 import useRefreshTrash from "src/hooks/useRefreshTrash";
import useRefreshListAllSharedByMe from "src/hooks/useRefreshListAllSharedByMe";
import useRefreshListAllFolders from "src/hooks/useRefreshListAllFolders";
 
interface DeleteFolderModalProps {
  isOpen: boolean;
  onClose: () => void;
  folderId: string;
  folderType: string;
}

const DeleteFolderModal: React.FC<DeleteFolderModalProps> = ({ isOpen, onClose, folderId , folderType}) => {
  const { t } = useTranslation();
  const { deleteFolder } = useFolderStore();
  const { deleteSharedFolder } = useShareStore();
  const { refreshListAllShareByUser, shareByCurrentPage, totalSharedByItems } = useRefreshListAllSharedByMe();
  const { refeshListAllFoldersFiles, currentPage, totalItems } = useRefreshListAllFolders();
  const { fetchTrashedItems,  currentPage: TrashCurrentPage, totalItems: TrashTotalItems } = useRefreshTrash();

   const handleDelete = async () => {

     if (folderType === "myFolder"){
      await deleteFolder(folderId);
      await refreshListAllShareByUser(shareByCurrentPage, totalSharedByItems,);
     }
    else  {
      await deleteSharedFolder(folderId);
      await refeshListAllFoldersFiles(currentPage, totalItems);
    }
    await fetchTrashedItems(TrashCurrentPage, TrashTotalItems);

    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      classNames={{}}
    >
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          {t("DeleteFolder")}
        </ModalHeader>
        <ModalBody>
          <form className="flex flex-col gap-4">
            <h2>
              {t('AreYouSureToDelete')}
            </h2>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" variant="light" onPress={onClose}>
            {t("No")}
          </Button>
          <Button color="danger" onPress={handleDelete}>
            {t("Yes")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteFolderModal;