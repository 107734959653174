import React from 'react';

interface ViewsIconProps extends React.SVGProps<SVGSVGElement> {
  color?: string; // Optional color prop
  width?: string; // Optional width prop
  height?: string; // Optional height prop
}

export const ViewsIcon: React.FC<ViewsIconProps> = ({
  color = '#000000', // Default black color
  width = '24px', // Default width
  height = '24px', // Default height
  ...props
}) => (
  <svg
    fill={color} // Use color from props
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 577.029 577.029"
    width={width} // Use width from props
    height={height} // Use height from props
    {...props}
  >
    <g>
      <path d="M288.514,148.629c73.746,0,136.162,33.616,175.539,61.821c46.652,33.415,70.66,65.737,76.885,78.065 c-6.232,12.327-30.232,44.649-76.885,78.065c-39.377,28.204-101.793,61.82-175.539,61.82c-73.746,0-136.161-33.616-175.539-61.82 c-46.661-33.416-70.66-65.738-76.894-78.065c6.234-12.328,30.233-44.65,76.885-78.065 C152.353,182.245,214.768,148.629,288.514,148.629 M288.514,113.657C129.176,113.657,0,253.543,0,288.515 s129.176,174.857,288.514,174.857c159.339,0,288.515-139.886,288.515-174.857S447.854,113.657,288.514,113.657L288.514,113.657z M288.514,183.601c-57.939,0-104.914,46.975-104.914,104.914c0,57.938,46.975,104.914,104.914,104.914 s104.914-46.976,104.914-104.914C393.428,230.576,346.453,183.601,288.514,183.601z M260.266,288.515 c-24.515,0-44.388-19.873-44.388-44.388c0-24.515,19.873-44.387,44.388-44.387c24.515,0,44.388,19.873,44.388,44.387 C304.654,268.642,284.781,288.515,260.266,288.515z"></path>
    </g>
  </svg>
);

export default ViewsIcon;
