import { useState, useCallback } from 'react';
import useFolderStore from 'src/store/api/useFolderStore';
import { FolderDetails } from 'src/types/api/Folder';

const useFolderDetails = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [folderDetails, setFolderDetails] = useState<FolderDetails | null>(null);

  const getFolderDetails = useCallback(async (folderId: string) => {
    setIsProcessing(true);
    setError(null);
    try {
      await useFolderStore.getState().getFolderDetails(folderId);
      const state = useFolderStore.getState();
      setFolderDetails(state.folderDetails);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsProcessing(false);
    }
  }, []);

  return {
    getFolderDetails,
    setFolderDetails,
    isProcessing,
    error,
    folderDetails,
  };
};

export default useFolderDetails;
