import { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Avatar,
  Input,
} from '@nextui-org/react';
import { PlusIcon } from '../../assets/icons/PlusIcon';
import { VerticalDotsIcon } from '../../assets/icons/VerticalDotsIcon';
import { SearchIcon } from '../../assets/icons/SearchIcon';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AddNewUserModal from '../modals/AddNewUserModal';
import EditRolesModal from '../modals/EditUserRolesModal';
import { User as ApiUser } from 'src/types/api/UserList';
import { useFetchUsers, useUserActions } from 'src/hooks/useUsersListHooks';
import { useEditRolesModalStore } from 'src/store/api/useUserListStore';
import { Pagination } from 'antd';

export default function Users() {
  const { t } = useTranslation();
  const { loading } = useFetchUsers();
  const {
    handleRowsPerPageChangeUsers,
    handleSearchChangeUsers,
    setPageUsers,
    pageUsers,
    rowsPerPageUsers,
    users,
    totalPagesUsers,
    totalUsersUsers,
  } = useUserActions();

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [currentUser, setCurrentUser] = useState<ApiUser | null>(null); // Store current user

  const {
    isOpen: isEditRolesModalOpen,
    setIsOpen: setEditRolesModalOpen,
  } = useEditRolesModalStore();

  const handleEditRoles = (user: ApiUser) => {
    setCurrentUser(user);
    setEditRolesModalOpen(true);
  };


  if (loading) return <div>{t('Loading')}</div>;

  const filteredUsers = users.filter(user =>
    (user.full_name_en || '').toLowerCase().includes(filterValue.toLowerCase()) ||
    (user.email || '').toLowerCase().includes(filterValue.toLowerCase())
  );

  const paginatedUsers = filteredUsers;

  return (
    <div className="w-full p-4">
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <Input
            isClearable
            classNames={{
              base: 'lg:w-full sm:max-w-[44%]',
              inputWrapper: 'border-1 bg-white',
            }}
            placeholder={t('SearchByName')}
            startContent={<SearchIcon className="text-default-300" />}
            value={filterValue}
            variant="bordered"
            onClear={() => setFilterValue('')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFilterValue(e.target.value);
              handleSearchChangeUsers(e.target.value);
            }}
          />
          <div className="flex gap-3">
            <Button
              className="ml-3 text-white"
              color="success"
              endContent={<PlusIcon />}
              size="sm"
              onClick={() => setIsAddUserModalOpen(true)}
            >
              {t('NewUser')}
            </Button>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-default-400 text-small">
            {t('TotalUsers')}: {totalUsersUsers}
          </span>
          {/* <div className="flex items-center">
            <label className="mr-2 text-default-400 text-small">
              {t('RowsPerPage')}
            </label>
            <select
              className="border border-default-200 rounded px-2 py-1"
              value={rowsPerPageUsers}
              onChange={e => handleRowsPerPageChangeUsers(Number(e.target.value))}
            >
              {[5, 10, 15, 50, 100].map(size => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div> */}
        </div>
      </div>
      <div className="flex flex-wrap gap-6 justify-start items-center mt-4">
        {paginatedUsers.map(user => (
          <Card className="lg:w-[23%] w-[20rem] hover:scale-105" key={user.user_id}>
            <CardHeader className="justify-end">
              <Dropdown>
                <DropdownTrigger>
                  <Button isIconOnly radius="full" size="sm" variant="light">
                    <VerticalDotsIcon className="text-default-400" />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu>
                  <DropdownItem onClick={() => handleEditRoles(user)}>{t('Edit')}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </CardHeader>
            <Link to="">
              <CardBody className="px-3 py-0 text-small text-center text-default-400">
                <div className="flex justify-center items-center">
                  <Avatar showFallback src="https://images.unsplash.com/broken" />
                </div>
                <span className="pt-2 capitalize text-gray-900 text-lg font-bold">
                  {user.full_name_en || 'No Name'}
                </span>
                <span className="pt-2 capitalize text-gray-900 text-lg font-bold">
                  {user.id ||0}
                </span>
                <span className="pt-2 capitalize"> {user.email || 'No Email'} </span>
              </CardBody>
              <CardFooter className="gap-3"></CardFooter>
            </Link>
          </Card>
        ))}
      </div>
      <div className="py-2 px-2 flex justify-between items-center mt-4">
      <Pagination
          current={pageUsers}
          total={totalPagesUsers * rowsPerPageUsers} // Ant Design uses total items instead of total pages
          pageSize={rowsPerPageUsers}
          onChange={setPageUsers}
          showSizeChanger={false}
        />
        <span className="text-small text-default-400">
          {pageUsers} {t('of')} {totalPagesUsers} {t('pages')}
        </span>
      </div>
      <AddNewUserModal
        isOpen={isAddUserModalOpen}
        onClose={() => setIsAddUserModalOpen(false)}
      />
      <EditRolesModal
        isOpen={isEditRolesModalOpen}
        onClose={() => setEditRolesModalOpen(false)}
        user={currentUser as ApiUser} // Pass the current user object
      />
    </div>
  );
}
