import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthStore } from 'src/store/api/useAuthStore';
import { useUploadFileStore } from "src/store/layout/useUploadFilesStore";
import { useDownloadStore } from 'src/store/layout/useDownloadStore'; // Import the download store
import { useTranslation } from "react-i18next";
import useFolderStore from "src/store/api/useFolderStore";
import useFilesStore from "src/store/layout/useFilesStore";
import { useFolderStore as useFolderStoreId } from 'src/components/main_page/cards/FolderCard';
export const useUploadFilesHooks = () => {
  const { files, uploading, description, setDescription, addFiles, removeFile, uploadFiles, uploadSharedByFiles, uploadSharedWithFiles } = useUploadFileStore();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const token = useAuthStore(state => state.token);
  const { listAllFoldersFiles, totalItems } = useFolderStore();
  const { fetchFiles, fetchSharedWithFiles, fetchSharedByFiles } = useFilesStore();
  const { t } = useTranslation();
  const folderId = useFolderStoreId.getState().folderId;
  const { downloads, addDownload, updateProgress } = useDownloadStore(); // Use the download store
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  useEffect(() => {
    const handleClick = () => setSuccessMessage(null);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const acceptedFileTypes = {
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    "application/pdf": [".pdf"],
    "text/plain": [".txt"],
    "image/vnd.adobe.photoshop": [".psd", ".psb"],
    "application/postscript": [".ai"],
    "image/jpeg": [".jpg", ".jpeg"],
    "image/png": [".png"],
    "image/svg+xml": [".svg"],
    "video/mp4": [".mp4"],
    "video/x-msvideo": [".avi"],
    "video/quicktime": [".mov"],
    "video/webm": [".webm"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "text/csv": [".csv"],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
    "application/x-indesign": [".indd"],
    "application/zip": [".zip"],
    "application/x-rar-compressed": [".rar"],
    "application/x-arj": [".arj"],
  };

  const onDrop = (acceptedFiles: File[]) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      progress: 0,
      id: `${file.name}-${file.size}-${file.lastModified}`,
      cancelTokenSource: axios.CancelToken.source(),
    }));

    addFiles(newFiles); // Add to the upload store

    // Add each file to the download store with initial progress of 0%
    newFiles.forEach((file) => {
      const existingDownload = downloads.find((download) => download.fileName === file.file.name);
      if (!existingDownload) {
        addDownload({ id: file.id, fileName: file.file.name, progress: 0 });
      }
    });
  };

  const handleUpload = async () => {
    if (token) {
      try {
        for (const file of files) {
          let progress = 0;
  
          const interval = setInterval(() => {
            progress += 10;
            console.log(`Updating progress for ${file.file.name}: ${progress}%`); // Debugging log
            updateProgress(file.id, progress);
  
            if (progress >= 100) {
              clearInterval(interval);
            }
          }, 500);
        }
  
        await uploadFiles(token, description);
        setSuccessMessage(t('UploadSuccessful'));
  
        if (folderId) {
          await fetchFiles();
        } else {
          await listAllFoldersFiles(1, totalItems);
        }
      } catch (error) {
        console.error("Upload failed or fetch folders failed:", error);
        setErrorMessage(t('UploadFailed')); // Set error message here
      }
    }
  };
  
  const handleSharedWithUpload = async () => {
    if (token) {
      try {
        console.log('handleSharedWithUpload');
        await uploadSharedWithFiles(token, description);
        setSuccessMessage(t('UploadSuccessful'));
        console.log("UploadSuccessful UploadSuccessful");
        await fetchSharedWithFiles();
      } catch (error) {
        console.error("Upload failed or fetch shared with files failed:", error);
        setErrorMessage(t('UploadFailed')); // Set error message here
      }
    }
  };
  
  const handleSharedByUpload = async () => {
    if (token) {
      try {
        console.log('handleSharedByUpload');
        await uploadSharedByFiles(token, description);
        setSuccessMessage(t('UploadSuccessful'));
        console.log("UploadSuccessful UploadSuccessful");
        await fetchSharedByFiles();
      } catch (error) {
        console.error("Upload failed or fetch shared by files failed:", error);
        setErrorMessage(t('UploadFailed')); // Set error message here
      }
    }
  };
  

  const handleCancelUpload = (id: string) => {
    removeFile(id);
  };

  return {
    files,
    uploading,
    description,
    setDescription,
    successMessage,
    errorMessage,
    acceptedFileTypes,
    onDrop,
    handleUpload,
    handleSharedWithUpload,
    handleSharedByUpload,
    handleCancelUpload,
  };
};
