import React from 'react';

interface AviIconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly as colors are hardcoded in SVG
}

const AviIcon: React.FC<AviIconProps> = ({ width = 40, height = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34 50H6C4.4087 50 2.88258 49.3679 1.75736 48.2426C0.63214 47.1174 0 45.5913 0 44V11L9 0H34C35.5913 0 37.1174 0.632141 38.2426 1.75736C39.3679 2.88258 40 4.4087 40 6V44C40 45.5913 39.3679 47.1174 38.2426 48.2426C37.1174 49.3679 35.5913 50 34 50Z"
      fill="#F15642"
    />
    <path
      d="M9 9.556C9 9.93897 8.84787 10.3063 8.57706 10.5771C8.30626 10.8479 7.93897 11 7.556 11H0L9 0V9.556Z"
      fill="#FFB9B9"
    />
    <path
      d="M10.5001 38.7151C10.1001 38.4885 9.8501 38.0401 10.1001 37.5151L13.686 30.5073C14.0235 29.8448 14.922 29.8198 15.2345 30.5073L18.7579 37.5151C19.2704 38.4885 17.7579 39.2635 17.3079 38.2885L16.7579 37.1901H12.1485L11.6126 38.2901C11.4126 38.7151 10.9501 38.8026 10.5001 38.7151ZM15.872 35.5416L14.4595 32.4557L12.9235 35.5416H15.872Z"
      fill="white"
    />
    <path
      d="M22.2377 38.1525L18.9392 31.2447C18.4517 30.2572 19.9267 29.4463 20.4752 30.4822L21.5877 32.9932L23.0236 36.29L24.4345 32.9932L25.547 30.4822C26.047 29.5338 27.4955 30.1322 27.072 31.1697L23.8861 38.1525C23.5986 38.94 22.7111 39.1275 22.2377 38.1525Z"
      fill="white"
    />
    <path
      d="M28.6125 30.8182C28.6125 29.7947 30.2625 29.7557 30.2625 30.8182V37.951C30.2625 38.9885 28.6125 39.0135 28.6125 37.951V30.8182Z"
      fill="white"
    />
    <path
      d="M20 27C23.866 27 27 23.866 27 20C27 16.134 23.866 13 20 13C16.134 13 13 16.134 13 20C13 23.866 16.134 27 20 27Z"
      fill="white"
    />
    <path
      d="M19.014 23.3019C18.3759 23.7148 17.854 23.4309 17.854 22.6709V17.3289C17.854 16.5691 18.3759 16.285 19.014 16.6977L22.9571 19.2492C23.5951 19.6621 23.5951 20.3378 22.9571 20.7501L19.014 23.3019Z"
      fill="#F15642"
    />
  </svg>
);

export default AviIcon;
