import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { useUserActions } from "src/hooks/useUsersListHooks";
import { Alert } from "antd";

interface AddNewUserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddNewUserModal: React.FC<AddNewUserModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const {
    nameEn,
    nameAr,
    email,
    phone,
    password,
    setNameEn,
    setNameAr,
    setEmail,
    setPhone,
    setPassword,
    reset,
    handleRegisterUser,
    registrationStatus,
    registrationMessage,
    resetRegistrationStatus,
  } = useUserActions();

  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const resetForm = useCallback(() => {
    reset();
    setConfirmPassword("");
    setErrors({});
    resetRegistrationStatus();
  }, [reset, resetRegistrationStatus]);

  useEffect(() => {
    if (isOpen) {
      resetForm();
    }
  }, [isOpen, resetForm]);

  const validateInputs = () => {
    const validationErrors: { [key: string]: string } = {};
    if (!nameEn) validationErrors.nameEn = t("UserNameENIsRequired");
    if (!nameAr) validationErrors.nameAr = t("UserNameARIsRequired");
    if (!email) validationErrors.email = t("EmailIsRequired");
    if (!phone) validationErrors.phone = t("PhoneNumberIsRequired");
    if (!password) validationErrors.password = t("PasswordIsRequired");
    if (password !== confirmPassword) {
      validationErrors.confirmPassword = t("PasswordsDoNotMatch");
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateInputs()) {
      return;
    }
    await handleRegisterUser();
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal scrollBehavior="inside" size="2xl" isOpen={isOpen} onClose={handleClose}>
      <ModalContent>
        <ModalHeader className="text-lg font-bold">{t("AddNewUser")}</ModalHeader>
        <ModalBody>
          {/* Display the registration message */}
          {registrationMessage && (
            <Alert message={registrationMessage} type={registrationStatus === "success" ? "success" : "error"} showIcon closable />
          )}

          <form className="flex flex-col gap-4">
            <div className="flex flex-col">
              <label className="text-sm font-medium mb-1">
                {t("UserNameEN")} <span className="text-red-500">*</span>
              </label>
              <Input
                type="text"
                value={nameEn}
                onChange={(e) => setNameEn(e.target.value)}
                required
                isInvalid={!!errors.nameEn}
                className="text-sm"
              />
              {errors.nameEn && <p className="text-xs text-red-500 mt-1">{errors.nameEn}</p>}
            </div>

            <div className="flex flex-col">
              <label className="text-sm font-medium mb-1">
                {t("UserNameAR")} <span className="text-red-500">*</span>
              </label>
              <Input
                type="text"
                value={nameAr}
                onChange={(e) => setNameAr(e.target.value)}
                required
                isInvalid={!!errors.nameAr}
                className="text-sm"
              />
              {errors.nameAr && <p className="text-xs text-red-500 mt-1">{errors.nameAr}</p>}
            </div>

            <div className="flex flex-col">
              <label className="text-sm font-medium mb-1">
                {t("Email")} <span className="text-red-500">*</span>
              </label>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                isInvalid={!!errors.email}
                className="text-sm"
              />
              {errors.email && <p className="text-xs text-red-500 mt-1">{errors.email}</p>}
            </div>

            <div className="flex flex-col">
              <label className="text-sm font-medium mb-1">
                {t("PhoneNumber")} <span className="text-red-500">*</span>
              </label>
              <Input
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
                isInvalid={!!errors.phone}
                className="text-sm"
              />
              {errors.phone && <p className="text-xs text-red-500 mt-1">{errors.phone}</p>}
            </div>

            <div className="flex flex-col">
              <label className="text-sm font-medium mb-1">
                {t("Password")} <span className="text-red-500">*</span>
              </label>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                isInvalid={!!errors.password}
                className="text-sm"
                autoComplete="new-password"
              />
              {errors.password && <p className="text-xs text-red-500 mt-1">{errors.password}</p>}
            </div>

            <div className="flex flex-col">
              <label className="text-sm font-medium mb-1">
                {t("ConfirmPassword")} <span className="text-red-500">*</span>
              </label>
              <Input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                isInvalid={!!errors.confirmPassword}
                className="text-sm"
                autoComplete="new-password"
              />
              {errors.confirmPassword && <p className="text-xs text-red-500 mt-1">{errors.confirmPassword}</p>}
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" variant="light" className="text-sm" onPress={handleClose}>
            {t("Close")}
          </Button>
          <Button color="primary" className="text-sm" onPress={handleSave}>
            {t("Save")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddNewUserModal;
