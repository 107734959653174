import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useEditRolesModalStore } from "src/store/api/useUserListStore";
import { useFetchRoles } from "src/hooks/useRoleHooks";
import { Alert } from "antd";
import { User } from "src/types/api/UserList";

interface EditRolesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (roles: string[]) => Promise<void>;
  user: User | null;
}

const EditRolesModal: React.FC<EditRolesModalProps> = ({
  isOpen,
  onClose,
  onSave,
  user,
}) => {
  const { t } = useTranslation();
  const { roles, setRoles } = useEditRolesModalStore();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { roles: fetchedRoles, isLoadingFetch, errorFetch } = useFetchRoles();

  const roleOptions = fetchedRoles.map((role) => ({
    value: role.role_id || '', // Ensure value is always a string
    label: role.role_name_en,
  }));

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: '0.5rem',
      backgroundColor: '#F4F4F5',
      borderColor: 'transparent',
      boxShadow: 'none',
      marginTop: '1.25rem',
      '&:hover': {
        borderColor: 'transparent',
      },
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: 'white',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: 'black',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#73737C',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: '#73737C',
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const handleSave = async () => {
    if (roles.length === 0) {
      setErrorMessage(t("PleaseSelectAtLeastOneRole"));
      return;
    }

    if (!user?.user_id) {
      setErrorMessage(t("UserIdIsMissing"));
      return;
    }

    setIsSaving(true);
    setErrorMessage(null);

    try {
      await onSave(roles);
      setIsSaving(false);
      onClose();
    } catch (error) {
      setErrorMessage(t("FailedToSaveRoles"));
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (user) {
      // Set the roles for the user
      setRoles(user.role_id || []);
    }
  }, [user, setRoles]);

  return (
    <Modal scrollBehavior="outside" size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>{t("EditUserRoles")}</ModalHeader>
        <ModalBody>
          {errorMessage && (
            <Alert message={errorMessage} type="error" showIcon closable />
          )}
          {isLoadingFetch && <div>{t("LoadingRoles")}</div>}
          {errorFetch && <div>{t("ErrorLoadingRoles")}</div>}
          <form className="flex flex-col gap-4">
            <Select
              isMulti
              required
              options={roleOptions}
              value={roleOptions.filter(option => roles.includes(option.value || ''))}
              onChange={(selectedOptions) =>
                setRoles(selectedOptions ? selectedOptions.map(option => option.value || '') : [])
              }
              placeholder={t("SelectRoles")}
              styles={customStyles}
              isDisabled={isSaving}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" variant="light" onPress={onClose} disabled={isSaving}>
            {t("Close")}
          </Button>
          <Button color="primary" onPress={handleSave} isLoading={isSaving}>
            {t("Save")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditRolesModal;