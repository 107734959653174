// src/hooks/useCreateDirectory.ts
import { useDirectoryStore } from '../store/api/useDirectoryStore';

const useCreateDirectory = () => {
  const { createDirectory, isLoading, error, successMessage } = useDirectoryStore(state => ({
    createDirectory: state.createDirectory,
    isLoading: state.isLoading,
    error: state.error,
    successMessage: state.successMessage,
  }));

  return { createDirectory, isLoading, error, successMessage };
};

export default useCreateDirectory;
