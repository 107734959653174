import React from "react";

interface IconProps extends React.SVGProps<SVGSVGElement> {
  color?: string; // Optional color prop
}

export const EnIcon: React.FC<IconProps> = ({ color = "white", ...props }) => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 18.5C1 10.2504 1 6.12563 3.56282 3.56282C6.12563 1 10.2504 1 18.5 1C26.7495 1 30.8744 1 33.4371 3.56282C36 6.12563 36 10.2504 36 18.5C36 26.7495 36 30.8744 33.4371 33.4371C30.8744 36 26.7495 36 18.5 36C10.2504 36 6.12563 36 3.56282 33.4371C1 30.8744 1 26.7495 1 18.5Z"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M9.152 12.606H16.604V14.496H11.51V17.178H15.758V19.068H11.51V22.11H16.838V24H9.152V12.606ZM20.8675 16.368V24H18.5455V12.606H20.5075C22.4275 15.102 24.3355 17.724 26.2315 20.472V12.606H28.5895V24H26.5735C25.4455 22.668 23.5435 20.124 20.8675 16.368Z"
      fill={color}
    />
  </svg>
);

export default EnIcon;
