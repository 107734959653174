import React from 'react'
import NavBar from "../components/header/NarBar";
 import MainSidebar from '../components/sidebar/MainSidebar';
import { useTranslation } from 'react-i18next';
import FavoriteTable from 'src/components/favorite_page/FavoriteTable';
import {
  Breadcrumbs,
  BreadcrumbItem,
} from '@nextui-org/react';

const Favorite: React.FC = () => {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  return (
    <div className="bg-main min-h-screen overflow-x-hidden">
      <NavBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex">
        <MainSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="flex-1 p-4 overflow-x-hidden">
          {/* Breadcrumbs */}
          <Breadcrumbs isDisabled>
            <BreadcrumbItem>{t("SAFFCLOUD")}</BreadcrumbItem>
            <BreadcrumbItem>{t("Favorite")}</BreadcrumbItem>
          </Breadcrumbs>

          <div className="text-[33px] capitalize text-navy-700 mt-4 mb-5">
            <div className="font-bold capitalize hover:text-navy-700">
              {t("Favorite")}
            </div>
          </div>

          <div>
            <FavoriteTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Favorite;
