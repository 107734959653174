import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Switch,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { Role } from "src/types/api/ListRole";
import { useAddNewRoleModalStore, useRoleStore } from "src/store/api/useRoleStore";
import { Alert } from "antd";

interface EditRoleModalProps {
  isOpen: boolean;
  role: Role | null;
  onClose: () => void;
}

const EditRoleModal: React.FC<EditRoleModalProps> = ({ isOpen, role, onClose }) => {
  const { t } = useTranslation();
  
  const {
    nameEn,
    descriptionEn,
    nameAr,
    descriptionAr,
    setNameEn,
    setDescriptionEn,
    setNameAr,
    setDescriptionAr,
  } = useAddNewRoleModalStore();

  const [permissions, setPermissions] = useState({
    is_supper_admin: false,
    is_administrators: false,  // Set based on the switch state
  });

  const { updateRole, responseStatus, responseMessage, resetResponseStatus } = useRoleStore();

  // Use useEffect to update the state when the role changes
  useEffect(() => {
    if (role) {
      setNameEn(role.role_name_en || '');
      setDescriptionEn(role.role_description_en || '');
      setNameAr(role.role_name_ar || '');
      setDescriptionAr(role.role_description_ar || '');

      setPermissions({
        is_supper_admin: role.is_supper_admin === 1,  
        is_administrators: role.is_administrators === 1,  // Set based on the switch state
      });
    }
  }, [role, setNameEn, setDescriptionEn, setNameAr, setDescriptionAr]);

  const handleSave = async () => {
    if (role) {
    const roleData: Role = {
      role_name_en: nameEn,
      role_description_en: descriptionEn,
      role_name_ar: nameAr,
      role_description_ar: descriptionAr,
      create_role: 0,
      delete_role: 0,
      edit_role: 0,
      read_role: 0,
      users_saff_read: 0,
      users_saff_edit_role: 0,
      create_users_outside_saff: 0,
      read_users_outside_saff: 0,
      edit_users_outside_saff: 0,
      delete_users_outside_saff: 0,
      share_delete_my_users_with_another: 0,
      share_read_my_users_with_another: 0,
      share_edit_my_users_with_another: 0,
      share_create_my_users_with_another: 0,
      is_supper_admin:  0,
      is_administrators:  0,  // Set based on the switch state
      ...Object.fromEntries(
        Object.entries(permissions).map(([key, value]) => [key, value ? 1 : 0])
      ),
    };

      await updateRole(role.role_id || '', roleData);
  
      if (responseStatus === "success") {
        resetResponseStatus();
      }
    }
  };
  
  const handleClose = () => {
    resetResponseStatus();
    onClose();
  };
  
  const handlePermissionChange = (permission: keyof typeof permissions) => {
    setPermissions((prev) => ({
      ...prev,
      [permission]: !prev[permission],
    }));
  };

  return (
    <Modal scrollBehavior="inside" size="2xl" isOpen={isOpen} onClose={handleClose}>
      <ModalContent>
        <ModalHeader>{t("EditRole")}</ModalHeader>
        <ModalBody>
          {responseMessage && (
            <Alert message={responseMessage} type={responseStatus === "success" ? "success" : "error"} showIcon closable />
          )}
          <Input
            label={t("RoleNameEN")}
            value={nameEn}
            onChange={(e) => setNameEn(e.target.value)}
          />
          <Input
            label={t("RoleDescriptionEN")}
            value={descriptionEn}
            onChange={(e) => setDescriptionEn(e.target.value)}
          />
          <Input
            label={t("RoleNameAR")}
            value={nameAr}
            onChange={(e) => setNameAr(e.target.value)}
          />
          <Input
            label={t("RoleDescriptionAR")}
            value={descriptionAr}
            onChange={(e) => setDescriptionAr(e.target.value)}
          />
                  <div className="mt-3 mb-1 text-lg font-semibold">
              {t("SelectPermissions")}
            </div>
          {/* Permission Switches */}
          {Object.keys(permissions).map((permission) => (
            <div key={permission} className="flex justify-between items-center">
              <span>{t(permission)}</span>
              <Switch
                isSelected={permissions[permission as keyof typeof permissions]}
                onChange={() => handlePermissionChange(permission as keyof typeof permissions)}
              />
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" variant="light" onPress={handleClose}>
            {t("Close")}
          </Button>
          <Button color="primary" onPress={handleSave}>
            {t("Save")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditRoleModal;
