import React from 'react';

interface PngIconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly as colors are hardcoded in SVG
}

const PngIcon: React.FC<PngIconProps> = ({ width = 40, height = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34 50H6C4.4087 50 2.88258 49.3679 1.75736 48.2426C0.63214 47.1174 0 45.5913 0 44V11L9 0H34C35.5913 0 37.1174 0.632141 38.2426 1.75736C39.3679 2.88258 40 4.4087 40 6V44C40 45.5913 39.3679 47.1174 38.2426 48.2426C37.1174 49.3679 35.5913 50 34 50Z"
      fill="#A066AA"
    />
    <path
      d="M9 9.556C9 9.93897 8.84787 10.3063 8.57706 10.5771C8.30626 10.8479 7.93897 11 7.556 11H0L9 0V9.556Z"
      fill="#D59CDF"
    />
    <path
      d="M7 23.9734C7 23.5609 7.32344 23.1094 7.84844 23.1094H10.7359C12.3594 23.1094 13.8219 24.1969 13.8219 26.2828C13.8219 28.2578 12.3594 29.3563 10.7359 29.3563H8.64687V31.0063C8.64687 31.5578 8.3 31.8688 7.84844 31.8688C7.43594 31.8688 7 31.5578 7 31.0063V23.9734ZM8.64687 24.6844V27.7953H10.7344C11.5703 27.7953 12.2328 27.0578 12.2328 26.2828C12.2328 25.4078 11.5703 24.6844 10.7344 24.6844H8.64687Z"
      fill="white"
    />
    <path
      d="M15.414 24.0984C15.414 23.6469 15.514 23.2109 16.164 23.2109C16.6125 23.2109 16.714 23.3234 17.05 23.6469L21.1843 28.8203V23.9734C21.1843 23.5609 21.5453 23.1094 21.9719 23.1094C22.4219 23.1094 22.8578 23.5609 22.8578 23.9734V31.0063C22.8578 31.5578 22.5203 31.7672 22.1953 31.8688C21.7593 31.8688 21.5453 31.7688 21.1843 31.4328L17.05 26.1844V31.0078C17.05 31.5594 16.7125 31.8703 16.2625 31.8703C15.8125 31.8703 15.4125 31.5594 15.4125 31.0078V24.0984H15.414Z"
      fill="white"
    />
    <path
      d="M32.2563 30.9078C31.3688 31.6438 30.2813 31.9578 29.1828 31.9578C26.5578 31.9578 24.6969 30.4578 24.6969 27.4844C24.6969 24.9609 26.6594 23 29.2938 23C30.2813 23 31.3672 23.3375 32.1422 24.1C32.9047 24.8484 31.8047 25.9609 31.0563 25.3C30.5938 24.8484 29.9438 24.5125 29.2938 24.5125C27.7828 24.5125 26.3219 25.7234 26.3219 27.4844C26.3219 29.3344 27.5453 30.4578 29.1828 30.4578C29.9438 30.4578 30.5938 30.2328 31.0563 29.8953V28.3609H29.1828C28.0703 28.3609 28.1828 26.8344 29.1828 26.8344H31.6813C32.1438 26.8344 32.5672 27.1859 32.5672 27.5734V30.2344C32.5672 30.4578 32.4688 30.6688 32.2563 30.9078Z"
      fill="white"
    />
  </svg>
);

export default PngIcon;
