import { create } from 'zustand';
import { useAuthStore } from './useAuthStore';
import {
  updateFolder,
  createFolder,
  deleteExpiredFolders,
  listAllFolders,
  copyFolder,
  // restoreFolder,
  getFolderDetails,
  uploadFolder,
  listAllFoldersFiles,
  deleteFolderApi,
  emptyTrashFolder,
} from 'src/services/api/API';
import { useSidebarStore } from 'src/store/layout/useSidebarStore';

interface FolderState {
  folderName: string;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isFetching: boolean;
  isCopying: boolean;
  isRestoring: boolean;
  isUploading: boolean;
  isDownloading: boolean;
  isEmptyingTrash: boolean;
  errorCreating: string | null;
  errorUpdating: string | null;
  errorDeleting: string | null;
  errorFetching: string | null;
  errorCopying: string | null;
  errorRestoring: string | null;
  errorUploading: string | null;
  errorDownloading: string | null;
  errorEmptyingTrash: string | null;
  responseMessage: string | null;
  responseStatus: 'success' | 'error' | null;
  folders: any[] | null;
  folderDetails: any | null;
  items: any[] | null;
  currentPage: number;
  totalPages: number;
  totalItems: number;
  rowsPerPage: number;
  lastFetchedPage: number | null;
  lastFetchedItemId: string | null;
  setRowsPerPage: (rows: number) => void;
  setFolderName: (name: string) => void;
  createNewFolder: () => Promise<void>;
  updateFolder: (folderId: string, updatedData: any) => Promise<void>;
  deleteExpiredFolders: () => Promise<void>;
  listAllFolders: () => Promise<void>;
  listAllFoldersFiles: (page: number, rowsPerPage: number) => Promise<void>;
  copyFolder: (folderId: string, params: { directory_id: string; parent_folder_id: string }) => Promise<void>;
  // restoreFolder: (folderId: string) => Promise<void>;
  getFolderDetails: (folderId: string) => Promise<void>;
  uploadFolder: (data: { parent_folder_id: string; name: string; directory_id: string; files: File[] }) => Promise<void>;
  downloadFolder: () => Promise<void>;
  deleteFolder: (folderId: string) => Promise<void>;
  emptyTrashFolders: () => Promise<void>;
  resetState: () => void;
  fetchFolders: (page: number) => Promise<void>;
  refeshListAllFoldersFiles: (page: number, rowsPerPage: number) => Promise<void>;

}

const useFolderStore = create<FolderState>((set, get) => ({
  folderName: '',
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isFetching: false,
  isCopying: false,
  isRestoring: false,
  isUploading: false,
  isDownloading: false,
  isEmptyingTrash: false,
  errorCreating: null,
  errorUpdating: null,
  errorDeleting: null,
  errorFetching: null,
  errorCopying: null,
  errorRestoring: null,
  errorUploading: null,
  errorDownloading: null,
  errorEmptyingTrash: null,
  responseMessage: null,
  responseStatus: null,
  folders: null,
  folderDetails: null,
  items: null,
  currentPage: 1,
  totalPages: 1,
  totalItems: 0,
  lastFetchedPage: null,
  rowsPerPage: 10,
  lastFetchedItemId: null,
  setFolderName: (name: string) => set({ folderName: name }),
  setRowsPerPage: (rows) => set({ rowsPerPage: rows }),
  resetState: () => set({ folderName: '', responseMessage: null, responseStatus: null }),
  setPage: (page: number) => {
    set(() => {
      console.log('Setting page to:', page);
      return { currentPage: page };
    });
  },


  deleteExpiredFolders: async () => {
    set({ isDeleting: true, errorDeleting: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      await deleteExpiredFolders(token);
      set({ responseMessage: 'Expired folders deleted successfully.', responseStatus: 'success', isDeleting: false });
      await get().listAllFoldersFiles(get().currentPage, get().totalItems); // Refresh list
    } catch (error: any) {
      set({ errorDeleting: error.message, responseMessage: error.message, responseStatus: 'error', isDeleting: false });
    }
  },

  createNewFolder: async () => {
    set({ isCreating: true, errorCreating: null, responseMessage: null, responseStatus: null });
    console.log('Starting to create a new folder'); // Debugging

    try {
      const folderName = get().folderName;
      const token = useAuthStore.getState().token;
      const selectedItemId = useSidebarStore.getState().selectedItemId;

      if (!token) {
        set({ responseMessage: 'Authentication token is missing.', responseStatus: 'error', isCreating: false });
        console.log('No token found'); // Debugging
        return;
      }

      if (!folderName) {
        set({ responseMessage: 'Folder name is required.', responseStatus: 'error', isCreating: false });
        console.log('Folder name is required'); // Debugging
        return;
      }

      const data = await createFolder(folderName, selectedItemId, token);
      set({ responseMessage: 'Folder created successfully', responseStatus: 'success', isCreating: false });
      console.log('Folder created successfully', data.message); // Debugging
      await get().listAllFoldersFiles(get().currentPage, get().rowsPerPage); // Refresh list
    } catch (error: any) {
      const errorMessage = error.message || 'Failed to create folder';
      set({ errorCreating: errorMessage, responseMessage: errorMessage, responseStatus: 'error', isCreating: false });
      console.log('Error creating folder', errorMessage); // Debugging
    }
  },


  updateFolder: async (folderId: string, updatedData: any) => {
    set({ isUpdating: true, errorUpdating: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      const result = await updateFolder(folderId, updatedData, token);
      set({ responseMessage: result.message, responseStatus: 'success', isUpdating: false });
      await get().listAllFoldersFiles(get().currentPage, get().totalItems); // Refresh list
    } catch (error: any) {
      set({ errorUpdating: error.message, responseMessage: error.message, responseStatus: 'error', isUpdating: false });
    }
  },

  deleteFolder: async (folderId: string) => {
    set({ isDeleting: true, errorDeleting: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      await deleteFolderApi(folderId, token);
      set({ responseMessage: 'Folder deleted successfully.', responseStatus: 'success', isDeleting: false });
      await get().refeshListAllFoldersFiles(get().currentPage, get().totalItems); // Refresh list
    } catch (error: any) {
      set({ errorDeleting: error.message, responseMessage: error.message, responseStatus: 'error', isDeleting: false });
    }
  },

  listAllFolders: async () => {
    set({ isFetching: true, errorFetching: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      const result = await listAllFolders(token);
      set({ folders: result.folders, responseMessage: 'Folders retrieved successfully.', responseStatus: 'success', isFetching: false });
    } catch (error: any) {
      set({ errorFetching: error.message, responseMessage: error.message, responseStatus: 'error', isFetching: false });
    }
  },
  fetchFolders: async (page: number) => {
    const { lastFetchedPage, lastFetchedItemId } = get();
    const selectedItemId = useSidebarStore.getState().selectedItemId;

    if (lastFetchedPage === page && lastFetchedItemId === selectedItemId) {
      // console.log(`Page ${page} already fetched for the selected item ${selectedItemId}, skipping...`);
      return;  // Do not fetch if the page has already been fetched for the current selected item
    }
    set({ isFetching: true });
    try {
      const token = useAuthStore.getState().token || '';
      const data = await listAllFoldersFiles(token, selectedItemId, page, get().rowsPerPage);
      set({
        items: data.items,
        totalPages: data.totalPages,
        totalItems: data.totalItems,
        lastFetchedPage: page,  // Update the last fetched page
        lastFetchedItemId: selectedItemId,  // Update the last fetched item ID
        isFetching: false,
      });
    } catch (error) {
      set({ isFetching: false });
      console.error('Error fetching folders:', error);
    }
  },

  refeshListAllFoldersFiles: async (page: number, rowsPerPage: number) => {
     const selectedItemId = useSidebarStore.getState().selectedItemId;

    
    set({ isFetching: true });
    try {
      const token = useAuthStore.getState().token || '';
      const data = await listAllFoldersFiles(token, selectedItemId, page, rowsPerPage);
      set({
        items: data.items,
        totalPages: data.totalPages,
        totalItems: data.totalItems,
        lastFetchedPage: page,  // Update the last fetched page
        lastFetchedItemId: selectedItemId,  // Update the last fetched item ID
        isFetching: false,
      });
    } catch (error) {
      set({ isFetching: false });
      console.error('Error fetching folders:', error);
    }
  },


  listAllFoldersFiles: async (page: number, rowsPerPage: number) => {
    set({ isFetching: true, errorFetching: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      const selectedItemId = useSidebarStore.getState().selectedItemId;
      const result = await listAllFoldersFiles(token, selectedItemId, page, rowsPerPage);
      set({
        items: result.items,
        currentPage: page,
        totalPages: result.totalPages,
        totalItems: result.totalItems,
        responseMessage: 'Folders and files retrieved successfully.',
        responseStatus: 'success',
        isFetching: false,
      });
    } catch (error: any) {
      set({ errorFetching: error.message, responseMessage: error.message, responseStatus: 'error', isFetching: false });
    }
  },

  copyFolder: async (folderId: string, params: { directory_id: string; parent_folder_id: string }) => {
    set({ isCopying: true, errorCopying: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      const result = await copyFolder(folderId, params, token);
      set({ responseMessage: result.message, responseStatus: 'success', isCopying: false });
      await get().listAllFoldersFiles(get().currentPage, get().totalItems); // Refresh list
    } catch (error: any) {
      set({ errorCopying: error.message, responseMessage: error.message, responseStatus: 'error', isCopying: false });
    }
  },

  // restoreFolder: async (folderId: string) => {
  //   set({ isRestoring: true, errorRestoring: null, responseMessage: '', responseStatus: null });
  //   try {
  //     const token = useAuthStore.getState().token;
  //     if (!token) throw new Error('No token found');
  //     const result = await restoreFolder(folderId, token);
  //     set({ responseMessage: result.message, responseStatus: 'success', isRestoring: false });
  //     await get().listAllFoldersFiles(get().currentPage, get().totalItems); // Refresh list
  //   } catch (error: any) {
  //     set({ errorRestoring: error.message, responseMessage: error.message, responseStatus: 'error', isRestoring: false });
  //   }
  // },

  getFolderDetails: async (folderId: string) => {
    set({ isFetching: true, errorFetching: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      const result = await getFolderDetails(folderId, token);
      set({ folderDetails: result.folder, responseMessage: 'Folder details retrieved successfully.', responseStatus: 'success', isFetching: false });
    } catch (error: any) {
      set({ errorFetching: error.message, responseMessage: error.message, responseStatus: 'error', isFetching: false });
    }
  },

  uploadFolder: async (data: { parent_folder_id: string; name: string; directory_id: string; files: File[] }) => {
    set({ isUploading: true, errorUploading: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      const result = await uploadFolder(data, token);
      set({ responseMessage: result.message, responseStatus: 'success', isUploading: false });
      await get().listAllFoldersFiles(get().currentPage, get().totalItems); // Refresh list
    } catch (error: any) {
      set({ errorUploading: error.message, responseMessage: error.message, responseStatus: 'error', isUploading: false });
    }
  },

  downloadFolder: async () => {
    set({ isDownloading: true, errorDownloading: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      set({ responseMessage: 'File downloaded successfully.', responseStatus: 'success', isDownloading: false });
      await get().listAllFoldersFiles(get().currentPage, get().totalItems); // Refresh list
    } catch (error: any) {
      set({ errorDownloading: error.message, responseMessage: error.message, responseStatus: 'error', isDownloading: false });
    }
  },

  emptyTrashFolders: async () => {
    set({ isEmptyingTrash: true, errorEmptyingTrash: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      const result = await emptyTrashFolder(token);
      set({ responseMessage: result.message, responseStatus: 'success', isEmptyingTrash: false });
      await get().listAllFoldersFiles(get().currentPage, get().totalItems); // Refresh list
    } catch (error: any) {
      set({ errorEmptyingTrash: error.message, responseMessage: error.message, responseStatus: 'error', isEmptyingTrash: false });
    }
  },
}));

export default useFolderStore;
