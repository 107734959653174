import React from 'react';

interface PsdIconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly as colors are hardcoded in SVG
}

const PsdIcon: React.FC<PsdIconProps> = ({ width = 72, height = 72 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 72 72"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z"
      fill="#755de0"
    />
    <path
      d="M27.2223,43H44.7086s2.325-.2815.7357-1.897l-5.6034-5.4985s-1.5115-1.7913-3.3357.7933L33.56,40.4707a.6887.6887,0,0,1-1.0186.0486l-1.9-1.6393s-1.3291-1.5866-2.4758,0c-.6561.9079-2.0261,2.8489-2.0261,2.8489S25.4268,43,27.2223,43Z"
      fill="#fff"
    />
    <path
      d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z"
      fill="#b5b3ff"
    />
  </svg>
);

export default PsdIcon;
