import React from 'react';

interface SvgIconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly as colors are hardcoded in SVG
}

const SvgIcon: React.FC<SvgIconProps> = ({ width = 40, height = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34 50H6C4.4087 50 2.88258 49.3679 1.75736 48.2426C0.63214 47.1174 0 45.5913 0 44V11L9 0H34C35.5913 0 37.1174 0.632141 38.2426 1.75736C39.3679 2.88258 40 4.4087 40 6V44C40 45.5913 39.3679 47.1174 38.2426 48.2426C37.1174 49.3679 35.5913 50 34 50Z"
      fill="#F7B84E"
    />
    <path
      d="M9 9.556C9 9.93897 8.84787 10.3063 8.57706 10.5771C8.30626 10.8479 7.93897 11 7.556 11H0L9 0V9.556Z"
      fill="#FFE9C4"
    />
    <path
      d="M8.14457 25.2705C8.43207 22.847 12.0914 22.4111 13.8164 23.7361C14.6664 24.422 13.7664 25.5095 13.0164 24.9595C12.0914 24.372 9.993 24.097 9.7305 25.3955C9.393 27.4455 14.8274 26.272 14.7414 29.5939C14.6539 32.7673 10.0555 32.8423 8.3305 31.4173C7.918 31.0814 7.9305 30.533 8.1555 30.1939C8.4805 29.8705 8.843 29.758 9.268 30.1064C10.2914 30.8064 12.9289 31.3314 13.1149 29.5548C12.9539 27.708 7.75707 28.8205 8.14457 25.2705Z"
      fill="white"
    />
    <path
      d="M19.1118 31.4814L15.8134 24.572C15.3259 23.5845 16.8009 22.7736 17.3493 23.8095L18.4618 26.3204L19.8977 29.6189L21.3087 26.3204L22.4212 23.8095C22.9212 22.8611 24.3696 23.4595 23.9462 24.497L20.7602 31.4814C20.4727 32.2689 19.5868 32.4548 19.1118 31.4814Z"
      fill="white"
    />
    <path
      d="M32.4555 31.0814C31.568 31.8173 30.4805 32.1314 29.3821 32.1314C26.7571 32.1314 24.8961 30.6314 24.8961 27.658C24.8961 25.1345 26.8586 23.1736 29.493 23.1736C30.4805 23.1736 31.5664 23.5111 32.3414 24.2736C33.1024 25.022 32.0039 26.1345 31.2555 25.4736C30.793 25.022 30.143 24.6861 29.493 24.6861C27.9821 24.6861 26.5211 25.897 26.5211 27.658C26.5211 29.508 27.7446 30.6314 29.3821 30.6314C30.143 30.6314 30.793 30.4064 31.2555 30.0689V28.5345H29.3821C28.2696 28.5345 28.3821 27.008 29.3821 27.008H31.8805C32.343 27.008 32.7664 27.3595 32.7664 27.747V30.408C32.768 30.6314 32.668 30.8423 32.4555 31.0814Z"
      fill="white"
    />
  </svg>
);

export default SvgIcon;
