import React from "react";

interface AvatarProps {
  icon: React.ReactNode;
  size?: string;
}

const CustomFileType: React.FC<AvatarProps> = ({ icon, size = "24px" }) => {
  return (
    <div
      style={{
        width: size,
        height: size,
      }}
    >
      <div 
      // style={{ width: "100%", height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {icon}
      </div>
    </div>
  );
};

export default CustomFileType;
