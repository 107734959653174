import React, { useCallback, useState } from 'react';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Tooltip, Button, Input } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

// Icons
import EditIcon from '../../assets/icons/EditIcon';
import { SearchIcon } from '../../assets/icons/SearchIcon';
import DeleteIcon from '../../assets/icons/DeleteIcon';

// Components
import DeleteRoleModal from '../modals/DeleteRoleModal';
import EditRoleModal from '../modals/EditRoleModal';
import AddNewRoleModal from '../modals/AddNewRoleModal';

// Hooks
import { useDisclosure } from '@nextui-org/react';
import { Role } from 'src/types/api/ListRole';
import { useFetchRoles } from 'src/hooks/useRoleHooks';
import { useRoleStore } from 'src/store/api/useRoleStore';

const Roles = () => {
  const { t } = useTranslation();
  const { roles, isLoadingFetch, errorFetch } = useFetchRoles();
  const { search, isModalOpen, setSearch, setModalOpen } = useRoleStore();
  const deleteRoleModal = useDisclosure();
  const editRoleModal = useDisclosure();
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value.toLowerCase());
    },
    [setSearch]
  );

  const filteredRoles = roles.filter(
    (role) =>
      role.role_name_en?.toLowerCase().includes(search) ||
      role.role_description_en?.toLowerCase().includes(search)
  );

  const handleOpenModal = useCallback(() => setModalOpen(true), [setModalOpen]);
  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setSelectedRole(null); // Clear the selected role when the modal is closed
  }, [setModalOpen]);

  const handleEditRole = useCallback(
    (role: Role) => {
      setSelectedRole(role); // Set the selected role for editing
      editRoleModal.onOpen();
    },
    [editRoleModal]
  );

  const handleDeleteRole = useCallback(
    (roleId: string) => {
      setSelectedRoleId(roleId);
      deleteRoleModal.onOpen();
    },
    [deleteRoleModal]
  );

  if (isLoadingFetch) {
    return <div>{t('Loading')}</div>;
  }

  if (errorFetch) {
    return <div>Error loading roles: {errorFetch}</div>;
  }

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <Input
          startContent={<SearchIcon className="text-default-300" />}
          placeholder={t('SearchByRoles')}
          value={search}
          onChange={handleSearch}
          aria-label="Search roles"
          classNames={{
            base: 'lg:w-full sm:max-w-[44%]',
            inputWrapper: 'border-1 bg-white',
          }}
        />
        <Button className="text-white" color="success" onClick={handleOpenModal}>
          {t('NewRole')}
        </Button>
      </div>

      <Table aria-label="Roles table">
        <TableHeader columns={[
          { uid: 'name', name: 'Role Name' },
          { uid: 'description', name: 'Description' },
          { uid: 'actions', name: 'Actions' }
        ]}>
          {(column) => (
            <TableColumn key={column.uid} align={column.uid === 'actions' ? 'end' : 'start'}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={filteredRoles}>
          {(item) => (
            <TableRow key={item.role_id || item.role_name_en}>
              <TableCell>{item.role_name_en}</TableCell>
              <TableCell>{item.role_description_en}</TableCell>
              <TableCell>
                <div className="relative flex items-center gap-2">
                  <Tooltip content={t('EditRole')}>
                    <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
                      <EditIcon onClick={() => handleEditRole(item)} />
                    </span>
                  </Tooltip>
                  <Tooltip color="danger" content={t('DeleteRole')}>
                    <span className="text-lg text-danger cursor-pointer active:opacity-50">
                      <DeleteIcon onClick={() => handleDeleteRole(item.role_id || '')} />
                    </span>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <AddNewRoleModal isOpen={isModalOpen} onClose={handleCloseModal} />
      <EditRoleModal
        role={selectedRole} // Pass the selected role to the modal
        isOpen={editRoleModal.isOpen}
        onClose={editRoleModal.onClose}
      />
      <DeleteRoleModal
        isOpen={deleteRoleModal.isOpen}
        onClose={deleteRoleModal.onClose}
        roleId={selectedRoleId}
      />
    </div>
  );
};

export default Roles;
