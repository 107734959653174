import React from 'react';

interface ZipIconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly as colors are hardcoded in SVG
}

const ZipIcon: React.FC<ZipIconProps> = ({ width = 72, height = 72 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 72 72"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="16" y="14" width="40" height="44" rx="6" ry="6" fill="#7e95c4" />
    <rect x="32" y="17" width="8" height="2" rx="1" ry="1" fill="#fff" />
    <rect x="32" y="22" width="8" height="2" rx="1" ry="1" fill="#fff" />
    <rect x="32" y="27" width="8" height="2" rx="1" ry="1" fill="#fff" />
    <rect x="32" y="32" width="8" height="2" rx="1" ry="1" fill="#fff" />
    <rect x="32" y="37" width="8" height="2" rx="1" ry="1" fill="#fff" />
    <path d="M35,14h2a0,0,0,0,1,0,0V43a1,1,0,0,1-1,1h0a1,1,0,0,1-1-1V14A0,0,0,0,1,35,14Z" fill="#fff" />
    <path d="M38.0024,42H33.9976A1.9976,1.9976,0,0,0,32,43.9976v2.0047A1.9976,1.9976,0,0,0,33.9976,48h4.0047A1.9976,1.9976,0,0,0,40,46.0024V43.9976A1.9976,1.9976,0,0,0,38.0024,42Zm-.0053,4H34V44h4Z" fill="#fff" />
  </svg>
);

export default ZipIcon;
