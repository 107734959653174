import { useState } from 'react';
import useTrashStore from 'src/store/api/useTrashStore';
  
const useRestoreFile = () => {

  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const restoreFile = async (FileId: string) => {
    setIsProcessing(true);
    setError(null);
    setSuccessMessage(null);
    try {
      await useTrashStore.getState().restoreFile(FileId);
      const state = useTrashStore.getState();
      setSuccessMessage(state.successMessage);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return {
    restoreFile,
    isProcessing,
    error,
    successMessage,
  };
  
};

export default useRestoreFile;
