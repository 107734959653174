import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Switch,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { Role } from "src/types/api/ListRole";
import { useAddNewRoleModalStore, useRoleStore } from "src/store/api/useRoleStore";
import { Alert } from "antd";

interface AddNewRoleModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddNewRoleModal: React.FC<AddNewRoleModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const {
    nameEn,
    descriptionEn,
    nameAr,
    descriptionAr,
    setNameEn,
    setDescriptionEn,
    setNameAr,
    setDescriptionAr,
    reset,
  } = useAddNewRoleModalStore();

  const [permissions, setPermissions] = useState({
    is_supper_admin:  false,
    is_administrators:  false,  // Set based on the switch state
  });

  const [errors, setErrors] = useState({
    nameEn: '',
    descriptionEn: '',
    nameAr: '',
    descriptionAr: '',
  });

  const {
    createRole,
    responseMessage,
    responseStatus,
    resetResponseStatus,
  } = useRoleStore();

  const validate = () => {
    const newErrors = {
      nameEn: nameEn.trim() ? '' : t("This field is required"),
      descriptionEn: descriptionEn.trim() ? '' : t("This field is required"),
      nameAr: nameAr.trim() ? '' : t("This field is required"),
      descriptionAr: descriptionAr.trim() ? '' : t("This field is required"),
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error !== '');
  };

  const handleSave = async () => {
    if (!validate()) {
      return;
    }

    const roleData: Role = {
      role_name_en: nameEn,
      role_description_en: descriptionEn,
      role_name_ar: nameAr,
      role_description_ar: descriptionAr,
      create_role: 0,
      delete_role: 0,
      edit_role: 0,
      read_role: 0,
      users_saff_read: 0,
      users_saff_edit_role: 0,
      create_users_outside_saff: 0,
      read_users_outside_saff: 0,
      edit_users_outside_saff: 0,
      delete_users_outside_saff: 0,
      share_delete_my_users_with_another: 0,
      share_read_my_users_with_another: 0,
      share_edit_my_users_with_another: 0,
      share_create_my_users_with_another: 0,
      is_supper_admin:  0,
      is_administrators:  0,  // Set based on the switch state
      ...Object.fromEntries(
        Object.entries(permissions).map(([key, value]) => [key, value ? 1 : 0])
      ),
    };

    await createRole(roleData);

    if (responseStatus === "success") {
      reset();
    }
  };

  const handlePermissionChange = (permission: keyof typeof permissions) => {
    setPermissions((prev) => ({
      ...prev,
      [permission]: !prev[permission],
    }));
  };

  const handleClose = () => {
    reset(); 
    resetResponseStatus(); 
    onClose(); 
  };

  useEffect(() => {
    if (isOpen) {
      reset(); 
      resetResponseStatus(); 
    }
  }, [isOpen, reset, resetResponseStatus]);

  return (
    <Modal scrollBehavior="inside" size="2xl" isOpen={isOpen} onClose={handleClose}>
      <ModalContent>
        <ModalHeader>{t("AddNewRole")}</ModalHeader>
        <ModalBody>
          {responseMessage && (
            <Alert message={responseMessage} type={responseStatus === "success" ? "success" : "error"} showIcon closable />
          )}
          <form className="flex flex-col gap-4">
            <div>
              <Input
                type="text"
                label={
                  <span>
                    {t("RoleNameEN")} <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                labelPlacement="outside"
                value={nameEn}
                onChange={(e) => setNameEn(e.target.value)}
                color={errors.nameEn ? 'danger' : undefined}
              />
              {errors.nameEn && (
                <div style={{ color: 'red', fontSize: '0.875rem' }}>{errors.nameEn}</div>
              )}
            </div>
            <div>
              <Input
                type="text"
                label={
                  <span>
                    {t("RoleDescriptionEN")} <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                labelPlacement="outside"
                value={descriptionEn}
                onChange={(e) => setDescriptionEn(e.target.value)}
                color={errors.descriptionEn ? 'danger' : undefined}
              />
              {errors.descriptionEn && (
                <div style={{ color: 'red', fontSize: '0.875rem' }}>{errors.descriptionEn}</div>
              )}
            </div>
            <div>
              <Input
                type="text"
                label={
                  <span>
                    {t("RoleNameAR")} <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                labelPlacement="outside"
                value={nameAr}
                onChange={(e) => setNameAr(e.target.value)}
                color={errors.nameAr ? 'danger' : undefined}
              />
              {errors.nameAr && (
                <div style={{ color: 'red', fontSize: '0.875rem' }}>{errors.nameAr}</div>
              )}
            </div>
            <div>
              <Input
                type="text"
                label={
                  <span>
                    {t("RoleDescriptionAR")} <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                labelPlacement="outside"
                value={descriptionAr}
                onChange={(e) => setDescriptionAr(e.target.value)}
                color={errors.descriptionAr ? 'danger' : undefined}
              />
              {errors.descriptionAr && (
                <div style={{ color: 'red', fontSize: '0.875rem' }}>{errors.descriptionAr}</div>
              )}
            </div>

            <div className="mt-3 mb-1 text-lg font-semibold">
              {t("SelectPermissions")}
            </div>
            {Object.keys(permissions).map((permission) => (
              <div key={permission} className="w-full flex justify-between">
                <div>{t(permission)}</div>
                <Switch
                  checked={permissions[permission as keyof typeof permissions]}
                  onChange={() => handlePermissionChange(permission as keyof typeof permissions)}
                />
              </div>
            ))}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" variant="light" onPress={handleClose}>
            {t("Close")}
          </Button>
          <Button color="primary" onPress={handleSave}>
            {t("Save")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddNewRoleModal;
