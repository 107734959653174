// src/store/api/useNotificationStore.ts
import { create } from 'zustand';
import { deleteAllNotifications, deleteNotification, listAllNotificationsItems, listLastThreeNotifications, markAllNotificationsAsRead, markNotificationAsRead } from '../../services/api/API';
import { useAuthStore } from './useAuthStore';
import { NotificationState } from '../../types/api/Notification';
import { useSidebarStore } from '../layout/useSidebarStore';
 

const useNotificationStore = create<NotificationState>((set,get) => ({
  filterValue: '',
  selectedKeys: new Set<React.Key>(),
  statusFilter: new Set<string>(),
  rowsPerPage: 8,
  sortDescriptor: { column: 'id', direction: 'ascending' },
  page: 1,
   totalPages: 1,
  isLoading: false,
  error: null,
  successMessage: null,
  notifications: null,
  allNotifications: null,
  currentPage: 1,
  totalItems: 0,
  allNotificationsItems: null ,
  totalUnRead: 0,    // Total number of notifications
  notificationLastFetchedItemId: null,

  setFilterValue: (value: string) => set({ filterValue: value }),
    setSelectedKeys: (keys: Set<React.Key>) => set({ selectedKeys: keys }),
    setStatusFilter: (value: Set<string>) => set({ statusFilter: value }),
    setRowsPerPage: (value: number) => set({ rowsPerPage: value }),
    setSortDescriptor: (descriptor) => set({ sortDescriptor: descriptor }),
    setPage: (value: number) => set({ page: value }),


  listLastThreeNotifications: async () => {
     set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await listLastThreeNotifications(token);
      set({ notifications: result.notifications, totalUnRead: result.totalUnRead||0, isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  deleteAllNotifications: async () => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await deleteAllNotifications(token);
      set({ successMessage: result.message, isLoading: false, notifications: [] });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  deleteNotification: async (notificationId: string) => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await deleteNotification(notificationId, token);
      set({ successMessage: result.message, isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },
  
  markNotificationAsRead: async (notificationId: string) => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await markNotificationAsRead(notificationId, token);
      set({ successMessage: result.message, isLoading: false });
      await get().listLastThreeNotifications(); // Refresh list
      await get().refreshListAllNotificationsItems(get().currentPage, get().rowsPerPage); // Refresh list
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  markAllNotificationsAsRead: async () => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await markAllNotificationsAsRead(token);
      set({ successMessage: result.message, isLoading: false });
      await get().listLastThreeNotifications(); // Refresh list
      await get().refreshListAllNotificationsItems(get().currentPage, get().rowsPerPage); // Refresh list
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },
  
  listAllNotificationsItems: async (page: number, rowsPerPage: number) => {
    const { currentPage, notificationLastFetchedItemId } = get();
    const selectedItemId = useSidebarStore.getState().selectedItemId;
 
    if (currentPage === page && notificationLastFetchedItemId === selectedItemId) {
      // console.log(`listAllNotificationsItems Page ${page} already fetched for the selected item ${selectedItemId}, skipping...`);
      return;  // Do not fetch if the page has already been fetched for the current selected item
    }
    await get().refreshListAllNotificationsItems(page, rowsPerPage); // Refresh list

  },

  refreshListAllNotificationsItems: async (page: number, rowsPerPage: number) => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState(); 
      if (!token) throw new Error('No token found');
      const { selectedItemId } = useSidebarStore.getState();
      const result = await listAllNotificationsItems(token, selectedItemId, page, rowsPerPage);
      set({
        currentPage: page, 
        totalPages: result.totalPages,
        totalItems: result.totalNotifications,
        allNotificationsItems: result.notifications,
        notificationLastFetchedItemId: selectedItemId,  // Update the last fetched item ID
        isLoading: false
      });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },
}));

export default useNotificationStore;
