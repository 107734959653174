import React from 'react';

interface AiIconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly as colors are hardcoded in SVG
}

const AiIcon: React.FC<AiIconProps> = ({ width = 40, height = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34 50H6C4.4087 50 2.88258 49.3679 1.75736 48.2426C0.63214 47.1174 0 45.5913 0 44V11L9 0H34C35.5913 0 37.1174 0.632141 38.2426 1.75736C39.3679 2.88258 40 4.4087 40 6V44C40 45.5913 39.3679 47.1174 38.2426 48.2426C37.1174 49.3679 35.5913 50 34 50Z"
      fill="#F7B84E"
    />
    <path
      d="M9 9.556C9 9.93897 8.84787 10.3063 8.57706 10.5771C8.30626 10.8479 7.93897 11 7.556 11H0L9 0V9.556Z"
      fill="#FFDCA1"
    />
    <path
      d="M12.6304 33.9855C12.1262 33.6999 11.811 33.1346 12.1262 32.4727L16.6449 23.6383C17.0703 22.8031 18.2049 22.7716 18.5989 23.6383L23.0388 32.4727C23.6849 33.6999 21.7781 34.6769 21.2128 33.4497L20.5175 32.063H14.7066L14.029 33.4497C13.7788 33.9855 13.1958 34.0978 12.6304 33.9855ZM19.4006 29.9849L17.6219 26.0946L15.6856 29.9849H19.4006Z"
      fill="white"
    />
    <path
      d="M24.9199 24.0322C24.9199 22.7401 27 22.6928 27 24.0322V33.0223C27 34.3302 24.9199 34.3618 24.9199 33.0223V24.0322Z"
      fill="white"
    />
  </svg>
);

export default AiIcon;
