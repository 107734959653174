import React, { useMemo, useCallback, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownTrigger,
  DropdownItem,
  useDisclosure,
} from "@nextui-org/react";
import { Pagination } from "antd";
import { useTranslation } from "react-i18next";
import CustomAvatar from "../../utils/partials/CustomFileType";
import AiIcon from "../../assets/icons/Files Types/AiIcon";
import DocIcon from "../../assets/icons/Files Types/DocIcon";
import PngIcon from "../../assets/icons/Files Types/PngIcon";
import PdfIcon from "../../assets/icons/Files Types/PdfIcon";
import TxtIcon from "../../assets/icons/Files Types/TxtIcon";
import PsdIcon from "../../assets/icons/Files Types/PsdIcon";
import JpgIcon from "../../assets/icons/Files Types/JpgIcon";
import JpegIcon from "../../assets/icons/Files Types/JpegIcon";
import PsbIcon from "../../assets/icons/Files Types/PsbIcon";
import SvgIcon from "../../assets/icons/Files Types/SvgIcon";
import Mp4Icon from "../../assets/icons/Files Types/Mp4Icon";
import AviIcon from "../../assets/icons/Files Types/AviIcon";
import MovIcon from "../../assets/icons/Files Types/MovIcon";
import WebmIcon from "../../assets/icons/Files Types/WebmIcon";
import XlsxIcon from "../../assets/icons/Files Types/XlsxIcon";
import CsvIcon from "../../assets/icons/Files Types/CsvIcon";
import PptIcon from "../../assets/icons/Files Types/PptIcon";
import InddIcon from "../../assets/icons/Files Types/InddIcon";
import RarIcon from "../../assets/icons/Files Types/RarIcon";
import ArjIcon from "../../assets/icons/Files Types/ArjIcon";
import ZipIcon from "../../assets/icons/Files Types/ZipIcon";
import { SearchIcon } from '../../assets/icons/SearchIcon';
import { VerticalDotsIcon } from "../../assets/icons/VerticalDotsIcon";
import { useToggleFileFavorite, useToggleFolderFavorite, useFavoritesActions, useFetchFavorites } from "src/hooks/useFavoriteHooks";
import HeartIcon from "../../assets/icons/Hearticon";
import MainFileIcon from '../../assets/icons/MainFileIcon';
import FolderDetailsModal from "../modals/FolderDetailsModal";
import FileDetailsModal from "../modals/FileDetailsModal";
import { Link } from "react-router-dom";
import { useFolderStore } from "../main_page/cards/FolderCard";


const typesIconMap: Record<string, React.ReactNode> = {
  ai: <AiIcon height={20} width={30} />,
  docx: <DocIcon height={28} width={30} />,
  png: <PngIcon height={20} width={30} />,
  pdf: <PdfIcon height={25} width={30} />,
  txt: <TxtIcon height={20} width={30} />,
  psd: <PsdIcon height={20} width={30} />,
  jpg: <JpgIcon height={20} width={30} />,
  jpeg: <JpegIcon height={20} width={30} />,
  psb: <PsbIcon height={20} width={30} />,
  svg: <SvgIcon height={20} width={30} />,
  mp4: <Mp4Icon height={20} width={30} />,
  avi: <AviIcon height={20} width={30} />,
  mov: <MovIcon height={20} width={30} />,
  webm: <WebmIcon height={20} width={30} />,
  xlsx: <XlsxIcon height={20} width={30} />,
  csv: <CsvIcon height={20} width={30} />,
  pptx: <PptIcon height={20} width={30} />,
  indd: <InddIcon height={20} width={30} />,
  zip: <ZipIcon height={30} width={30} />,
  RAR: <RarIcon height={20} width={30} />,
  ARJ: <ArjIcon height={20} width={30} />,
  folder: <MainFileIcon height={23} width={30} />
};

const FavoritesTable: React.FC = () => {
  const { t } = useTranslation();

  // Step 1: State for search term
  const [searchTerm, setSearchTerm] = useState<string>('');

  const {
    setSelectedKeys,
    setPage,
    page,
    totalPages,
    selectedKeys,
    favorite,
    rowsPerPage,
  } = useFavoritesActions();
  const { setFolderId } = useFolderStore(); // Zustand store to manage selected folder id
  const {
    isOpen: isFolderDetailsOpen,
    onOpen: onOpenFolderDetails,
    onClose: onCloseFolderDetails,
  } = useDisclosure();

  const {
    isOpen: isFileDetailsOpen,
    onOpen: onOpenFileDetails,
    onClose: onCloseFileDetails,
  } = useDisclosure();

  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);

  const { loading } = useFetchFavorites();
  const toggleFavoriteFile = useToggleFileFavorite();
  const toggleFavoriteFolder = useToggleFolderFavorite();

  // Step 2: Filter the favorites list based on the search term
  const filteredFavorites = useMemo(() => {
    return favorite.filter((item) =>
      item.original_filename?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [favorite, searchTerm]);

  const handleDownload = useCallback((s3RedirectUrl?: string) => {
    if (s3RedirectUrl) {
      window.open(s3RedirectUrl, '_blank');
    }
  }, []);

  const handleViewDetails = (favoriteItem: any) => {
    if (favoriteItem.folder_id) {
      setSelectedFolderId(favoriteItem.folder_id);
      onOpenFolderDetails();
    } else if (favoriteItem.file_id) {
      setSelectedFileId(favoriteItem.file_id);
      onOpenFileDetails();
    }
  };

  // Step 3: Update search term when input changes
  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  return (
    <div className="w-full bg-white p-4 rounded-lg shadow overflow-x-auto">
      <div className="flex flex-col gap-4">
        {/* Step 5: Render search input and pagination controls */}
        <div className="flex justify-between gap-3 items-end">
          <Input
            isClearable
            classNames={{ base: 'w-full sm:max-w-[44%]', inputWrapper: 'border-1' }}
            placeholder={t('Search by name...')}
            startContent={<SearchIcon className="text-default-300" />}
            variant="bordered"
            onClear={() => handleSearchChange('')}
            onValueChange={handleSearchChange}
          />
        </div>
        <div className="flex justify-between items-center">
          <span className="text-default-400 text-small">
            {loading ? t('Loading') : `${t('TotalFavorites')}: ${filteredFavorites.length}`}
          </span>
          {/* <div className="flex items-center">
            <label className="mr-2 text-default-400 text-small">{t('RowsPerPage')}</label>
            <select
              className="border border-default-200 rounded px-2 py-1"
              value={rowsPerPage}
              onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div> */}
        </div>
      </div>

      <Table
        isCompact
        className="min-w-full"
        removeWrapper
        bottomContent={
          <div className="py-2 px-2 flex justify-between items-center mt-4">
            <Pagination
              current={page}
              total={totalPages * rowsPerPage} // Ant Design uses total items instead of total pages
              pageSize={rowsPerPage}
              onChange={(newPage) => setPage(newPage)}
              showSizeChanger={false}
            />
            {/* <span className="text-small text-default-400">
              {selectedKeys.has('all')
                ? `${t('AllItemsSelected')}`
                : `${selectedKeys.size} ${t('of')} ${filteredFavorites.length} ${t('selected')}`}
            </span> */}
          </div>
        }
        bottomContentPlacement="outside"
        checkboxesProps={{ classNames: { wrapper: 'after:bg-secondary after:text-background text-background' } }}
        selectedKeys={Array.from(selectedKeys) as Iterable<import('@react-types/shared').Key>}
        selectionMode="none"
        onSelectionChange={(keys) => setSelectedKeys(new Set(keys as unknown as React.Key[]))}
      >
        {/* Step 7: Define table headers */}
        <TableHeader className="bg-white" columns={['full_name', 'file_type', 'actions']}>
          {['full_name', 'file_type', 'actions'].map((column) => (
            <TableColumn key={column} align={column === 'actions' ? 'end' : 'start'}>
              {t(column)}
            </TableColumn>
          ))}
        </TableHeader>

        {/* Step 8: Populate table rows with filtered favorite items */}
        <TableBody emptyContent={t('NoFavoritesFound')} items={filteredFavorites}>
          {filteredFavorites.map((favorite, index) => (
            <TableRow key={`${favorite.favorite_id}-${index}`}>
              <TableCell>
                {favorite.folder_id ? (
                  <div className="flex items-start gap-2">
                    <Button
                      isIconOnly
                      className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
                      radius="full"
                      variant="light"
                      onPress={(e) => {
                        toggleFavoriteFolder(favorite.folder_id || '');
                      }}
                    >
                      <HeartIcon className="[&>path]:stroke-transparent" fill="red" />
                    </Button>
                    {/* If the item is a folder, wrap with a Link component to navigate to folder details */}
                    <Link to="/fileDetails" onClick={() => setFolderId(favorite.folder_id || '')} className="flex items-center gap-2">
                      {/* Step 9: Add button to toggle favorite status */}
                      {/* Step 10: Display file type icon */}
                      <CustomAvatar
                        icon={typesIconMap[favorite.file_type as keyof typeof typesIconMap] || typesIconMap[favorite.type as keyof typeof typesIconMap]}
                        size="24px"
                      />
                      {/* Step 11: Display folder name */}
                      <span className="text-lg">{favorite.original_filename || favorite.name}</span>
                    </Link>
                  </div>
                ) : (
                  // If the item is a file, just display the name and other info without navigation
                  <div className="flex items-start gap-2">
                    {/* Step 9: Add button to toggle favorite status */}
                    <Button
                      isIconOnly
                      className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
                      radius="full"
                      variant="light"
                      onPress={() => toggleFavoriteFile(favorite.file_id || '')}
                    >
                      <HeartIcon className="[&>path]:stroke-transparent" fill="red" />
                    </Button>

                    {/* Step 10: Display file type icon */}
                    <CustomAvatar
                      icon={typesIconMap[favorite.file_type as keyof typeof typesIconMap] || typesIconMap[favorite.type as keyof typeof typesIconMap]}
                      size="24px"
                    />

                    {/* Step 11: Display file name */}
                    <span className="text-lg">{favorite.original_filename || favorite.name}</span>
                  </div>
                )}
              </TableCell>

              {/* Step 12: Display file type */}
              <TableCell>{favorite.file_type || 'N/A'}</TableCell>

              {/* Step 13: Render dropdown menu for actions */}
              <TableCell>
                <div className="relative flex justify-end items-center gap-2">
                  <Dropdown>
                    <DropdownTrigger>
                      <Button isIconOnly radius="full" size="sm" variant="light">
                        <VerticalDotsIcon className="text-default-400" />
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleViewDetails(favorite)}>{t('ViewDetails')}</DropdownItem>
                      <DropdownItem onClick={() => handleDownload(favorite.s3_redirect_url || '')}>{t('View')}</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {selectedFileId && (
        <FileDetailsModal isOpen={isFileDetailsOpen} onClose={onCloseFileDetails} fileId={selectedFileId} />)}
      {/* Conditionally render the FolderDetailsModal */}
      {selectedFolderId && (
        <FolderDetailsModal isOpen={isFolderDetailsOpen} onClose={onCloseFolderDetails} folderId={selectedFolderId} />
      )}
    </div>
  );
};

export default FavoritesTable;
