// src/components/LayoutSwitcher.js
import { useSwitch, VisuallyHidden } from "@nextui-org/react";
import ListIcon from "../../assets/icons/ListIcon";
import GridIcon from "../../assets/icons/GridIcon";
import { useSidebarStore } from "src/store/layout/useSidebarStore";


const LayoutSwitcher = () => {
  const { layout, toggleLayout } = useSidebarStore();
  const isSelected = layout === 'grid';

  const {
    Component,
    getBaseProps,
    getInputProps,
    getWrapperProps,
  } = useSwitch({
    isSelected: isSelected,
    onChange: toggleLayout,
  });

  return (
    <div className="flex flex-col gap-2">
      <Component {...getBaseProps()}>
        <VisuallyHidden>
          <input {...getInputProps()} />
        </VisuallyHidden>
        <div
          {...getWrapperProps()}
          className={`w-8 h-8 flex items-center justify-center rounded-lg ${
            isSelected ? "bg-[#2A8360]" : "bg-[#2A8360]"
          } hover:bg-[#FFA500]`}
        >
          {isSelected ? <ListIcon color="white" /> : <GridIcon color="white" width={20} height={20}/>}
        </div>
      </Component>
      <p className="text-default-500 text-xs select-none">
        Layout: {isSelected ? "Grid" : "List"}
      </p>
    </div>
  );
};

export default LayoutSwitcher;
