import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Input,
  useDisclosure,
} from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
 
// Components 
import FileDetailsModal from '../modals/FileDetailsModal';

// Icons 
import { VerticalDotsIcon } from '../../assets/icons/VerticalDotsIcon';
import { SearchIcon } from '../../assets/icons/SearchIcon';
import AiIcon from "../../assets/icons/Files Types/AiIcon";
import DocIcon from "../../assets/icons/Files Types/DocIcon";
import PngIcon from "../../assets/icons/Files Types/PngIcon";
import PdfIcon from "../../assets/icons/Files Types/PdfIcon";
import TxtIcon from "../../assets/icons/Files Types/TxtIcon";
import PsdIcon from "../../assets/icons/Files Types/PsdIcon";
import JpgIcon from "../../assets/icons/Files Types/JpgIcon";
import JpegIcon from "../../assets/icons/Files Types/JpegIcon";
import PsbIcon from "../../assets/icons/Files Types/PsbIcon";
import SvgIcon from "../../assets/icons/Files Types/SvgIcon";
import Mp4Icon from "../../assets/icons/Files Types/Mp4Icon";
import AviIcon from "../../assets/icons/Files Types/AviIcon";
import MovIcon from "../../assets/icons/Files Types/MovIcon";
import WebmIcon from "../../assets/icons/Files Types/WebmIcon";
import XlsxIcon from "../../assets/icons/Files Types/XlsxIcon";
import CsvIcon from "../../assets/icons/Files Types/CsvIcon";
import PptIcon from "../../assets/icons/Files Types/PptIcon";
import InddIcon from "../../assets/icons/Files Types/InddIcon";
import RarIcon from "../../assets/icons/Files Types/RarIcon";
import ArjIcon from "../../assets/icons/Files Types/ArjIcon";
import ZipIcon from "../../assets/icons/Files Types/ZipIcon";
import HeartIcon from '../../assets/icons/Hearticon';
import useFilesStore from 'src/store/layout/useFilesStore';
import { useToggleFileFavorite } from 'src/hooks/useFavoriteHooks';
import SharedWithDND from '../drag_and_drop/SharedWithDND';
import DeleteFileModal from '../modals/DeleteFileModal';
import { Pagination } from "antd";
import { useUploadFilesHooks } from 'src/hooks/useUploadFilesHooks';

const typesIconMap: Record<string, React.ReactNode> = {
  ai: <AiIcon height={75} width={70} />,
  docx: <DocIcon height={100} width={100} />,
  png: <PngIcon height={20} width={20} />,
  pdf: <PdfIcon height={40} width={90} />,
  txt: <TxtIcon height={75} width={70} />,
  psd: <PsdIcon height={75} width={70} />,
  jpg: <JpgIcon height={20} width={20} />,
  jpeg: <JpegIcon height={20} width={20} />,
  psb: <PsbIcon height={75} width={70} />,
  svg: <SvgIcon height={90} width={110} />,
  mp4: <Mp4Icon height={100} width={100} />,
  avi: <AviIcon height={75} width={70} />,
  mov: <MovIcon height={75} width={70} />,
  webm: <WebmIcon height={75} width={70} />,
  xlsx: <XlsxIcon height={90} width={110} />,
  csv: <CsvIcon height={75} width={70} />,
  pptx: <PptIcon height={75} width={70} />,
  indd: <InddIcon height={75} width={70} />,
  zip: <ZipIcon height={100} width={90} />,
  rar: <RarIcon height={75} width={70} />,
  arj: <ArjIcon height={75} width={70} />,
};

const EditorGridList: React.FC = () => {
  const { t } = useTranslation();
  const {
    filterValue,
    selectedKeys,
    statusFilter,
    rowsPerPage,
    page,
    setFilterValue,
    setStatusFilter,
    setRowsPerPage,
    setPage,
    files,
    totalPages,
    fetchSharedWithFiles,
  } = useFilesStore();

  const toggleFileFavorite = useToggleFileFavorite(); // Use the toggle hook
  const { onDrop, handleSharedWithUpload } = useUploadFilesHooks();
  const [isDragging, setIsDragging] = useState(false);

  const { isOpen: isFileDetailsOpen, onOpen: onOpenFileDetails, onClose: onCloseFileDetails } = useDisclosure();
  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
  const { isOpen: isDeleteFileOpen, onClose: onCloseDeleteFile } = useDisclosure();

  useEffect(() => {
    fetchSharedWithFiles();
  }, [fetchSharedWithFiles, page, rowsPerPage]);

  const onRowsPerPageChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(Number(e.target.value));
  }, [setRowsPerPage]);

  const onSearchChange = useCallback((value?: string) => {
    setFilterValue(value || '');
  }, [setFilterValue]);

  const handleView = useCallback((fileId: string) => {
    setSelectedFileId(fileId);
    onOpenFileDetails();
  }, [onOpenFileDetails]);

  const handleDownload = useCallback((s3RedirectUrl: string | undefined) => {
    if (s3RedirectUrl) {
      window.open(s3RedirectUrl, '_blank');
    }
  }, []);
  

  const handleToggleFavorite = useCallback(async (fileId: string) => {
    try {
      await toggleFileFavorite(fileId);
      fetchSharedWithFiles(); // Optionally refetch files to update the list
    } catch (error) {
      console.error('Failed to toggle favorite:', error);
    }
  }, [toggleFileFavorite, fetchSharedWithFiles]);

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    onDrop(droppedFiles);
    handleSharedWithUpload(); // Automatically upload files after drop
  }, [onDrop, handleSharedWithUpload]);

    const handleStatusChange = useCallback((selection: any) => {
    const newStatusSet = new Set<string>(selection.currentKey ? [selection.currentKey] : []);
    setStatusFilter(newStatusSet);
  }, [setStatusFilter]);

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
     setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const topContent = useMemo(() => (
    <div className="flex flex-col gap-4 w-[97%]">
      <div className="flex justify-between gap-3 items-end">
        <Input
          isClearable
          classNames={{
            base: 'w-full sm:max-w-[44%]',
            inputWrapper: 'border-1 bg-white',
          }}
          placeholder={t('SearchByFileName')}
          startContent={<SearchIcon className="text-default-300" />}
          value={filterValue}
          variant="bordered"
          onClear={() => setFilterValue('')}
          onValueChange={onSearchChange}
        />
        <div className="flex gap-3">
          {/* <Dropdown>
            <DropdownTrigger className="hidden sm:flex">
              <Button
                endContent={<ChevronDownIcon className="text-small" />}
                size="sm"
                variant="flat"
              >
                {t('Type')}
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              disallowEmptySelection
              aria-label="Table Columns"
              closeOnSelect={false}
              selectedKeys={statusFilter}
              selectionMode="multiple"
              onSelectionChange={handleStatusChange}
            >
              {fileTypeOptions.map((type) => (
                <DropdownItem key={type.uid} className="capitalize">
                  {Capitalize(type.name)}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown> */}
          <SharedWithDND/>
          </div>
      </div>
      <div className="flex justify-between items-center ">
        <span className="text-default-400 text-small">
          {t('TotalFiles')}: {files.length}
        </span>
        {/* <div className="flex items-center">
          <label className="mr-2 text-default-400 text-small">
            {t('RowsPerPage')}
          </label>
          <select
            className="border border-default-200 rounded px-2 py-1"
            value={rowsPerPage}
            onChange={onRowsPerPageChange}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div> */}
      </div>
    </div>
  ), [t, filterValue, onSearchChange, statusFilter, handleStatusChange, files.length, rowsPerPage, onRowsPerPageChange, setFilterValue]);

  const bottomContent = useMemo(() => (
    <div className="py-2 px-2 flex justify-between items-center mt-4">
        <Pagination
        current={page}
        total={totalPages * rowsPerPage} // Ant Design uses total items instead of total pages
        pageSize={rowsPerPage}
        onChange={(newPage) => setPage(newPage)}
        showSizeChanger={false}
      />
      {/* <span className="text-small text-default-400">
        {selectedKeys.has('all')
          ? `${t('AllItemsSelected')}`
          : `${selectedKeys.size} ${t('of')} ${files.length} ${t('selected')}`}
      </span> */}
    </div>
  ), [page, totalPages, rowsPerPage, selectedKeys, t, files.length, setPage]);

  const specialFileTypes = ["png", "jpg", "jpeg"];

  return (
    <div
      className={`w-full p-4 ${isDragging ? 'border-1.5 border-dashed border-success h-full bg-[#2a835f48] rounded-lg' : ''}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {topContent}
      <div className="flex flex-wrap gap-6 justify-start items-center mt-4">

      {files.map((file) => {
          const isSpecialFileType = specialFileTypes.includes(
            file.file_type?.toLocaleLowerCase() || ""
          );

          return (
            <Card
              className="lg:w-[15%] w-[20rem] hover:scale-105"
              key={file.file_id}
            >
              <CardHeader className="justify-between">
                <Dropdown>
                  <DropdownTrigger>
                    <Button isIconOnly radius="full" size="sm" variant="light">
                      <VerticalDotsIcon className="text-default-400" />
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleView(file.file_id!)}>
                      {t("ViewDetails")}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => handleDownload(file.s3_redirect_url || "")}
                    >
                      {t("View")}
                    </DropdownItem>
               
                  </DropdownMenu>
                </Dropdown>
                <Button
                  isIconOnly
                  className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
                  radius="full"
                  variant="light"
                  onPress={() => handleToggleFavorite(file.file_id!)}
                >
                  <HeartIcon
                    className={
                      file.is_favorite === 1
                        ? "[&>path]:stroke-transparent"
                        : ""
                    }
                    fill={file.is_favorite === 1 ? "red" : "none"}
                  />
                </Button>
              </CardHeader>

              {isSpecialFileType ? (
                <CardBody className="px-3 py-0 text-small text-center text-default-400">
                  <div className="flex justify-center items-center">
                    <div>
                      <div
                        className="min-w-[200px] min-h-[100px] bg-contain bg-center rounded-xl"
                        style={{
                          backgroundImage: `url('${file.thumbnail_s3_url}')`,
                          backgroundRepeat: "no-repeat",
                          cursor: "pointer" // makes it clear the element is clickable
                        }}
                        onClick={() => handleDownload(file.s3_redirect_url || "")} // Trigger download on click
                      ></div>
                    </div>
                  </div>
                  <span className="pt-2 capitalize text-gray-900 text-xs font-bold flex justify-center items-center">
                    {typesIconMap[file.file_type?.toLocaleLowerCase() || ""]}{" "}
                    {file.original_filename && file.original_filename.length >= 60
                  ? `${file.original_filename.slice(0, 60)}.....`
                  : file.original_filename || ""}
                  </span>
                  <span className="pt-2 capitalize"> {file.file_size} MB</span>
                </CardBody>
              ) : (
                <CardBody className="px-3 py-0 text-small text-center text-default-400">
                  <div className="flex justify-center items-center"
                    style={{
                      cursor: "pointer" // makes it clear the element is clickable
                    }}
                    onClick={() => handleDownload(file.s3_redirect_url || "")} // Trigger download on click
                 >
                    {typesIconMap[file.file_type?.toLocaleLowerCase() || ""]}
                  </div>
                  <span className="pt-2 capitalize text-gray-900 text-sm font-bold">
                  {file.original_filename && file.original_filename.length >= 60
                  ? `${file.original_filename.slice(0, 60)}.....`
                  : file.original_filename || ""}
                  </span>
                  <span className="pt-2 capitalize"> {file.file_size} MB</span>
                </CardBody>
              )}
              <CardFooter className="gap-3"></CardFooter>
            </Card>
          );
        })}

        {/* {files.map((file) => (
          <Card className="lg:w-[23%] w-[20rem] hover:scale-105" key={file.file_id}>
            <CardHeader className="justify-between">
              <Dropdown>
                <DropdownTrigger>
                  <Button isIconOnly radius="full" size="sm" variant="light">
                    <VerticalDotsIcon className="text-default-400" />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu>
                  <DropdownItem onClick={() =>handleView(file.file_id!)}>{t('ViewDetails')}</DropdownItem>
                  <DropdownItem onClick={() => handleDownload(file.s3_redirect_url || '')}>{t('View')}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Button
                isIconOnly
                className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
                radius="full"
                variant="light"
                onPress={() => handleToggleFavorite(file.file_id!)}
              >
                <HeartIcon
                  className={file.is_favorite === 1 ? "[&>path]:stroke-transparent" : ""}
                  fill={file.is_favorite === 1 ? "red" : "none"}
                />
              </Button>
            </CardHeader>
               <CardBody className="px-3 py-0 text-small text-center text-default-400">
                <div className="flex justify-center items-center">
                  {typesIconMap[file.file_type?.toLocaleLowerCase() ?? '']}
                </div>
                <span className="pt-2 capitalize text-gray-900 text-lg font-bold">
                  {file.original_filename}
                </span>
                <span className="pt-2 capitalize">{file.created_at} . {file.file_size} MB</span>
              </CardBody>
              <CardFooter className="gap-3"></CardFooter>
           </Card>
        ))} */}
      </div>
      {bottomContent}
      {selectedFileId && (
        <DeleteFileModal isOpen={isDeleteFileOpen} onClose={onCloseDeleteFile} fileId={selectedFileId} fileType="sharedWithFile" />
      )}
      {selectedFileId && <FileDetailsModal isOpen={isFileDetailsOpen} onClose={onCloseFileDetails} fileId={selectedFileId} />}
    </div>
  );
};

export default EditorGridList;
