import React from 'react';
// import Sidebar from './components/sidebar';
import { useTranslation } from 'react-i18next';
import { useLanguageStore } from './store/layout/useLanguageStore';
import Routing from './routes/Routing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './App.css';


const App: React.FC = () => {
  useTranslation();
  const { direction } = useLanguageStore();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5, // 5 minutes
      },
    },
  });

  React.useEffect(() => {
    document.documentElement.dir = direction;
  }, [direction]);

  return (
    <>
      <div className={`App flex ${direction === 'rtl' ? 'rtl' : 'ltr'}`}>
        
      <QueryClientProvider client={queryClient}>
          <main className="w-full">
            <Routing />
          </main>
          </QueryClientProvider>
        </div>

    </>
  );
};

export default App;
