import React, { useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import MainFileIcon from "../../assets/icons/MainFileIcon";
import useFolderDetails from "src/hooks/useFolderDetails";

interface FolderDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  folderId: string;
}

const FolderDetailsModal: React.FC<FolderDetailsModalProps> = ({ isOpen, onClose, folderId }) => {
  const { t } = useTranslation();
  const { folderDetails, isProcessing, error, getFolderDetails, setFolderDetails } = useFolderDetails();

  useEffect(() => {
    if (isOpen && folderId && !folderDetails && !isProcessing) {
      getFolderDetails(folderId);
    }
  }, [isOpen, folderId, folderDetails, isProcessing, getFolderDetails]);

  useEffect(() => {
    if (!isOpen) {
      setFolderDetails(null);
    }
  }, [isOpen, setFolderDetails]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          <div className="flex items-end justify-start">
            <MainFileIcon height={30} width={30} />
            {t('Folder Details')}
          </div>
        </ModalHeader>

        <ModalBody>
          {isProcessing && <p>{t('Loading')}</p>}
          {error && <p>{t('Error: ')}{error}</p>}
          {folderDetails && (
            <Table isStriped aria-label={t('FolderDetailsTable')}>
              <TableHeader>
                <TableColumn>{t('Type')}</TableColumn>
                <TableColumn>{t('Folder')}</TableColumn>
              </TableHeader>

              <TableBody>

                <TableRow key="1">
                  <TableCell>{t('Name')}</TableCell>
                  <TableCell>{folderDetails.name}</TableCell>
                </TableRow>

                <TableRow key="2">
                <TableCell>{t('Owner')}</TableCell>
                  <TableCell>{folderDetails.owner}</TableCell>
                </TableRow>

                <TableRow key="3">
                <TableCell>{t('SharedWith')}</TableCell>
                  <TableCell>{folderDetails.share_with_usernames}</TableCell>
                </TableRow>

                <TableRow key="4">
                <TableCell>{t('Modified')}</TableCell>
                <TableCell>{folderDetails.updated_at}</TableCell>
              </TableRow>

              <TableRow key="5">
                <TableCell>{t('Created')}</TableCell>
                <TableCell>{folderDetails.created_at}</TableCell>
              </TableRow>
              </TableBody>
            </Table>
          )}
          {!folderDetails && !isProcessing && !error && <p>{t('NoFolderDetailsAvailable')}</p>}
        </ModalBody>

        <ModalFooter>
          <Button color="danger" variant="light" onPress={onClose}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FolderDetailsModal;
