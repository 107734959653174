import React from 'react';

interface MovIconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly as colors are hardcoded in SVG
}

const MovIcon: React.FC<MovIconProps> = ({ width = 40, height = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34 50H6C4.4087 50 2.88258 49.3679 1.75736 48.2426C0.63214 47.1174 0 45.5913 0 44V11L9 0H34C35.5913 0 37.1174 0.632141 38.2426 1.75736C39.3679 2.88258 40 4.4087 40 6V44C40 45.5913 39.3679 47.1174 38.2426 48.2426C37.1174 49.3679 35.5913 50 34 50Z"
      fill="#50BEE8"
    />
    <path
      d="M9 9.556C9 9.93897 8.84787 10.3063 8.57706 10.5771C8.30626 10.8479 7.93897 11 7.556 11H0L9 0V9.556Z"
      fill="#9AE3FF"
    />
    <path
      d="M20 27C23.866 27 27 23.866 27 20C27 16.134 23.866 13 20 13C16.134 13 13 16.134 13 20C13 23.866 16.134 27 20 27Z"
      fill="white"
    />
    <path
      d="M19.014 23.3019C18.3759 23.7148 17.854 23.4309 17.854 22.6709V17.3289C17.854 16.5691 18.3759 16.285 19.014 16.6977L22.9571 19.2492C23.5951 19.6621 23.5951 20.3378 22.9571 20.7501L19.014 23.3019Z"
      fill="#50BEE8"
    />
    <path
      d="M7.65 35.4735V40.0954C7.65 40.6469 7.2 40.9579 6.75 40.9579C6.35 40.9579 6 40.6469 6 40.0954V33.0626C6 32.4126 6.55 32.1985 6.75 32.1985C7.11094 32.1985 7.32344 32.4126 7.5375 32.6501L10.2844 36.3594L13.1328 32.511C13.5469 32.0001 14.5578 32.1985 14.5578 33.0626V40.0954C14.5578 40.6469 14.2094 40.9579 13.8094 40.9579C13.3594 40.9579 13.0094 40.6469 13.0094 40.0954V35.4735L10.9344 38.0969C10.4859 38.6485 9.92344 38.6485 9.51094 38.0969L7.65 35.4735Z"
      fill="white"
    />
    <path
      d="M21.0453 41.0828C18.7343 41.1828 16.3344 39.6453 16.3344 36.5844C16.3344 33.5109 18.7328 32 21.0453 32C23.2312 32.1109 25.5172 33.6234 25.5172 36.5844C25.5172 39.5469 23.2312 41.0828 21.0453 41.0828ZM20.9078 33.625C19.5078 33.625 17.9844 34.6125 17.9844 36.5859C17.9844 38.5484 19.5094 39.5484 20.9078 39.5484C22.3453 39.5484 23.8812 38.5484 23.8812 36.5859C23.8812 34.6109 22.3453 33.625 20.9078 33.625Z"
      fill="white"
    />
    <path
      d="M29.7922 40.3969L26.4938 33.4875C26.0063 32.5 27.4813 31.6891 28.0297 32.725L29.1422 35.236L30.5782 38.5344L31.9891 35.236L33.1016 32.725C33.6016 31.7766 35.05 32.375 34.6266 33.4125L31.4407 40.3969C31.1532 41.1844 30.2657 41.3703 29.7922 40.3969Z"
      fill="white"
    />
  </svg>
);

export default MovIcon;
