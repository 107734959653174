// DeleteFileModal.tsx
import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import useFilesStore from "src/store/layout/useFilesStore";
import useRefreshTrash from "src/hooks/useRefreshTrash";
import useRefreshListAllSharedByMe from "src/hooks/useRefreshListAllSharedByMe";
import useRefreshListAllFolders from "src/hooks/useRefreshListAllFolders";

interface DeleteFileModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileId: string;
  fileType: string;
}

const DeleteFileModal: React.FC<DeleteFileModalProps> = ({ isOpen, onClose, fileId, fileType }) => {
  const { t } = useTranslation();
  const deleteFile = useFilesStore((state) => state.deleteFile);
  const { refreshListAllShareByUser, shareByCurrentPage, totalSharedByItems } = useRefreshListAllSharedByMe();
  const { refeshListAllFoldersFiles, currentPage, totalItems } = useRefreshListAllFolders();
  const { fetchTrashedItems,  currentPage: TrashCurrentPage, totalItems: TrashTotalItems } = useRefreshTrash();

  const handleDelete = async () => {

    await deleteFile(fileId, fileType);
    if (fileType !== 'sharedWithFile'){
      await refreshListAllShareByUser(shareByCurrentPage, totalSharedByItems);
      await refeshListAllFoldersFiles(currentPage, totalItems);
    }

    await fetchTrashedItems(TrashCurrentPage, TrashTotalItems);

    onClose(); // Close the modal after successful deletion
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          {t("DeleteFile")}
        </ModalHeader>
        <ModalBody>
          <div className="flex flex-col gap-4">
            <h2>{t('AreYouSureToDelete')}</h2>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" variant="light" onPress={onClose}>
            {t("No")}
          </Button>
          <Button color="danger" onPress={handleDelete}>
            {t("Yes")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteFileModal;