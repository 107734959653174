//src/hooks/useListAllSharedWithMe.ts
import useShareStore from 'src/store/api/useShareStore';
import { useSidebarStore } from 'src/store/layout/useSidebarStore';
 
const useRefreshListAllSharedWithMe = () => {
  const { selectedItemId } = useSidebarStore((state) => ({
    selectedItemId: state.selectedItemId,
  }));
  const { refreshListAllShareWithUser, isShareWithLoading, ShareWithError, ShareWithSuccessMessage, sharedWithUserItems, shareWithCurrentPage, shareWithTotalPages, totalSharedWithItems } = useShareStore((state) => ({
    refreshListAllShareWithUser: state.refreshListAllShareWithUser,
    isShareWithLoading: state.isShareWithLoading,
    ShareWithError: state.ShareWithError,
    ShareWithSuccessMessage: state.ShareWithSuccessMessage,
    sharedWithUserItems: state.sharedWithUserItems,
    shareWithCurrentPage: state.shareWithCurrentPage,
    shareWithTotalPages: state.shareWithTotalPages,
    totalSharedWithItems: state.totalSharedWithItems,
  }));

  return { refreshListAllShareWithUser, isShareWithLoading, ShareWithError, ShareWithSuccessMessage, sharedWithUserItems, shareWithCurrentPage, shareWithTotalPages, totalSharedWithItems, ID: selectedItemId};
};

export default useRefreshListAllSharedWithMe;
