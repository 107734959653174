import React from 'react';

interface JpegIconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly as colors are hardcoded in SVG
}

const JpegIcon: React.FC<JpegIconProps> = ({ width = 40, height = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34 50H6C4.4087 50 2.88258 49.3679 1.75736 48.2426C0.63214 47.1174 0 45.5913 0 44V11L9 0H34C35.5913 0 37.1174 0.632141 38.2426 1.75736C39.3679 2.88258 40 4.4087 40 6V44C40 45.5913 39.3679 47.1174 38.2426 48.2426C37.1174 49.3679 35.5913 50 34 50Z"
      fill="#2F88FF"
    />
    <path
      d="M9 9.556C9 9.93897 8.84787 10.3063 8.57706 10.5771C8.30626 10.8479 7.93897 11 7.556 11H0L9 0V9.556Z"
      fill="#83B8FF"
    />
    <path
      d="M7.97789 23.8116C7.97789 22.7295 9.48571 22.7295 9.48571 23.8116V28.8966C9.48571 30.9191 8.629 32.1429 6.65426 32.1429C5.68332 32.1429 4.87372 31.845 4.1655 30.816C3.58293 30.0302 4.67953 28.8983 5.27352 29.7211C5.75328 30.3909 6.26731 30.5455 6.76849 30.5198C7.40675 30.494 7.9679 30.1703 7.97932 28.8966V23.8116H7.97789Z"
      fill="white"
    />
    <path
      d="M12.2285 23.8104C12.2285 23.4228 12.5333 23 13.0258 23H15.7326C17.2568 23 18.6285 24.0218 18.6285 25.9802C18.6285 27.8358 17.2568 28.8694 15.7326 28.8694H13.7761V30.4197C13.7761 30.9364 13.4478 31.2286 13.0258 31.2286C12.6389 31.2286 12.2285 30.9364 12.2285 30.4197V23.8104ZM13.7761 24.4784V27.4028H15.7326C16.5181 27.4028 17.1395 26.7084 17.1395 25.9802C17.1395 25.1595 16.5181 24.4784 15.7326 24.4784H13.7761Z"
      fill="white"
    />
    <path
      d="M35.711 31.071C34.8862 31.8238 33.8755 32.1429 32.8547 32.1429C30.4151 32.1429 28.6857 30.6132 28.6857 27.5762C28.6857 25.0018 30.5095 23 32.9578 23C33.8755 23 34.8847 23.3429 35.605 24.1213C36.3122 24.8854 35.2913 26.021 34.5958 25.3463C34.1659 24.8869 33.5619 24.5424 32.9578 24.5424C31.5536 24.5424 30.1959 25.7786 30.1959 27.5762C30.1959 29.4648 31.3329 30.6132 32.8547 30.6132C33.5619 30.6132 34.1659 30.3819 34.5958 30.039V28.471H32.8547C31.8208 28.471 31.9253 26.9143 32.8547 26.9143H35.1766C35.6064 26.9143 36 27.2716 36 27.6671V30.3835C36 30.6132 35.9085 30.8269 35.711 31.071Z"
      fill="white"
    />
    <path
      d="M21.2371 32.1429C20.7968 32.1429 20.4572 31.7859 20.4572 31.3233V23.8196C20.4572 23.3437 20.7968 23 21.2371 23H25.7504C26.9078 23 26.8826 24.7169 25.7504 24.7169H22.1304V26.6998H25.299C26.4171 26.6998 26.4171 28.4315 25.299 28.4315H22.1304V30.4128H25.9658C27.1106 30.4128 27.1971 32.1429 25.9658 32.1429H21.2371Z"
      fill="white"
    />
  </svg>
);

export default JpegIcon;
