import React from 'react';

interface PdfIconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly as colors are hardcoded in SVG
}

const PdfIcon: React.FC<PdfIconProps> = ({ width = 40, height = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: "100%", height: "100%" }}
  >
    <path
      d="M34 50H6C4.4087 50 2.88258 49.3679 1.75736 48.2426C0.63214 47.1174 0 45.5913 0 44V11L9 0H34C35.5913 0 37.1174 0.632141 38.2426 1.75736C39.3679 2.88258 40 4.4087 40 6V44C40 45.5913 39.3679 47.1174 38.2426 48.2426C37.1174 49.3679 35.5913 50 34 50Z"
      fill="#771B1B"
    />
    <path
      d="M9 9.556C9 9.93897 8.84787 10.3063 8.57706 10.5771C8.30626 10.8479 7.93897 11 7.556 11H0L9 0V9.556Z"
      fill="#A72825"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2499 16C19.918 16 20.5072 16.308 20.9469 16.9212C21.9928 18.3595 21.9195 19.8912 21.5333 21.9537C21.4008 22.6633 21.5361 23.381 21.9392 23.9777C22.5087 24.8247 23.329 25.9907 24.1352 26.978C24.7272 27.7068 25.6378 28.0945 26.5596 27.9735C27.1516 27.8937 27.8648 27.8278 28.5978 27.8278C31.8678 27.8278 31.6226 28.9662 31.9186 30.0112C32.0821 30.6437 32.0003 31.1965 31.6648 31.6063C31.2476 32.159 30.3878 32.445 29.1306 32.445C27.738 32.445 27.2785 32.214 26.0663 31.8373C25.3813 31.6227 24.6709 31.5787 23.9774 31.7575C22.5933 32.1095 20.3098 32.6458 18.4042 32.9015C17.3865 33.039 16.4619 33.5505 15.8473 34.3837C14.4632 36.2537 13.1805 38 11.148 38C10.6237 38 10.1416 37.8158 9.74417 37.4473C9.15781 36.8752 8.90691 36.24 9.03096 35.503C9.37486 33.2398 12.2277 31.9775 13.9248 31.0563C14.3251 30.839 14.6408 30.5062 14.8381 30.0937L17.5303 24.415C17.722 24.0162 17.7812 23.5735 17.7107 23.1362C17.3978 21.2112 16.9355 17.9745 18.1787 16.5115C18.4521 16.1842 18.8299 16 19.2499 16ZM19.763 24.2775C18.6833 27.3603 16.7748 30.7125 16.5183 31.103C18.8214 30.047 23.4051 29.5135 23.4051 29.5135C21.0992 27.3465 19.763 24.2775 19.763 24.2775Z"
      fill="white"
    />
  </svg>
);

export default PdfIcon;
