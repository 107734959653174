import { useEffect } from "react";
import EyeIcon from "../../assets/icons/EyeIcon";
import {
  Avatar,
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import LanguageSwitcher from "../switchers/LanguageSwitcher";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLastThreeNotifications } from "../../hooks/useListLastThreeNotifications";
import { useMarkNotificationAsRead } from "../../hooks/useMarkNotificationAsRead";
import { Notification } from "../../types/api/Notification";
import { useAuthStore } from "src/store/api/useAuthStore";

function HeaderLinks() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifications, totalUnRead, listLastThreeNotifications } = useLastThreeNotifications();
  const { markNotificationAsRead } = useMarkNotificationAsRead();
  const notificationsLastList = notifications || [];
  const nameEn = useAuthStore((state) => state.nameEn);
  useEffect(() => {
    if (!notifications) listLastThreeNotifications();
  }, [listLastThreeNotifications, notifications]);

  const handleMarkAsRead = async (notificationId: string) => {
    try {
      await markNotificationAsRead(notificationId);
     } catch (error) {
      console.error("Failed to mark notification as read:", error);
    }
  };

  const handleNotificationClick = () => {
    navigate("/notifications");
  };

  const handleSignOut = () => {
    useAuthStore.getState().clearToken();
    navigate("/home");
  };


  return (
    <>
      <div className="flex items-center mt-4 lg:mt-0 gap-4">
        {/* noti  */}
        <Dropdown>
          <Badge
            color="danger"
            content={totalUnRead}
            shape="circle"
            className=""
          >
            <DropdownTrigger>
              <svg
                className="w-8 h-7"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 17H20L18.5951 15.5951C18.2141 15.2141 18 14.6973 18 14.1585V11C18 8.38757 16.3304 6.16509 14 5.34142V5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5V5.34142C7.66962 6.16509 6 8.38757 6 11V14.1585C6 14.6973 5.78595 15.2141 5.40493 15.5951L4 17H9M15 17V18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18V17M15 17H9"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </DropdownTrigger>
          </Badge>
          <DropdownMenu aria-label="Notifications" className="custom-dropdown">
            {notificationsLastList.map((notification: Notification) => (
              <DropdownItem
                key={notification.notification_id}
                className={notification.is_read === 0 ? "bg-gray-200" : ""}
                onClick={handleNotificationClick}
              >
                <div className="flex justify-between">
                  <span>
                    {notification.message.length > 15
                      ? `${notification.message.slice(0, 50)}.....`
                      : notification.message}
                  </span>
                  <EyeIcon
                    onClick={() => {
                      if (notification.notification_id) {
                        handleMarkAsRead(notification.notification_id);
                      }
                    }}
                  />
                </div>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>

        {/* lang  */}
        <div className="mt-2 ltr:ml-1.5 rtl:mr-1">
          <LanguageSwitcher />
        </div>

        {/* {isSaff && (
          
          <div onClick={toMySAFF} className="">
            <Tooltip color="foreground" content={t('BackToMySAFF')}>

            <img
            
              className="w-auto h-8 max-sm:h-9 shadow border-1 rounded-lg"
              src={Logo}
              alt="Logo"
            />
            </Tooltip>
          </div>
        )} */}

        {/* user  */}
        <div className="px-3 py-3">
          <Dropdown placement="bottom-end">
            <DropdownTrigger>
              <Avatar
                isBordered
                as="button"
                className="transition-transform"
                color="default"
                size="sm"
                src=""
              />
            </DropdownTrigger>
            <DropdownMenu aria-label="Profile Actions" variant="flat">
              <DropdownItem key="profile" className="h-14 gap-2">
                <p className="font-semibold">{t("Hello")}{nameEn}</p>
              </DropdownItem>
              {/* <DropdownItem key="settings">My Settings</DropdownItem>
                    <DropdownItem key="help_and_feedback">
                      Help & Feedback
                    </DropdownItem> */}
              <DropdownItem key="logout" color="danger" onClick={handleSignOut}>
                {t("SignOut")}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </>
  );
}

export default HeaderLinks;
