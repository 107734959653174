import { create } from 'zustand';
import axios from 'axios';
import { uploadFiles } from 'src/services/api/API';
import { FileStore } from 'src/types/api/UploudFiles';
import { useSidebarStore } from './useSidebarStore';
import { useFolderStore } from 'src/components/main_page/cards/FolderCard';
import { useSharedWithFolderStore } from 'src/components/shared_files/cards/SharedWithMeFolderCard';
import { useSharedByFolderStore } from 'src/components/shared_files/cards/SharedByMeFolderCard';

export const useUploadFileStore = create<FileStore>((set, get) => ({
  files: [],
  uploading: false,
  description: '',
  
  setFiles: (files) => set({ files }),
  setUploading: (uploading) => set({ uploading }),
  setDescription: (description) => set({ description }),
  
  addFiles: (newFiles) => set((state) => ({ files: [...state.files, ...newFiles] })),
  
  updateFileProgress: (id, progress) => set((state) => ({
    files: state.files.map((file) =>
      file.id === id ? { ...file, progress } : file
    ),
  })),
  
  removeFile: (id) => set((state) => ({
    files: state.files.filter((file) => {
      if (file.id === id) {
        file.cancelTokenSource.cancel('Upload canceled by user');
        return false;
      }
      return true;
    }),
  })),
  
  clearFiles: () => set({ files: [] }),
  
  uploadFiles: async (token: string, description: string) => {
    const { files, updateFileProgress, clearFiles, setUploading, setDescription } = get();
    const selectedItemId = useSidebarStore.getState().selectedItemId; // Fetching latest selectedItemId
    const folderId  = useFolderStore.getState().folderId; // Fetching latest folderId

    if (!selectedItemId) {
      console.error('Directory ID or Folder ID is not selected');
      return;
    }

    setUploading(true);

    try {
      await Promise.all(files.map(async (fileWithProgress) => {
        await uploadFiles(
          fileWithProgress.file,
          description,
          token,
          selectedItemId,
          folderId,
          (progressEvent: ProgressEvent) => {
            if (progressEvent.total) {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              updateFileProgress(fileWithProgress.id, progress);
            }
          },
          fileWithProgress.cancelTokenSource.token
        );
      }));
      clearFiles();
      setDescription('');
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        console.error("Upload error:", error);
      }
    } finally {
      setUploading(false);
    }
  },


  uploadSharedByFiles: async (token: string, description: string) => {
    const { files, updateFileProgress, clearFiles, setUploading, setDescription } = get();
    const selectedItemId = useSidebarStore.getState().selectedItemId; // Fetching latest selectedItemId
    const folderId = useSharedByFolderStore.getState().folderId; // Fetching latest folderId

 
    if (!selectedItemId) {
      console.error('Directory ID or Folder ID is not selected');
      return;
    }

    setUploading(true);

    try {
      await Promise.all(files.map(async (fileWithProgress) => {
        await uploadFiles(
          fileWithProgress.file,
          description,
          token,
          selectedItemId,
          folderId,
          (progressEvent: ProgressEvent) => {
            if (progressEvent.total) {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              updateFileProgress(fileWithProgress.id, progress);
            }
          },
          fileWithProgress.cancelTokenSource.token
        );
      }));
      clearFiles();
      setDescription('');
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        console.error("Upload error:", error);
      }
    } finally {
      setUploading(false);
    }
  },

  uploadSharedWithFiles: async (token: string, description: string) => {
    const { files, updateFileProgress, clearFiles, setUploading, setDescription } = get();
    const selectedItemId = useSidebarStore.getState().selectedItemId; // Fetching latest selectedItemId
    const folderId = useSharedWithFolderStore.getState().folderId; // Fetching latest folderId
    const directoryId = useSharedWithFolderStore.getState().directoryId; // Fetching latest folderId


    if (!selectedItemId && !directoryId) {
      console.error('Directory ID or Folder ID is not selected');
      return;
    }
 
    setUploading(true);

    try {
      await Promise.all(files.map(async (fileWithProgress) => {
        await uploadFiles(
          fileWithProgress.file,
          description,
          token,
          selectedItemId || directoryId!,
          folderId,
          (progressEvent: ProgressEvent) => {
            if (progressEvent.total) {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              updateFileProgress(fileWithProgress.id, progress);
            }
          },
          fileWithProgress.cancelTokenSource.token
        );
      }));
      clearFiles();
      setDescription('');
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        console.error("Upload error:", error);
      }
    } finally {
      setUploading(false);
    }
  },
  


}));
