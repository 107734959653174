import React from 'react';

interface XlsxIconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly in SVG paths as they have specific colors defined
}

const XlsxIcon: React.FC<XlsxIconProps> = ({ width = 72, height = 72 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 72 72"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z"
      fill="#36c684"
    />
    <path
      d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z"
      fill="#95e5bd"
    />
    <path
      d="M42,31H30a3.0033,3.0033,0,0,0-3,3V45a3.0033,3.0033,0,0,0,3,3H42a3.0033,3.0033,0,0,0,3-3V34A3.0033,3.0033,0,0,0,42,31ZM29,38h6v3H29Zm8,0h6v3H37Zm6-4v2H37V33h5A1.001,1.001,0,0,1,43,34ZM30,33h5v3H29V34A1.001,1.001,0,0,1,30,33ZM29,45V43h6v3H30A1.001,1.001,0,0,1,29,45Zm13,1H37V43h6v2A1.001,1.001,0,0,1,42,46Z"
      fill="#fff"
    />
  </svg>
);

export default XlsxIcon;
