// src/hooks/useLoginUser.ts
import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import  { AxiosError } from 'axios';
import { loginUser } from '../services/api/API';
import { useAuthStore } from '../store/api/useAuthStore';
import { LoginRequest, LoginResponse } from 'src/types/api/UserList';

const useLoginUser = (): UseMutationResult<LoginResponse, AxiosError, LoginRequest> => {
  const setToken = useAuthStore(state => state.setToken);
  const mutationOptions: UseMutationOptions<LoginResponse, AxiosError, LoginRequest> = {
    mutationFn: loginUser,
    onSuccess: (data) => {
      // Handle successful login, e.g., setting user data, redirecting, etc.\
      setToken(data.token); // Set token in Zustand store
    },
    onError: (error: AxiosError) => {
      console.error("Login failed:", error.message);
      // Handle specific error scenarios here if needed
    }
  };

  return useMutation<LoginResponse, AxiosError, LoginRequest>(mutationOptions);
};

export default useLoginUser;
