import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import MainFileIcon from "../../assets/icons/MainFileIcon";

interface PublicFolderDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  folderName: string;
  folderOwner: string;
}

const PublicFolderDetailsModal: React.FC<PublicFolderDetailsModalProps> = ({ isOpen, onClose, folderName, folderOwner }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          <div className="flex items-end justify-start">
            <MainFileIcon height={30} width={30} />
            {t('Folder Details')}
          </div>
        </ModalHeader>

        <ModalBody>

            <Table isStriped aria-label={t('FolderDetailsTable')}>
              <TableHeader>
                <TableColumn>{t('Type')}</TableColumn>
                <TableColumn>{t('Folder')}</TableColumn>
              </TableHeader>

              <TableBody>

                <TableRow key="1">
                  <TableCell>{t('Name')}</TableCell>
                  <TableCell>{folderName}</TableCell>
                </TableRow>

                <TableRow key="2">
                <TableCell>{t('Owner')}</TableCell>
                  <TableCell>{folderOwner}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </ModalBody>

        <ModalFooter>
          <Button color="danger" variant="light" onPress={onClose}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PublicFolderDetailsModal;
