import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { useEditRolesModalStore, useUserListStore } from "src/store/api/useUserListStore";
import { User } from 'src/types/api/UserList';
import { Alert } from "antd";  // Import Alert component

interface EditRolesModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: User;
}

const EditRolesModal: React.FC<EditRolesModalProps> = ({
  isOpen,
  onClose,
  user,
}) => {
  const { t } = useTranslation();
  const {
    nameEn,
    nameAr,
    email,
    phone,
    roles,
    password,
    setNameEn,
    setNameAr,
    setEmail,
    setPhone,
    setRoles,
    setPassword,
  } = useEditRolesModalStore();

  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [resetAuthState, setResetAuthState] = useState<{
    clicked: boolean;
    color: 'warning' | 'success';
    label: string;
  }>({ clicked: false, color: 'warning', label: t("ResetAuthenticator") });
  const [responseMessage, setResponseMessage] = useState<string | null>(null);  // New state for response message
  const [responseStatus, setResponseStatus] = useState<'success' | 'error' | null>(null);  // New state for response status

  const updateUserFromStore = useUserListStore((state) => state.updateUser); // Fetch the updateUser function from the store

  useEffect(() => {
    if (user) {
      setNameEn(user.full_name_en || '');
      setNameAr(user.full_name_ar || '');
      setEmail(user.email || '');
      setPhone(user.phone || '');
      setPassword('');
    }
  }, [user, setNameEn, setNameAr, setEmail, setPhone, setRoles, setPassword]);

  const validateInputs = () => {
    const validationErrors: { [key: string]: string } = {};

    if (!nameEn) validationErrors.nameEn = t("UserNameENIsRequired");
    if (!nameAr) validationErrors.nameAr = t("UserNameARIsRequired");
    if (!email) validationErrors.email = t("EmailIsRequired");
    if (!phone) validationErrors.phone = t("PhoneNumberIsRequired");
    if (roles.length === 0) validationErrors.roles = t("AtLeastOneRoleIsRequired");

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateInputs()) {
      return;
    }

    const updatedUser: User = {
      ...user,
      full_name_en: nameEn,
      full_name_ar: nameAr,
      email: email,
      phone: phone,
      password: password || user.password,
    };

    if (!user.user_id) {
      setResponseMessage('User ID is missing.');
      setResponseStatus('error');
      return;
    }

    const response = await updateUserFromStore(user.user_id.toString(), updatedUser);  // Directly call updateUser from the store

    // Set the response message and status
    setResponseMessage(response.message || '');
    setResponseStatus(response.success ? 'success' : 'error');

    if (response.success) {
  
    }
  };

  const handleChangePassword = () => {
    setPassword("");
    setConfirmPassword("");
  };

  const handleResetAuthenticator = () => {
    setResetAuthState({
      clicked: true,
      color: resetAuthState.color === 'warning' ? 'success' : 'warning',
      label: resetAuthState.color === 'warning' ? t("AuthenticatorReset") : t("ResetAuthenticator"),
    });
    console.log("Reset Authenticator Clicked");
  };

  return (
    <Modal scrollBehavior="inside" size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>{t("EditUserRoles")}</ModalHeader>
        <ModalBody>
          {/* Display the response message */}
          {responseMessage && (
            <Alert message={responseMessage} type={responseStatus === 'success' ? 'success' : 'error'} showIcon closable />
          )}

          <form className="flex flex-col gap-4">
            <Input
              type="text"
              label={t("UserNameEN")}
              labelPlacement="outside"
              value={nameEn}
              onChange={(e) => setNameEn(e.target.value)}
              isInvalid={!!errors.nameEn}
              validationState={errors.nameEn ? 'invalid' : 'valid'}
              errorMessage={errors.nameEn}
            />
            <Input
              type="text"
              label={t("UserNameAR")}
              labelPlacement="outside"
              value={nameAr}
              onChange={(e) => setNameAr(e.target.value)}
              isInvalid={!!errors.nameAr}
              validationState={errors.nameAr ? 'invalid' : 'valid'}
              errorMessage={errors.nameAr}
            />
            <Input
              type="email"
              label={t("Email")}
              labelPlacement="outside"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isInvalid={!!errors.email}
              validationState={errors.email ? 'invalid' : 'valid'}
              errorMessage={errors.email}
            />
            <Input
              type="tel"
              label={t("PhoneNumber")}
              labelPlacement="outside"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              isInvalid={!!errors.phone}
              validationState={errors.phone ? 'invalid' : 'valid'}
              errorMessage={errors.phone}
            />

            <Button color='primary' onPress={() => setShowPasswordFields(!showPasswordFields)}>
              {showPasswordFields ? t("HidePasswordFields") : t("ShowPasswordFields")}
            </Button>

            {showPasswordFields && (
              <>
                <Input
                  type="password"
                  label={t("Password")}
                  labelPlacement="outside"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Input
                  type="password"
                  label={t("ConfirmPassword")}
                  labelPlacement="outside"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {(password && confirmPassword && password === confirmPassword) && (
                  <Button color="primary" onPress={handleChangePassword}>
                    {t("ChangePassword")}
                  </Button>
                )}
              </>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" variant="light" onPress={onClose}>
            {t("Close")}
          </Button>
          <Button color={resetAuthState.color} onPress={handleResetAuthenticator}>
{  t("ResetAuthenticator")}
          </Button>
          <Button color="primary" onPress={handleSave}>
            {t("Save")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditRolesModal;
