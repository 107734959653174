import React, { useEffect } from "react";
import NavBar from "../components/header/NarBar";
import MainSidebar from "../components/sidebar/MainSidebar";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import GridList from "../components/main_page/FilesGridList";
import { useFolderStore } from "src/components/main_page/cards/FolderCard";
import DownloadList from "src/components/common/DownloadList";

const Homepage: React.FC = () => {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const clearFolderId = useFolderStore((state) => state.clearFolderId);
  const folderId = useFolderStore.getState().folderId;
  useEffect(() => {
    // console.log("clearFolderId",folderId)
    // console.log("hissss",clearFolderId)
    // console.log("clearFolderId",folderId)
    clearFolderId(); // Clear the folder ID when the homepage is loaded
  }, [clearFolderId, folderId]);

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="bg-main min-h-screen overflow-x-hidden">
      <NavBar sidebarOpen={sidebarOpen} setSidebarOpen={handleSidebarToggle} />
      <div className="flex">
        <MainSidebar sidebarOpen={sidebarOpen} setSidebarOpen={handleSidebarToggle} />

        <div className="flex-1 p-4 overflow-x-hidden">
          {/* Breadcrumbs */}
          <Breadcrumbs isDisabled>
            <BreadcrumbItem>{t("SAFFCLOUD")}</BreadcrumbItem>
            <BreadcrumbItem>{t("HomePage")}</BreadcrumbItem>
          </Breadcrumbs>

          <div className="text-[33px] capitalize text-navy-700 mt-4 mb-5">
            <div className="font-bold capitalize hover:text-navy-700">
              {t("HomePage")}
            </div>
          </div>

          <div className="max-sm:hidden">
            <DownloadList />
          </div>

          <GridList />
        </div>
      </div>
    </div>
  );
};

export default Homepage;
