// src/store/useFilesStore.ts
import { create } from 'zustand';
import axios from 'axios';
import { useAuthStore } from '../api/useAuthStore';
import { emptyTrashFile, getFileDetails } from 'src/services/api/API';

export interface ListFiles {
  files?: File[];
  totalPages?: number;
  totalFiles?: number;
  currentPage?: number;
}

export interface File {
  file_id?: string;
  user_id?: number;
  folder_id?: string;
  directory_id?: string;
  file_name?: string;
  file_path?: string;
  file_size?: number;
  file_category?: string;
  is_trashed?: number;
  is_trashed_soft?: number;
  date_trashed_soft?: null | string;
  date_trashed?: null;
  created_at?: string;
  updated_at?: string;
  s3_url?: string;
  s3_key?: string;
  s3_bucket?: string;
  mime_type?: string;
  last_accessed?: null;
  checksum?: string;
  year?: number;
  month?: number;
  file_type?: string;
  original_filename?: string;
  description?: null;
  download_count?: number;
  view_count?: number;
  s3_redirect_url?: null;
  backup_s3_url?: null;
  backup_s3_key?: null;
  backup_s3_bucket?: null;
  backup_s3_redirect_url?: null;
  is_favorite?: number;
}

// Interface for folder details
export interface FileDetails extends File {
  owner?: string; // A string listing usernames who have access
  share_with_usernames?: string | "-"; // A string listing usernames who have access
 }

interface StoreState {
  page: number;
  rowsPerPage: number;
  files: File[];
  totalPages: number;
  error: string | null;
  isLoading: boolean;                            // Loading state
  successMessage: string | null;                 // Success message
  filterValue: string;
  selectedKeys: Set<string>;
  statusFilter: Set<string>;
  fileDetails: FileDetails | null;

  setFilterValue: (value: string) => void;
  setStatusFilter: (value: Set<string>) => void;
  setRowsPerPage: (value: number) => void;
  setPage: (value: number) => void;
  setSelectedKeys: (value: Set<string>) => void;
  fetchFiles: () => Promise<void>;
  // restoreFile: (fileId: string) => Promise<void>;
  emptyTrashFiles: () => Promise<void>;
  getFileDetails: (fileId: string) => Promise<void>;

}

const useFilesStore = create<StoreState>((set, get) => ({
  files: [],
  totalPages: 1,
  filterValue: '',
  selectedKeys: new Set<string>(),
  statusFilter: new Set<string>(),
  rowsPerPage: 10,
  page: 1,
  error: null,
  isLoading: false,
  successMessage: null,
  fileDetails: null,

  setFilterValue: (value: string) => set({ filterValue: value }),
  setStatusFilter: (value: Set<string>) => set({ statusFilter: value }),
  setRowsPerPage: (value: number) => set({ rowsPerPage: value }),
  setPage: (value: number) => set({ page: value }),
  setSelectedKeys: (value: Set<string>) => set({ selectedKeys: value }),

  fetchFiles: async () => {
    const token = useAuthStore.getState().token;
    const { page, rowsPerPage } = get();
    try {
      const response = await axios.get<ListFiles>('https://saff1.cloud/cloud-storage-backend/folder/MFNTUW4ySXFSWjFPTkJoUmRoekM0dz09/list-files', {
        headers: { Authorization: `Bearer ${token}` },
        params: { page, rowsPerPage }
      });
      set({
        files: response.data.files || [],
        totalPages: response.data.totalPages || 1,
      });
    } catch (error) {
      console.error('Error fetching files:', error);
      set({ error: 'Error fetching files' });
    }
  },

  // restoreFile: async (fileId: string) => {
  //   set({ isLoading: true, error: null, successMessage: null });
  //   try {
  //     const { token } = useAuthStore.getState();
  //     if (!token) throw new Error('No token found');
  //     const result = await restoreFile(fileId, token);
  //     set({ successMessage: result.message, isLoading: false });
  //   } catch (error: any) {
  //     set({ error: error.message, isLoading: false });
  //   }
  // },

  emptyTrashFiles: async () => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await emptyTrashFile(token);
      set({ successMessage: result.message, isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  getFileDetails: async (fileId: string) => {
    set({ isLoading: true, error: null, fileDetails: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await getFileDetails(fileId, token);
      set({ fileDetails: result.file, isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

}));

export default useFilesStore;
