//src/hooks/useListAllSharedByMe.ts
import useShareStore from 'src/store/api/useShareStore';
import { useSidebarStore } from 'src/store/layout/useSidebarStore';
 
const useListAllSharedByMe = () => {
  const { selectedItemId } = useSidebarStore((state) => ({
    selectedItemId: state.selectedItemId,
  }));
  const { listAllShareByUser, isShareByLoading, ShareByError, ShareBySuccessMessage, sharedByUserItems, shareByCurrentPage, shareByTotalPages, totalSharedByItems } = useShareStore((state) => ({
    listAllShareByUser: state.listAllShareByUser,
    isShareByLoading: state.isShareByLoading,
    ShareByError: state.ShareByError,
    ShareBySuccessMessage: state.ShareBySuccessMessage,
    sharedByUserItems: state.sharedByUserItems,
    shareByCurrentPage: state.shareByCurrentPage,
    shareByTotalPages: state.shareByTotalPages,
    totalSharedByItems: state.totalSharedByItems,
  }));

  return { listAllShareByUser, isShareByLoading, ShareByError, ShareBySuccessMessage, sharedByUserItems, shareByCurrentPage, shareByTotalPages, totalSharedByItems, ID: selectedItemId};
};

export default useListAllSharedByMe;
