import React from 'react';

interface DocIconProps {
  width?: number;
  height?: number;
  colorPrimary?: string;
  colorSecondary?: string;
  colorDetails?: string;
}

const DocIcon: React.FC<DocIconProps> = ({
  width = 72,
  height = 72,
  colorPrimary = '#599def',
  colorSecondary = '#c2e1ff',
  colorDetails = '#fff'
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 72"
      width={width}
      height={height}
    >
      <g>
        <path
          d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z"
          style={{ fill: colorPrimary }}
        />
        <path
          d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z"
          style={{ fill: colorSecondary }}
        />
        <rect
          x="27"
          y="31"
          width="18"
          height="2"
          rx="1"
          ry="1"
          style={{ fill: colorDetails }}
        />
        <rect
          x="27"
          y="36"
          width="18"
          height="2"
          rx="1"
          ry="1"
          style={{ fill: colorDetails }}
        />
        <rect
          x="27"
          y="41"
          width="18"
          height="2"
          rx="1"
          ry="1"
          style={{ fill: colorDetails }}
        />
        <rect
          x="27"
          y="46"
          width="12"
          height="2"
          rx="1"
          ry="1"
          style={{ fill: colorDetails }}
        />
      </g>
    </svg>
  );
};

export default DocIcon;
