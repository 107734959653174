import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Input,
  useDisclosure,
} from '@nextui-org/react';
 import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// componenets 

// icons 
import { VerticalDotsIcon } from '../../assets/icons/VerticalDotsIcon';
import { ChevronDownIcon } from '../../assets/icons/ChevronDownIcon';
import { SearchIcon } from '../../assets/icons/SearchIcon';
import AiIcon from "../../assets/icons/Files Types/AiIcon";
import DocIcon from "../../assets/icons/Files Types/DocIcon";
import PngIcon from "../../assets/icons/Files Types/PngIcon";
import PdfIcon from "../../assets/icons/Files Types/PdfIcon";
import TxtIcon from "../../assets/icons/Files Types/TxtIcon";
import PsdIcon from "../../assets/icons/Files Types/PsdIcon";
import JpgIcon from "../../assets/icons/Files Types/JpgIcon";
import JpegIcon from "../../assets/icons/Files Types/JpegIcon";
import PsbIcon from "../../assets/icons/Files Types/PsbIcon";
import SvgIcon from "../../assets/icons/Files Types/SvgIcon";
import Mp4Icon from "../../assets/icons/Files Types/Mp4Icon";
import AviIcon from "../../assets/icons/Files Types/AviIcon";
import MovIcon from "../../assets/icons/Files Types/MovIcon";
import WebmIcon from "../../assets/icons/Files Types/WebmIcon";
import XlsxIcon from "../../assets/icons/Files Types/XlsxIcon";
import CsvIcon from "../../assets/icons/Files Types/CsvIcon";
import PptIcon from "../../assets/icons/Files Types/PptIcon";
import InddIcon from "../../assets/icons/Files Types/InddIcon";
import RarIcon from "../../assets/icons/Files Types/RarIcon";
import ArjIcon from "../../assets/icons/Files Types/ArjIcon";
import ZipIcon from "../../assets/icons/Files Types/ZipIcon";
import useFilesStore from 'src/store/layout/useFilesStore';
import { Pagination } from 'antd';
import PublicFileDetailsModal from '../modals/PublicFileDetailsModal';



const typesIconMap: Record<string, React.ReactNode> = {
  ai: <AiIcon height={40} width={50} />,
  docx: <DocIcon height={50} width={50} />,
  png: <PngIcon height={40} width={50} />,
  pdf: <PdfIcon height={7} width={50} />,
  txt: <TxtIcon height={40} width={30} />,
  psd: <PsdIcon height={50} width={55} />,
  jpg: <JpgIcon height={40} width={50} />,
  jpeg: <JpegIcon height={40} width={50} />,
  psb: <PsbIcon height={40} width={50} />,
  svg: <SvgIcon height={40} width={30} />,
  mp4: <Mp4Icon height={50} width={50} />,
  avi: <AviIcon height={40} width={50} />,
  mov: <MovIcon height={45} width={50} />,
  webm: <WebmIcon height={45} width={50} />,
  xlsx: <XlsxIcon height={55} width={60} />,
  csv: <CsvIcon height={45} width={50} />,
  pptx: <PptIcon height={55} width={55} />,
  indd: <InddIcon height={45} width={50} />,
  zip: <ZipIcon height={50} width={55} />,
  rar: <RarIcon height={45} width={55} />,
  arj: <ArjIcon height={45} width={55} />,
};
const PublicSharedGridList: React.FC = () => {
  const { t } = useTranslation();
  const {
    filterValue,
    selectedKeys,
    statusFilter,
    rowsPerPage,
    page,
    setFilterValue,
    setStatusFilter,
    setRowsPerPage,
    setPage,
    files,
    totalPages,
    fetchPublicSharedFiles,
  } = useFilesStore();
  console.log('PublicSharedGridList');


  const { isOpen: isFileDetailsOpen, onOpen: onOpenFileDetails, onClose: onCloseFileDetails } = useDisclosure();
  
  const [selectedFileType, setSelectedFileType] = useState<string | null>(null);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [selectedFileOwner, setSelectedFileOwner] = useState<string | null>(null);
  const [selectedFileSize, setSelectedFileSize] = useState<number | null>(null);
  const [selectedFileDescription, setselectedFileDescription] = useState<string | null>(null);


  useEffect(() => {
    fetchPublicSharedFiles();
  }, [fetchPublicSharedFiles, page, rowsPerPage]);

  const onRowsPerPageChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(Number(e.target.value));
  }, [setRowsPerPage]);

  const onSearchChange = useCallback((value?: string) => {
    setFilterValue(value || '');
  }, [setFilterValue]);

  const handleView = useCallback((fileType: string, fileName: string, fileOwner: string, fileSize: number, fileDescription: string ) => {
    setSelectedFileType(fileType);
    setSelectedFileName(fileName);
    setSelectedFileOwner(fileOwner);
    setSelectedFileSize(fileSize);
    setselectedFileDescription(fileDescription);
    onOpenFileDetails();
  }, [onOpenFileDetails]);

  const handleDownload = useCallback((s3RedirectUrl: string | undefined) => {
    if (s3RedirectUrl) {
      // Open the file in a new tab or trigger a download
      window.open(s3RedirectUrl, '_blank');
    } else {

    }
  }, []);


  const handleStatusChange = useCallback((selection: any) => {
    const newStatusSet = new Set<string>(selection.currentKey ? [selection.currentKey] : []);
    setStatusFilter(newStatusSet);
  }, [setStatusFilter]);


  const topContent = useMemo(() => (
    <div className="flex flex-col gap-4 w-[97%]">
      <div className="flex justify-between gap-3 items-end">
        <Input
          isClearable
          classNames={{
            base: 'w-full sm:max-w-[44%]',
            inputWrapper: 'border-1 bg-white',
          }}
          placeholder={t('SearchByFileName')}
          startContent={<SearchIcon className="text-default-300" />}
          value={filterValue}
          variant="bordered"
          onClear={() => setFilterValue('')}
          onValueChange={onSearchChange}
        />
        <div className="flex gap-3">
          {/* <Dropdown>
            <DropdownTrigger className="hidden sm:flex">
              <Button
                endContent={<ChevronDownIcon className="text-small" />}
                size="sm"
                variant="flat"
              >
                {t('Type')}
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              disallowEmptySelection
              aria-label="Table Columns"
              closeOnSelect={false}
              selectedKeys={statusFilter}
              selectionMode="multiple"
              onSelectionChange={handleStatusChange}
            >
              {fileTypeOptions.map((type) => (
                <DropdownItem key={type.uid} className="capitalize">
                  {Capitalize(type.name)}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown> */}
         </div>
      </div>
      <div className="flex justify-between items-center ">
        <span className="text-default-400 text-small">
          {t('TotalFiles')}: {files.length}
        </span>
        {/* <div className="flex items-center">
          <label className="mr-2 text-default-400 text-small">
            {t('RowsPerPage')}
          </label>
          <select
            className="border border-default-200 rounded px-2 py-1"
            value={rowsPerPage}
            onChange={onRowsPerPageChange}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div> */}
      </div>
    </div>
  ), [t, filterValue, onSearchChange, statusFilter, handleStatusChange, files.length, rowsPerPage, onRowsPerPageChange, setFilterValue]);

  const bottomContent = useMemo(() => (
    <div className="py-2 px-2 flex justify-between items-center mt-4">
      <Pagination
        current={page}
        total={totalPages * rowsPerPage} // Ant Design uses total items instead of total pages
        pageSize={rowsPerPage}
        onChange={(newPage) => setPage(newPage)}
        showSizeChanger={false}
      />
      {/* <span className="text-small text-default-400">
        {selectedKeys.has('all')
          ? `${t('AllItemsSelected')}`
          : `${selectedKeys.size} ${t('of')} ${files.length} ${t('selected')}`}
      </span> */}
    </div>
  ), [page, totalPages, rowsPerPage, selectedKeys, t, files.length, setPage]);

  return (
    <div className="w-full p-4">
      {topContent}
      <div className="flex flex-wrap gap-6 justify-start items-center mt-4">
        {files.map((file) => (
          <Card className="lg:w-[23%] w-[20rem] hover:scale-105" key={file.file_id}>
            <CardHeader className="justify-between">
              <Dropdown>
                <DropdownTrigger>
                  <Button isIconOnly radius="full" size="sm" variant="light">
                    <VerticalDotsIcon className="text-default-400" />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu>
                  <DropdownItem onClick={() =>handleView(file.file_type ?? '', file.original_filename ?? '', (file.full_name_en === '' ? file.full_name_ar : file.full_name_en ?? '') as string, file.file_size ?? 0, (file.description ?? '') as string)}>{t('ViewDetails')}</DropdownItem>
                  <DropdownItem onClick={() => handleDownload(file.s3_redirect_url || '')}>{t('View')}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              
            </CardHeader>
            <Link to={`/fileDetails/${file.file_id}`}>
              <CardBody className="px-3 py-0 text-small text-center text-default-400">
                <div className="flex justify-center items-center">
                  {typesIconMap[file.file_type?.toLocaleLowerCase() ?? '']}
                </div>
                <span className="pt-2 capitalize text-gray-900 text-lg font-bold">
                {file.original_filename && file.original_filename.length >= 60
                  ? `${file.original_filename.slice(0, 60)}.....`
                  : file.original_filename || ""}
                </span>
                <span className="pt-2 capitalize">Today . {file.file_size} MB</span>
              </CardBody>
              <CardFooter className="gap-3"></CardFooter>
            </Link>
          </Card>
        ))}
      </div>
      {bottomContent}

      
  {/* Modal to display file details */}
 <PublicFileDetailsModal
          isOpen={isFileDetailsOpen}
          onClose={onCloseFileDetails}
          fileType={selectedFileType || ''}
          fileName={selectedFileName || ''}
          fileOwner={selectedFileOwner ||''}
          fileSize={selectedFileSize || 0}
          fileDescription = {selectedFileDescription || ''}
        />

    </div>
  );
};

export default PublicSharedGridList;
