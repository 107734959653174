import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Chip,
  useDisclosure,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";

// Components
 import CustomAvatar from "../../utils/partials/CustomFileType";

// Icons
import { VerticalDotsIcon } from "../../assets/icons/VerticalDotsIcon";
import { ChevronDownIcon } from "../../assets/icons/ChevronDownIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";

// File Type Icons
import AiIcon from "../../assets/icons/Files Types/AiIcon";
import DocIcon from "../../assets/icons/Files Types/DocIcon";
import PngIcon from "../../assets/icons/Files Types/PngIcon";
import PdfIcon from "../../assets/icons/Files Types/PdfIcon";
import TxtIcon from "../../assets/icons/Files Types/TxtIcon";
import PsdIcon from "../../assets/icons/Files Types/PsdIcon";
import JpgIcon from "../../assets/icons/Files Types/JpgIcon";
import JpegIcon from "../../assets/icons/Files Types/JpegIcon";
import PsbIcon from "../../assets/icons/Files Types/PsbIcon";
import SvgIcon from "../../assets/icons/Files Types/SvgIcon";
import Mp4Icon from "../../assets/icons/Files Types/Mp4Icon";
import AviIcon from "../../assets/icons/Files Types/AviIcon";
import MovIcon from "../../assets/icons/Files Types/MovIcon";
import WebmIcon from "../../assets/icons/Files Types/WebmIcon";
import XlsxIcon from "../../assets/icons/Files Types/XlsxIcon";
import CsvIcon from "../../assets/icons/Files Types/CsvIcon";
import PptIcon from "../../assets/icons/Files Types/PptIcon";
import InddIcon from "../../assets/icons/Files Types/InddIcon";
import RarIcon from "../../assets/icons/Files Types/RarIcon";
import ArjIcon from "../../assets/icons/Files Types/ArjIcon";
import ZipIcon from "../../assets/icons/Files Types/ZipIcon";
import useFilesStore, { File } from "src/store/layout/useFilesStore";
import { Pagination } from "antd";
import PublicFileDetailsModal from "../modals/PublicFileDetailsModal";
      
const typesIconMap: Record<string, React.ReactNode> = {
  ai: <AiIcon height={20} width={30} />,
  docx: <DocIcon height={28} width={30} />,
  png: <PngIcon height={20} width={30} />,
  pdf: <PdfIcon height={25} width={30} />,
  txt: <TxtIcon height={20} width={30} />,
  psd: <PsdIcon height={20} width={30} />,
  jpg: <JpgIcon height={20} width={30} />,
  jpeg: <JpegIcon height={20} width={30} />,
  psb: <PsbIcon height={20} width={30} />,
  svg: <SvgIcon height={20} width={30} />,
  mp4: <Mp4Icon height={20} width={30} />,
  avi: <AviIcon height={20} width={30} />,
  mov: <MovIcon height={20} width={30} />,
  webm: <WebmIcon height={20} width={30} />,
  xlsx: <XlsxIcon height={20} width={30} />,
  csv: <CsvIcon height={20} width={30} />,
  pptx: <PptIcon height={20} width={30} />,
  indd: <InddIcon height={20} width={30} />,
  zip: <ZipIcon height={30} width={30} />,
  rar: <RarIcon height={20} width={30} />,
  arj: <ArjIcon height={20} width={30} />,
};

const INITIAL_VISIBLE_COLUMNS = ["file_name", "file_type", "actions"];

const PublicSharedTableList: React.FC = () => {
  const { t } = useTranslation();
  const {
    filterValue,
    selectedKeys,
    statusFilter,
    rowsPerPage,
    page,
    totalPages,
    setFilterValue,
    setStatusFilter,
    setRowsPerPage,
    setPage,
    files,
    fetchPublicSharedFiles,
  } = useFilesStore();

  console.log('PublicSharedGridList');

  const {
    isOpen: isFileDetailsOpen,
    onOpen: onOpenFileDetails,
    onClose: onCloseFileDetails,
  } = useDisclosure();


  const [selectedFileType, setSelectedFileType] = useState<string | null>(null);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [selectedFileOwner, setSelectedFileOwner] = useState<string | null>(null);
  const [selectedFileSize, setSelectedFileSize] = useState<number | null>(null);
  const [selectedFileDescription, setselectedFileDescription] = useState<string | null>(null);

 
  useEffect(() => {
    fetchPublicSharedFiles();
  }, [fetchPublicSharedFiles, page, rowsPerPage]);

  const handleRowsPerPageChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRowsPerPage(Number(e.target.value));
      setPage(1);
      fetchPublicSharedFiles();
    },
    [setRowsPerPage, setPage, fetchPublicSharedFiles]
  );

  const handleSearchChange = useCallback(
    (value?: string) => {
      setFilterValue(value || "");
      setPage(1);
      fetchPublicSharedFiles();
    },
    [setFilterValue, setPage, fetchPublicSharedFiles]
  );

  const handleView = useCallback((fileType: string, fileName: string, fileOwner: string, fileSize: number, fileDescription: string) => {
    setSelectedFileType(fileType);
    setSelectedFileName(fileName);
    setSelectedFileOwner(fileOwner);
    setSelectedFileSize(fileSize);
    setselectedFileDescription(fileDescription);
    onOpenFileDetails();
  }, [onOpenFileDetails]);


  const handleDownload = useCallback((s3RedirectUrl: string | undefined) => {
    if (s3RedirectUrl) {
      // Open the file in a new tab or trigger a download
      window.open(s3RedirectUrl, '_blank');
    } else {

    }
  }, []);
  

  const renderTopContent = useMemo(
    () => (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <Input
            isClearable
            classNames={{
              base: "w-full sm:max-w-[44%]",
              inputWrapper: "border-1",
            }}
            placeholder={t("SearchByFileName")}
            startContent={<SearchIcon className="text-default-300" />}
            value={filterValue}
            variant="bordered"
            onClear={() => setFilterValue("")}
            onValueChange={handleSearchChange}
          />
          <div className="flex gap-3">
            {/* <Dropdown>
              <DropdownTrigger className="hidden sm:flex">
                <Button
                  endContent={<ChevronDownIcon className="text-small" />}
                  size="sm"
                  variant="flat"
                >
                  {t("Type")}
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Table Columns"
                closeOnSelect={false}
                selectedKeys={statusFilter}
                selectionMode="multiple"
                onSelectionChange={(keys) =>
                  setStatusFilter(new Set(Array.from(keys, String)))
                }
              >
                {fileTypeOptions.map((status) => (
                  <DropdownItem key={status.uid} className="capitalize">
                    {Capitalize(status.name)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown> */}
           </div>
        </div>
        <div className="flex justify-between items-center ">
          <span className="text-default-400 text-small">
            {t("TotalFiles")}: {files.length}
          </span>
          {/* <div className="flex items-center">
            <label className="mr-2 text-default-400 text-small">
              {t("RowsPerPage")}
            </label>
            <select
              className="border border-default-200 rounded px-2 py-1"
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div> */}
        </div>
      </div>
    ),
    [t, filterValue, handleSearchChange, statusFilter, files.length, rowsPerPage, handleRowsPerPageChange, setFilterValue, setStatusFilter]
  );

  const renderBottomContent = useMemo(
    () => (
      <div className="py-2 px-2 flex justify-between items-center mt-4">
        <Pagination
        current={page}
        total={totalPages * rowsPerPage} // Ant Design uses total items instead of total pages
        pageSize={rowsPerPage}
        onChange={(newPage) => setPage(newPage)}
        showSizeChanger={false}
      />
        {/* <span className="text-small text-default-400">
          {selectedKeys.has("all")
            ? `${t("AllItemsSelected")}`
            : `${selectedKeys.size} ${t("of")} ${files.length} ${t("selected")}`}
        </span> */}
      </div>
    ),
    [page, totalPages, rowsPerPage, selectedKeys, t, files.length, setPage]
  );

  const renderCell = useCallback(
    (file: File, columnKey: React.Key): React.ReactNode => {
      const cellValue = file[columnKey as keyof File] || "N/A";
  
      switch (columnKey) {
        case "file_name":
          const fileIcon = typesIconMap[file.file_type?.toLowerCase() || ""];
          return (
            <div className="flex items-start gap-2">
              {fileIcon && <CustomAvatar icon={fileIcon} size="24px" />}
              <span className="text-lg">
              {file.original_filename && file.original_filename.length >= 60
                  ? `${file.original_filename.slice(0, 60)}.....`
                  : file.original_filename || ""}
              </span>
            </div>
          );
  
        case "file_type":
          return (
            <Chip
              className="capitalize border-none gap-1 text-default-600"
              size="sm"
              variant="flat"
            >
              {file.file_type}
            </Chip>
          );
  
        case "actions":
          return (
            <div className="relative flex justify-end items-center gap-2">
              <Dropdown className="bg-background border-1 border-default-200">
                <DropdownTrigger>
                  <Button isIconOnly radius="full" size="sm" variant="light">
                    <VerticalDotsIcon className="text-default-400" />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu>
                   <DropdownItem onClick={() =>handleView(file.file_type ?? '', file.original_filename ?? '', (file.full_name_en === '' ? file.full_name_ar : file.full_name_en ?? '' ) as string, file.file_size ?? 0, (file.description ?? '') as string)}>{t('ViewDetails')}</DropdownItem>
                   <DropdownItem onClick={() => handleDownload(file.s3_redirect_url || '')}>{t('View')}</DropdownItem>
                 </DropdownMenu>
              </Dropdown>
            </div>
          );
        default:
          return String(cellValue);
      }
    },
    [handleView, t, handleDownload]
  );
  

  return (
    <div className="w-full bg-white p-4 rounded-lg shadow overflow-x-auto">
      <Table
        isCompact
        className="min-w-full"
        removeWrapper
        aria-label="Example table with custom cells, pagination and sorting"
        bottomContent={renderBottomContent}
        bottomContentPlacement="outside"
        checkboxesProps={{
          classNames: {
            wrapper: "after:bg-secondary after:text-background text-background",
          },
        }}
        selectedKeys={Array.from(
          selectedKeys
        ) as Iterable<import("@react-types/shared").Key>}
        selectionMode="none"
        topContent={renderTopContent}
        topContentPlacement="outside"
      >
        <TableHeader className="bg-white" columns={INITIAL_VISIBLE_COLUMNS}>
          {INITIAL_VISIBLE_COLUMNS.map((column) => (
            <TableColumn
              key={column}
              align={column === "actions" ? "end" : "start"}
            >
              {t(column)}
            </TableColumn>
          ))}
        </TableHeader>

        <TableBody emptyContent={t("NoFilesFound")} items={files}>
          {files.map((item) => (
            <TableRow key={item.file_id}>
              {INITIAL_VISIBLE_COLUMNS.map((columnKey) => (
                <TableCell key={columnKey}>
                  {renderCell(item, columnKey)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Modal to display file details */}
         <PublicFileDetailsModal
          isOpen={isFileDetailsOpen}
          onClose={onCloseFileDetails}
          fileType={selectedFileType || ''}
          fileName={selectedFileName || ''}
          fileOwner={selectedFileOwner ||''}
          fileSize={selectedFileSize || 0}
          fileDescription = {selectedFileDescription || ''}
        />
  
    </div>
  );
};

export default PublicSharedTableList;
