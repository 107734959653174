
import { create } from 'zustand';
import { useAuthStore } from './useAuthStore';
import { fetchFavoritesWithoutDirectory, toggleFileFavorite, toggleFolderFavorite } from 'src/services/api/API';
import { Favorite, ListFavorite } from 'src/types/api/ListFavorite';

interface FavoriteStoreState {
  filterValue: string;
  selectedKeys: Set<React.Key>;
  rowsPerPage: number;
  page: number;
  totalPages: number;
  totalFavorites: number;
  favorites: ListFavorite[];
  favorite: Favorite[];
  isLoading: boolean;
  lastFetchedPage: number | null;
  setFilterValue: (value: string) => void;
  setSelectedKeys: (keys: Set<React.Key>) => void;
  setRowsPerPage: (value: number) => void;
  setPage: (value: number) => void;
  fetchFavoritesWithoutDirectory: (page: number, perPage: number) => Promise<void>;
  toggleFileFavorite: (fileId: string) => Promise<void>;
  toggleFolderFavorite: (folderId: string) => Promise<void>;
  reftreshFetchFavoritesWithoutDirectory: (page: number, perPage: number) => Promise<void>;
}

// Step 1: Create Zustand store with initial state and actions
const useFavoriteStore = create<FavoriteStoreState>((set, get) => ({
  // Step 2: Initialize state variables
  filterValue: '',
  selectedKeys: new Set<React.Key>(),
  rowsPerPage: 10,
  page: 1,
  totalPages: 0,
  totalFavorites: 0,
  favorites: [],
  favorite: [],
  isLoading: false,
  lastFetchedPage: null,

  // Step 3: Define state update actions
  setFilterValue: (value: string) => set({ filterValue: value }),
  setSelectedKeys: (keys: Set<React.Key>) => set({ selectedKeys: keys }),
  setRowsPerPage: (value: number) => set({ rowsPerPage: value }),
  setPage: (value: number) => set({ page: value }),

  // Step 4: Define API action to fetch favorites without directory filtering
  fetchFavoritesWithoutDirectory: async (page, perPage) => {
    const { lastFetchedPage } = get();
    const token = useAuthStore.getState().token;
    if (!token || lastFetchedPage === page) return;

    set({ isLoading: true });

    try {
      const data = await fetchFavoritesWithoutDirectory(token, page, perPage);
      console.log("data");
      console.log(data);
      console.log("data");
      set({
        favorite: data.favorites || [],
        totalPages: data.totalPages || 0,
        totalFavorites: data.totalFavorites || 0,
        lastFetchedPage: page,
      });
    } catch (error) {
      console.error("Failed to fetch favorites:", error);
    } finally {
      set({ isLoading: false });
    }
  },

  // Step 5: Define action to toggle a file as a favorite
  toggleFileFavorite: async (fileId) => {
    const token = useAuthStore.getState().token;
    if (!token) return;
    await toggleFileFavorite(fileId, token);
   
    set((state) => {
      const isFavorite = state.favorite.some(fav => fav.file_id === fileId);
      const favorite = isFavorite
        ? state.favorite.filter(fav => fav.file_id !== fileId)
        : [...state.favorite, { file_id: fileId }];
      return { favorite };
    });
    await get().reftreshFetchFavoritesWithoutDirectory(get().page, get().rowsPerPage); // Refresh list

  },

  // Step 6: Define action to toggle a folder as a favorite
  toggleFolderFavorite: async (folderId) => {
    const token = useAuthStore.getState().token;
    if (!token) return;

    await toggleFolderFavorite(folderId, token);

    set((state) => {
      const isFavorite = state.favorite.some(fav => fav.folder_id === folderId);
      const favorite = isFavorite
        ? state.favorite.filter(fav => fav.folder_id !== folderId)
        : [...state.favorite, { folder_id: folderId }];
      return { favorite };
    });

    await get().reftreshFetchFavoritesWithoutDirectory(get().page, get().rowsPerPage); // Refresh list

  },

  reftreshFetchFavoritesWithoutDirectory: async (page, perPage) => {
    const token = useAuthStore.getState().token;
    if (!token) return;
     set({ isLoading: true });
    try {
      const data = await fetchFavoritesWithoutDirectory(token , page, perPage);
      set({
        favorite: data.favorites || [],
        totalPages: data.totalPages || 0,
        totalFavorites: data.totalFavorites || 0,
        lastFetchedPage: page,
      });
    } catch (error) {
      console.error("Failed to fetch favorites:", error);
    } finally {
      set({ isLoading: false });
    }
  },

}));

// Step 7: Export the store for use in other components and hooks
export default useFavoriteStore;