import React from "react";

interface TxtIconProps extends React.SVGProps<SVGSVGElement> {
  width?: string | number;  // Allow dynamic width
  height?: string | number; // Allow dynamic height
}

export const TxtIcon: React.FC<TxtIconProps> = ({ width = "40px", height = "50px", ...props }) => (
  <svg
    viewBox="0 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}  // Apply dynamic width
    height={height} // Apply dynamic height
    {...props}
  >
    <path d="M34 50H6C4.4087 50 2.88258 49.3679 1.75736 48.2426C0.63214 47.1174 0 45.5913 0 44V11L9 0H34C35.5913 0 37.1174 0.632141 38.2426 1.75736C39.3679 2.88258 40 4.4087 40 6V44C40 45.5913 39.3679 47.1174 38.2426 48.2426C37.1174 49.3679 35.5913 50 34 50Z" fill="#7E95C4"/>
    <path d="M9 9.556C9 9.93897 8.84787 10.3063 8.57706 10.5771C8.30626 10.8479 7.93897 11 7.556 11H0L9 0V9.556Z" fill="#B7CCEA"/>
    <path d="M28 20H12C11.4477 20 11 20.4477 11 21C11 21.5523 11.4477 22 12 22H28C28.5523 22 29 21.5523 29 21C29 20.4477 28.5523 20 28 20Z" fill="white"/>
    <path d="M28 24H12C11.4477 24 11 24.4477 11 25C11 25.5523 11.4477 26 12 26H28C28.5523 26 29 25.5523 29 25C29 24.4477 28.5523 24 28 24Z" fill="white"/>
    <path d="M28 28H12C11.4477 28 11 28.4477 11 29C11 29.5523 11.4477 30 12 30H28C28.5523 30 29 29.5523 29 29C29 28.4477 28.5523 28 28 28Z" fill="white"/>
    <path d="M24 32H12C11.4477 32 11 32.4477 11 33C11 33.5523 11.4477 34 12 34H24C24.5523 34 25 33.5523 25 33C25 32.4477 24.5523 32 24 32Z" fill="white"/>
    <path d="M18 36H12C11.4477 36 11 36.4477 11 37C11 37.5523 11.4477 38 12 38H18C18.5523 38 19 37.5523 19 37C19 36.4477 18.5523 36 18 36Z" fill="white"/>
  </svg>
);

export default TxtIcon;
