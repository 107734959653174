// src/pages/Files.js
import React from "react";
import MainSidebar from "../components/sidebar/MainSidebar";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
 import LayoutSwitcher from "../components/switchers/LayoutSwitcher";
 
import NavBar from "src/components/header/NarBar";
import { useSidebarStore } from "src/store/layout/useSidebarStore";
 import GridList from "src/components/shared_files_layout/SharedByGridList";
import TableList from "src/components/shared_files_layout/SharedByTableList";
import DownloadList from "src/components/common/DownloadList";
 
const Files = () => {
  const { t } = useTranslation();
  const { layout } = useSidebarStore();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
   return (
    <div className="bg-main min-h-screen overflow-x-hidden">
      <NavBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex">
        <MainSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="flex-1 p-4 overflow-x-hidden">
          <Breadcrumbs isDisabled>
            <BreadcrumbItem>{t("SAFFCLOUD")}</BreadcrumbItem>
            <BreadcrumbItem>{t("Files")}</BreadcrumbItem>
          </Breadcrumbs>

          <div className="text-[33px] capitalize text-navy-700 mt-4 mb-5 flex justify-between">
            <div className="font-bold capitalize hover:text-navy-700">
              {t("Files")}
            </div>
            <LayoutSwitcher />
          </div>

          <div className="max-sm:hidden">
            <DownloadList />
          </div>

          {layout === "grid" ? <GridList /> : <div className="">
          <TableList />
          </div> }
        </div>
      </div>
    </div>
  );
};

export default Files;
