import React, { useState, useEffect } from "react";

const generateCaptcha = () => {
  const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let captcha = "";
  for (let i = 0; i < 6; i++) {
    captcha += chars[Math.floor(Math.random() * chars.length)];
  }
  return captcha;
};

const Captcha = ({ onChange }: { onChange: (value: string) => void }) => {
  const [captcha, setCaptcha] = useState<string>(generateCaptcha());

  useEffect(() => {
    onChange(captcha);
  }, [captcha, onChange]);

  const refreshCaptcha = () => {
    setCaptcha(generateCaptcha());
  };

  return (
    <div className="flex items-center space-x-4">
      <div className="bg-gray-200 text-gray-800 font-bold p-2 rounded">
        {captcha}
      </div>
      <button
        type="button"
        onClick={refreshCaptcha}
        className="bg-blue-500 text-white p-2 rounded"
      >
        Refresh
      </button>
    </div>
  );
};

export default Captcha;