import React from 'react';

interface Mp4IconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly as colors are hardcoded in SVG
}

const Mp4Icon: React.FC<Mp4IconProps> = ({ width = 72, height = 72 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 72 72"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z"
      fill="#f74141"
    />
    <path
      d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z"
      fill="#ffa9a9"
    />
    <path
      d="M46,46.5v-13A3.5042,3.5042,0,0,0,42.5,30h-13A3.5042,3.5042,0,0,0,26,33.5v13A3.5042,3.5042,0,0,0,29.5,50h13A3.5042,3.5042,0,0,0,46,46.5ZM40,45v3H37V45Zm-3-2V37h7v6Zm0-8V32h3v3Zm-2-3v3H32V32Zm0,5v6H28V37Zm0,8v3H32V45Zm7.5,3H42V45h2v1.5A1.5016,1.5016,0,0,1,42.5,48ZM44,33.5V35H42V32h.5A1.5016,1.5016,0,0,1,44,33.5ZM29.5,32H30v3H28V33.5A1.5016,1.5016,0,0,1,29.5,32ZM28,46.5V45h2v3h-.5A1.5016,1.5016,0,0,1,28,46.5Z"
      fill="#fff"
    />
  </svg>
);

export default Mp4Icon;
