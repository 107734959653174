import React from 'react';

interface CsvIconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly as colors are hardcoded in SVG
}

const CsvIcon: React.FC<CsvIconProps> = ({ width = 40, height = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34 50H6C4.4087 50 2.88258 49.3679 1.75736 48.2426C0.63214 47.1174 0 45.5913 0 44V11L9 0H34C35.5913 0 37.1174 0.632141 38.2426 1.75736C39.3679 2.88258 40 4.4087 40 6V44C40 45.5913 39.3679 47.1174 38.2426 48.2426C37.1174 49.3679 35.5913 50 34 50Z"
      fill="#22714D"
    />
    <path
      d="M9 9.556C9 9.93897 8.84787 10.3063 8.57706 10.5771C8.30626 10.8479 7.93897 11 7.556 11H0L9 0V9.556Z"
      fill="#4FA980"
    />
    <path
      d="M26 20H14C13.2046 20.0009 12.4421 20.3172 11.8796 20.8796C11.3172 21.4421 11.0009 22.2046 11 23V34C11.0009 34.7954 11.3172 35.5579 11.8796 36.1204C12.4421 36.6828 13.2046 36.9991 14 37H26C26.7954 36.9991 27.5579 36.6828 28.1204 36.1204C28.6828 35.5579 28.9991 34.7954 29 34V23C28.9991 22.2046 28.6828 21.4421 28.1204 20.8796C27.5579 20.3172 26.7954 20.0009 26 20ZM13 27H19V30H13V27ZM21 27H27V30H21V27ZM27 23V25H21V22H26C26.2651 22.0003 26.5193 22.1057 26.7068 22.2932C26.8943 22.4807 26.9997 22.7349 27 23ZM14 22H19V25H13V23C13.0003 22.7349 13.1057 22.4807 13.2932 22.2932C13.4807 22.1057 13.7349 22.0003 14 22ZM13 34V32H19V35H14C13.7349 34.9997 13.4807 34.8943 13.2932 34.7068C13.1057 34.5193 13.0003 34.2651 13 34ZM26 35H21V32H27V34C26.9997 34.2651 26.8943 34.5193 26.7068 34.7068C26.5193 34.8943 26.2651 34.9997 26 35Z"
      fill="white"
    />
  </svg>
);

export default CsvIcon;
