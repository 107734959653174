// src/pages/LoginForm.tsx
import React, { useState } from 'react';
import useLoginUser from '../hooks/useLoginUser';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('j@example.com'); // Default email
  const [password, setPassword] = useState('password123'); // Default password
  const { mutate: loginUser, error } = useLoginUser();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    loginUser({ email, password });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        placeholder="Enter your email"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        placeholder="Enter your password"
      />
      <button type="submit">Login</button>
      {error && <div>{error.message}</div>}
    </form>
  );
};

export default LoginForm;
