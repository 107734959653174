import React, { useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import MainFileIcon from "../../assets/icons/MainFileIcon";
import useFileDetails from "src/hooks/useFileDetails";

interface FileDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileId: string;

}

const FileDetailsModal: React.FC<FileDetailsModalProps> = ({ isOpen, onClose,fileId }) => {
  const { t } = useTranslation();

  const {fileDetails, isProcessing, error, getFileDetails, setFileDetails } = useFileDetails();

   // Fetch folder details when the modal opens, and only if not already fetched
  //  useEffect(() => {
  //   if (isOpen && fileId && !hasFetched) {
  //     getFileDetails(fileId).then(() => setHasFetched(true));
  //   }
  //   // Reset fetch flag when modal closes
  //   if (!isOpen) {
  //     setHasFetched(false);
  //   }
  // }, [isOpen, getFileDetails, hasFetched, fileId]);
  useEffect(() => {
    if (isOpen && fileId && !fileDetails && !isProcessing) {
      getFileDetails(fileId);
    }
  }, [isOpen, fileId, fileDetails, isProcessing, getFileDetails]);

  useEffect(() => {
    if (!isOpen) {
      setFileDetails(null);
    }
  }, [isOpen, setFileDetails]);

   return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          <div className="flex items-end justify-start">
            <MainFileIcon height={30} width={30} />
            {t('FileDetails')}
          </div>
        </ModalHeader>

        <ModalBody>
        {isProcessing && <p>{t('Loading')}</p>}
          {error && <p>{t('Error: ')}{error}</p>}
          {fileDetails && (
          <Table isStriped aria-label="Example static collection table">
            <TableHeader>
              <TableColumn>{t('Type')}</TableColumn>
              <TableColumn>{fileDetails.file_type}</TableColumn>
            </TableHeader>

            <TableBody>
            <TableRow key="1">
                  <TableCell>{t('Name')}</TableCell>
                  <TableCell>{fileDetails.original_filename}</TableCell>
                </TableRow>

              <TableRow key="2">
                <TableCell>{t('Size')}</TableCell>
                <TableCell>{fileDetails.file_size}</TableCell>
              </TableRow>
              <TableRow key="3">
              <TableCell>{t('Owner')}</TableCell>
              <TableCell>{fileDetails.owner}</TableCell>
              </TableRow>

              <TableRow key="4">
              <TableCell>{t('Description')}</TableCell>
              <TableCell>{fileDetails.description}</TableCell>
              </TableRow>

              <TableRow key="5">
              <TableCell>{t('SharedWith')}</TableCell>
              <TableCell>{fileDetails.share_with_usernames }</TableCell>
              </TableRow>

              <TableRow key="6">
                <TableCell>{t('Modified')}</TableCell>
                <TableCell>{fileDetails.updated_at}</TableCell>
              </TableRow>

              <TableRow key="7">
                <TableCell>{t('Created')}</TableCell>
                <TableCell>{fileDetails.created_at}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
)}
{!fileDetails && !isProcessing && !error && <p>{t('NoFileDetailsAvailable')}</p>}
</ModalBody>

        <ModalFooter>
          <Button color="danger" variant="light" onPress={onClose}>
            {t("Close")}
          </Button>
          {/* <Button color="primary" onPress={onClose}>
            {t("Save")}
          </Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FileDetailsModal;