// src/pages/Files.js
import { useEffect, useState } from "react";
import PublicSidebar from "../components/public_sidebar/PublicSidebar";
 import { useTranslation } from "react-i18next";
import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
 import LayoutSwitcher from "../components/switchers/LayoutSwitcher";
 import NavBar from "../components/public_header/NarBar";
 import { useSidebarStore } from "src/store/layout/useSidebarStore";
import PublicSharedGridList from "src/components/public_shared_files_layout/PublicSharedGridList";
import PublicSharedTableList from "src/components/public_shared_files_layout/PublicSharedTableList";
import { usePublicFolderStore } from "src/components/public_access_page/cards/PublicFolderCard";
import { useNavigate } from 'react-router-dom';

const PublicSharedFiles = () => {
  const { t } = useTranslation();
  const { layout } = useSidebarStore();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate(); // Hook for navigation


  useEffect(() => {
    const folderId = usePublicFolderStore.getState().folderId;
    if (folderId === null) {
      navigate('/home'); // Redirect to 404 page
    }

  }, [ navigate]);
  
   return (
    <div className="bg-main min-h-screen overflow-x-hidden">
      <NavBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex">
        <PublicSidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />

            <div className="flex-1 p-4 overflow-x-hidden">
          <Breadcrumbs isDisabled>
            <BreadcrumbItem>{t("SAFFCLOUD")}</BreadcrumbItem>
            <BreadcrumbItem>{t("Files")}</BreadcrumbItem>
          </Breadcrumbs>

          <div className="text-[33px] capitalize text-navy-700 mt-4 mb-5 flex justify-between">
            <div className="font-bold capitalize hover:text-navy-700">
              {t("Files")}
            </div>
            <LayoutSwitcher />
          </div>

          {layout === "grid" ? <PublicSharedGridList /> : <div className="">
          <PublicSharedTableList />
          </div> }
        </div>
      </div>
    </div>
  );
};

export default PublicSharedFiles;
