import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useAuthStore } from './useAuthStore';
import { createRole, deleteRole, fetchRoles, updateRole } from 'src/services/api/API';

interface RoleState {
  search: string;
  isModalOpen: boolean;
  setSearch: (search: string) => void;
  setModalOpen: (isOpen: boolean) => void;
  roles: Role[];
  isLoadingFetch: boolean;
  isLoadingCreate: boolean;
  isLoadingUpdate: boolean;
  isLoadingDelete: boolean;
  
  errorFetch: string | null;
  errorCreate: string | null;
  errorUpdate: string | null;
  errorDelete: string | null;
  
  responseMessage: string;
  responseStatus: 'success' | 'error' | null;
  
  fetchRoles: () => Promise<void>;
  createRole: (roleData: Partial<Role>) => Promise<void>;
  updateRole: (roleId: string, updatedData: Partial<Role>) => Promise<void>;
  deleteRole: (roleId: string) => Promise<void>;
  
  resetResponseStatus: () => void;
}

export const useRoleStore = create<RoleState>()(
  devtools((set) => ({
    search: '',
    isModalOpen: false,
    setSearch: (search) => set({ search }),
    setModalOpen: (isOpen) => set({ isModalOpen: isOpen }),
    roles: [],
    isLoadingFetch: false,
    isLoadingCreate: false,
    isLoadingUpdate: false,
    isLoadingDelete: false,
    
    errorFetch: null,
    errorCreate: null,
    errorUpdate: null,
    errorDelete: null,
    
    responseMessage: '',
    responseStatus: null,

    fetchRoles: async () => {
      set({ isLoadingFetch: true, errorFetch: null });

      const token = useAuthStore.getState().token;
      if (!token) {
        set({ errorFetch: 'Token is null. Unable to fetch roles.', isLoadingFetch: false });
        return;
      }

      try {
        const data: RoleList = await fetchRoles(token);
        set({ roles: data.roles || [], isLoadingFetch: false });
      } catch (error) {
        set({ errorFetch: 'Failed to fetch roles', isLoadingFetch: false });
        console.error('Fetch roles error:', error);
      }
    },

    createRole: async (roleData: Partial<Role>) => {
      set({ isLoadingCreate: true, errorCreate: null });

      const token = useAuthStore.getState().token;
      if (!token) {
        set({ errorCreate: 'Token is null. Unable to create role.', isLoadingCreate: false });
        return;
      }

      try {
        const response = await createRole(token, roleData);
        if (response.success) {
          set((state) => ({
            roles: [...state.roles, roleData as Role],
            isLoadingCreate: false,
            responseMessage: response.message || 'Role created successfully!',
            responseStatus: 'success',
          }));
        } else {
          set({
            errorCreate: response.message || 'Failed to create role',
            isLoadingCreate: false,
            responseStatus: 'error',
          });
        }
      } catch (errorCreate) {
        console.error('Error creating role:', errorCreate);
        set({
          errorCreate: 'Failed to create role',
          isLoadingCreate: false,
          responseStatus: 'error',
        });
      }
    },

    updateRole: async (roleId: string, updatedData: Partial<Role>) => {
      set({ isLoadingUpdate: true, errorUpdate: null });

      const token = useAuthStore.getState().token;
      if (!token) {
        set({ errorUpdate: 'Token is null. Unable to update role.', isLoadingUpdate: false });
        return;
      }

      try {
        const response = await updateRole(token, roleId, updatedData);
        console.log("      if (response.success) {",response)
        if (response.success) {
          set((state) => ({
            roles: state.roles.map((role) =>
              role.role_id === roleId ? { ...role, ...updatedData } : role
            ),
            isLoadingUpdate: false,
            responseMessage: response.message || 'Role updated successfully!',
            responseStatus: 'success',
          }));
        } else {
          set({
            responseMessage: response.message || 'Failed to update role',
            isLoadingUpdate: false,
            responseStatus: 'error',
          });
        }
      } catch (errorUpdate) {
        console.error('Error updating role:', errorUpdate);
        set({
          responseMessage: 'Failed to update role',
          isLoadingUpdate: false,
          responseStatus: 'error',
        });
      }
    },

    deleteRole: async (roleId: string) => {
      set({ isLoadingDelete: true, errorDelete: null });

      const token = useAuthStore.getState().token;
      if (!token) {
        set({ errorDelete: 'Token is null. Unable to delete role.', isLoadingDelete: false });
        return;
      }

      try {
        await deleteRole(token, roleId);
        set((state) => ({
          roles: state.roles.filter((role) => role.role_id !== roleId),
          isLoadingDelete: false,
        }));
      } catch (error) {
        console.error('Error deleting role:', error);
        set({
          errorDelete: 'Failed to delete role',
          isLoadingDelete: false,
        });
      }
    },

    resetResponseStatus: () => set({ responseMessage: '', responseStatus: null }),
  }))
);
// To parse this data:
//
//   import { Convert, RoleList } from "./file";
//
//   const roleList = Convert.toRoleList(json);

export interface RoleList {
  roles?:       Role[];
  totalPages?:  number;
  totalRoles?:  number;
  currentPage?: number;
}

export interface Role {
  role_id?:                            string;
  role_name_en?:                       string;
  role_description_en?:                string;
  role_name_ar?:                       string;
  role_description_ar?:                string;
  saff_employee_permission?:           number;
  add_permission?:                     number;
  delete_permission?:                  number;
  edit_permission?:                    number;
  upload_permission?:                  number;
  download_permission?:                number;
  create_role?:                        number;
  delete_role?:                        number;
  edit_role?:                          number;
  read_role?:                          number;
  users_saff_read?:                    number;
  users_saff_edit_role?:               number;
  create_users_outside_saff?:          number;
  read_users_outside_saff?:            number;
  edit_users_outside_saff?:            number;
  delete_users_outside_saff?:          number;
  share_delete_my_users_with_another?: number;
  share_read_my_users_with_another?:   number;
  share_edit_my_users_with_another?:   number;
  share_create_my_users_with_another?: number;
  created_at?:                         string;
  updated_at?:                         string;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toRoleList(json: string): RoleList {
      return JSON.parse(json);
  }

  public static roleListToJson(value: RoleList): string {
      return JSON.stringify(value);
  }
}
export interface ResponsCreateRole {
  message?: string;
  success?: boolean;}
  export const useAddNewRoleModalStore = create<AddNewRoleModalState>((set) => ({
    isOpen: false,
    nameEn: '',
    descriptionEn: '',
    nameAr: '',
    descriptionAr: '',
    setIsOpen: (isOpen) => set({ isOpen }),
    setNameEn: (nameEn) => set({ nameEn }),
    setDescriptionEn: (descriptionEn) => set({ descriptionEn }),
    setNameAr: (nameAr) => set({ nameAr }),
    setDescriptionAr: (descriptionAr) => set({ descriptionAr }),
    reset: () => set({
      nameEn: '',
      descriptionEn: '',
      nameAr: '',
      descriptionAr: '',
    }),
  }));
  interface AddNewRoleModalState {
    isOpen: boolean;
    nameEn: string;
    descriptionEn: string;
    nameAr: string;
    descriptionAr: string;
    setIsOpen: (isOpen: boolean) => void;
    setNameEn: (nameEn: string) => void;
    setDescriptionEn: (descriptionEn: string) => void;
    setNameAr: (nameAr: string) => void;
    setDescriptionAr: (descriptionAr: string) => void;
    reset: () => void;
  }
  

