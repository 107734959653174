import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { useRoleStore } from "src/store/api/useRoleStore";

interface DeleteRoleModalProps {
  isOpen: boolean;
  onClose: () => void;
  roleId: string | null;
}

const DeleteRoleModal: React.FC<DeleteRoleModalProps> = ({ isOpen, onClose, roleId }) => {
  const { t } = useTranslation();
  const { deleteRole } = useRoleStore();

  const handleDelete = async () => {
    if (roleId) {
      try {
        await deleteRole(roleId); // Ensure that the deleteRole function is called with the correct roleId
        onClose();
      } catch (error) {
        console.error("Failed to delete role:", error);
        // Optionally, show an error message to the user
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          {t("DeleteRole")}
        </ModalHeader>
        <ModalBody>
          <h2>{t('AreYouSureToDelete')}</h2>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" variant="light" onPress={onClose}>
            {t("No")}
          </Button>
          <Button color="danger" onPress={handleDelete}>
            {t("Yes")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteRoleModal;
