import useFolderStore from 'src/store/api/useFolderStore';

export const useFolderHooks = () => {
  const {
    folderName,
    setFolderName,
    createNewFolder,
    updateFolder,
    deleteFolder,
    listAllFolders,
    listAllFoldersFiles,
    copyFolder,
    // restoreFolder,
    getFolderDetails,
    uploadFolder,
    downloadFolder,
    emptyTrashFolders,
    isCreating,
    isUpdating,
    isDeleting,
    isFetching,
    isCopying,
    isRestoring,
    isUploading,
    isDownloading,
    isEmptyingTrash,
    responseMessage,
    responseStatus,
    resetState
  } = useFolderStore();

  const handleCreateFolder = async () => {
    await createNewFolder();
  };

  return {
    folderName,
    setFolderName,
    handleCreateFolder,
    handleUpdateFolder: updateFolder,
    handleDeleteFolder: deleteFolder,
    handleFetchFolders: listAllFolders,
    handleFetchFolderDetails: getFolderDetails,
    // handleRestoreFolder: restoreFolder,
    handleEmptyTrashFolders: emptyTrashFolders,
    handleUploadFolder: uploadFolder,
    handleDownloadFolder: downloadFolder,
    handleListAllFoldersFiles: listAllFoldersFiles,
    handleCopyFolder: copyFolder,
    isCreating,
    isUpdating,
    isDeleting,
    isFetching,
    isCopying,
    isRestoring,
    isUploading,
    isDownloading,
    isEmptyingTrash,
    responseMessage,
    responseStatus,
    resetState,
  };
};
