// src/store/api/useDirectoryStore.ts
import { create } from 'zustand';
import { useAuthStore } from './useAuthStore';
import { DirectoryState } from 'src/types/api/Directory'; // Adjust the import path if needed
import {
  createDirectory,
  getDirectoryDetails,
  listAllDirectories,
  listTrashDirectories,
  updateDirectory,
  deleteDirectory, 
  trashDirectory,
  restoreDirectory,
  emptyTrashDirectory, 
  setTrashSoftAllDirectories,
  deleteAllExpiredDirectories,
} from 'src/services/api/API';

const useDirectoryStore = create<DirectoryState>((set) => ({
  isLoading: false,
  error: null,
  successMessage: null,
  directories: null,
  trashDirectories: null,
  directoryDetails: null,

  listAllDirectories: async () => {
    set({ isLoading: true, error: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await listAllDirectories(token);
      set({ directories: result.directories, isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  listTrashDirectories: async () => {
    set({ isLoading: true, error: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await listTrashDirectories(token);
      set({ trashDirectories: result.directories, isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  getDirectoryDetails: async (directoryId: string) => {
    set({ isLoading: true, error: null, directoryDetails: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await getDirectoryDetails(directoryId, token);
      set({ directoryDetails: result.directory, isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },
 
  createDirectory: async (year: string) => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await createDirectory(year, token);
      set({ successMessage: result.message, isLoading: false });
      return { message: result.message }; // Ensure this is returned
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
      throw error; // Propagate the error
    }
  },
  updateDirectory: async (directoryId: string, updatedData: any) => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await updateDirectory(directoryId, updatedData, token);
      set({ successMessage: result.message || 'Directory updated successfully', isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  deleteDirectory: async (directoryId: string): Promise<{ message: string }> => { 
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await deleteDirectory(directoryId, token);
      set({ successMessage: result.message, isLoading: false });
      return { message: result.message }; // Return the success message
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
      throw error; // Ensure to propagate the error
    }
  },

  trashDirectory: async (directoryId: string): Promise<{ message: string }> => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await trashDirectory(directoryId, token);
      set({ successMessage: result.message, isLoading: false });
      return { message: result.message }; // Return the success message
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
      throw error; // Ensure to propagate the error
    }
  },

  restoreDirectory: async (directoryId: string): Promise<{ message: string }> =>{
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await restoreDirectory(directoryId, token);
      set({ successMessage: result.message, isLoading: false });
      return { message: result.message }; // Return the success message
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
      throw error; // Ensure to propagate the error
    }
  },

  emptyTrashDirectory: async (): Promise<{ message: string }> => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await emptyTrashDirectory(token);
      set({ successMessage: result.message || 'Trash emptied successfully', isLoading: false });
      return { message: result.message || 'Trash emptied successfully' }; // Return the success message
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
      throw error; // Ensure to propagate the error
    }
  },

  setTrashSoftAllDirectories: async (): Promise<{ message: string }> => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await setTrashSoftAllDirectories(token);
      set({ successMessage: result.message  || 'Set trash soft for all directories successfully' , isLoading: false });
      return { message: result.message || 'Set trash soft for all directories successfully' }; // Return the success message
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
      throw error; // Ensure to propagate the error

    }
  },

  deleteAllExpiredDirectories: async (): Promise<{ message: string }> => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState();
      if (!token) throw new Error('No token found');
      const result = await deleteAllExpiredDirectories(token);
      set({ successMessage: result.message || 'Expired directories deleted successfully' , isLoading: false });
      return { message: result.message || 'Expired directories deleted successfully'  }; // Return the success message
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
      throw error; // Ensure to propagate the error

    }
  },
  
}));

export { useDirectoryStore };
