import React from 'react';

interface RarIconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly as colors are hardcoded in SVG
}

const RarIcon: React.FC<RarIconProps> = ({ width = 40, height = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34 50H6C4.4087 50 2.88258 49.3679 1.75736 48.2426C0.63214 47.1174 0 45.5913 0 44V11L9 0H34C35.5913 0 37.1174 0.632141 38.2426 1.75736C39.3679 2.88258 40 4.4087 40 6V44C40 45.5913 39.3679 47.1174 38.2426 48.2426C37.1174 49.3679 35.5913 50 34 50Z"
      fill="#576D7E"
    />
    <path
      d="M9 9.556C9 9.93897 8.84787 10.3063 8.57706 10.5771C8.30626 10.8479 7.93897 11 7.556 11H0L9 0V9.556Z"
      fill="#8CA0AF"
    />
    <path
      d="M9.6625 36.9112C9.6625 37.9971 8 38.0346 8 36.9362V29.8409C8 29.4034 8.33906 29.0784 8.75 29.0784H12.0734C15.2453 29.0784 15.8953 33.3268 13.2469 34.3877L14.9078 36.4112C15.5578 37.3737 14.2594 38.2971 13.5078 37.3487L11.6219 34.6502H9.6625V36.9112ZM9.6625 33.2127H12.0734C13.6969 33.2127 13.7984 30.5893 12.0734 30.5893H9.6625V33.2127Z"
      fill="white"
    />
    <path
      d="M26.6625 36.8328C26.6625 37.9188 25 37.9563 25 36.8578V29.7625C25 29.325 25.3391 29 25.75 29H29.0734C32.2453 29 32.8953 33.2484 30.2469 34.3094L31.9078 36.3328C32.5578 37.2953 31.2594 38.2188 30.5078 37.2703L28.6219 34.5719H26.6625V36.8328ZM26.6625 33.1344H29.0734C30.6969 33.1344 30.7984 30.5109 29.0734 30.5109H26.6625V33.1344Z"
      fill="white"
    />
    <path
      d="M16.275 37.7236C15.875 37.4971 15.625 37.0486 15.875 36.5236L19.4594 29.5158C19.7969 28.8533 20.6969 28.8283 21.0094 29.5158L24.5312 36.5236C25.0437 37.4971 23.5312 38.2721 23.0828 37.2986L22.5312 36.1986H17.9219L17.3844 37.2986C17.1859 37.7236 16.7234 37.8127 16.275 37.7236ZM21.6469 34.5502L20.2359 31.4643L18.7 34.5502H21.6469Z"
      fill="white"
    />
  </svg>
);

export default RarIcon;
