import create from "zustand";

interface ShareFileModalState {
  isOpen: boolean;
  names: string[];
  roles: string[];
  setNames: (names: string[]) => void;
  setRoles: (roles: string[]) => void;
  setIsOpen: (isOpen: boolean) => void;
  reset: () => void;
}

export const useShareFileModalStore = create<ShareFileModalState>((set) => ({
  isOpen: false,
  names: [],
  roles: [],
  setNames: (names) => set({ names }),
  setRoles: (roles) => set({ roles }),
  setIsOpen: (isOpen) => set({ isOpen }),
  reset: () => set({ names: [], roles: [] }),
}));
