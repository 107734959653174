import { useRoleStore } from "src/store/api/useRoleStore";
import { useEffect, useRef } from 'react';

export function useFetchRoles() {
  const { roles, isLoadingFetch, errorFetch, fetchRoles } = useRoleStore();
  const didFetch = useRef(false);

  useEffect(() => {
    if (!didFetch.current && roles.length === 0 && !isLoadingFetch && !errorFetch) {
      fetchRoles();
      didFetch.current = true;
    }
  }, [fetchRoles, roles.length, isLoadingFetch, errorFetch]);

  return { roles, isLoadingFetch, errorFetch };
}

export const useRoles = () => {
  const {
    roles,
    isLoadingFetch,
    errorFetch,
    fetchRoles,
    deleteRole,
    updateRole,
    createRole,
  } = useRoleStore();

  return {
    roles,
    isLoadingFetch,
    errorFetch,
    fetchRoles,
    deleteRole,
    updateRole,
    createRole,
  };
};
