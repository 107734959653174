import React from 'react'
import Error404 from '../assets/icons/Errors/PageNotFound404.svg'
import { Button } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/loaders/Loader';

export default function PageNotFound404() {

  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }
  
  return (
    <>
       <div className="text-black">
  <div className="flex items-center justify-center min-h-screen px-2">
    <div className="text-center">
      <img src={Error404} alt="" />
      {/* <h1 className="text-5xl font-bold">404</h1> */}

      <p className="text-2xl font-medium mt-4">{t('PageNotFound')}</p>
      <p className="mt-4 mb-8">{t('PageDoesNotExist')}</p>
      <Button onClick={()=>{navigate('/Shared')}}
        className="bg-success text-background" size='lg'>
        {t('Home')}
      </Button>
    </div>
  </div>
</div>
    </>
  )
}
