import React from "react";

interface IconProps extends React.SVGProps<SVGSVGElement> {
  color?: string; // Optional color prop
}

export const ArIcon: React.FC<IconProps> = ({ color = "white", ...props }) => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 18.5C1 10.2504 1 6.12563 3.56282 3.56282C6.12563 1 10.2504 1 18.5 1C26.7495 1 30.8744 1 33.4371 3.56282C36 6.12563 36 10.2504 36 18.5C36 26.7495 36 30.8744 33.4371 33.4371C30.8744 36 26.7495 36 18.5 36C10.2504 36 6.12563 36 3.56282 33.4371C1 30.8744 1 26.7495 1 18.5Z"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M12.284 12.606H14.264L18.458 24H16.064C15.86 23.4 15.608 22.608 15.308 21.624H11.222C11.054 22.164 10.796 22.956 10.448 24H8.09L12.284 12.606ZM13.292 15.684C12.956 16.632 12.494 17.964 11.906 19.68H14.642C14.078 18.024 13.628 16.692 13.292 15.684ZM19.9869 24V12.624C20.1549 12.588 20.6289 12.54 21.4089 12.48C22.1889 12.42 22.7829 12.39 23.1909 12.39C26.0949 12.39 27.5469 13.578 27.5469 15.954C27.5469 17.238 27.0249 18.33 25.9809 19.23C26.4129 20.226 27.1509 21.816 28.1949 24H25.8189C25.2309 22.716 24.5949 21.336 23.9109 19.86C23.7789 19.884 23.5989 19.896 23.3709 19.896C22.8429 19.896 22.5009 19.848 22.3449 19.752V24H19.9869ZM22.3449 14.406V17.844C22.7049 17.952 23.0949 18.006 23.5149 18.006C24.5469 18.006 25.0629 17.322 25.0629 15.954C25.0629 14.838 24.5289 14.28 23.4609 14.28C22.8489 14.28 22.4769 14.322 22.3449 14.406Z"
      fill={color}
    />
  </svg>
);

export default ArIcon;
