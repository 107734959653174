import React, { useState, useEffect } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Input } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import useCreateDirectory from "src/hooks/useCreateDirectory";
import { useListAllDirectories } from "src/hooks/useListAllDirectories";
import { Alert } from "antd";

interface AddYearFolderModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddYearFolderModal: React.FC<AddYearFolderModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [year, setYear] = useState('');
  const { createDirectory, isLoading } = useCreateDirectory();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMsg, setSuccessMsg] = useState<string | null>(null);
  const { fetchDirectories } = useListAllDirectories();

  useEffect(() => {
    if (isOpen) {
      setYear('');
      setErrorMessage(null);
      setSuccessMsg(null);
    }
  }, [isOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await createDirectory(year);
      setSuccessMsg(response.message);
      setErrorMessage(null);
      await fetchDirectories(); // Re-fetch directories after a successful creation
    } catch (err) {
      setErrorMessage((err as Error).message || "An unknown error occurred");
      setSuccessMsg(null);
    }
  };

  const extractErrorMessage = (errorMsg: string | null) => {
    if (!errorMsg) return '';
    const prefix = "Error: Failed to create directory: ";
    return errorMsg.startsWith(prefix) ? errorMsg.substring(prefix.length) : errorMsg;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">{t('AddNewYearFolder')}</ModalHeader>
        <ModalBody>
          {/* {responseMessage && ( */}
          {successMsg && <Alert message={successMsg}
            // type={responseStatus === "success" ? "success" : "error"} استخدموا هذا لشروط الالوان
            type='success'
            showIcon closable />}
          {errorMessage &&
            <Alert message={extractErrorMessage(errorMessage)}
              // type={responseStatus === "success" ? "success" : "error"} استخدموا هذا لشروط الالوان
              type='error'
              showIcon closable />}

          {/* )} */}
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <Input
              type="number"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              label={t('FolderName')}
              labelPlacement='outside'
              description={t('Yearsonly')}
              required
            />
            {isLoading && <p>{t('CreatingDirectory')}...</p>}

            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                {t('Close')}
              </Button>
              <Button type="submit" color="primary" disabled={isLoading}>
                {t('Save')}
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddYearFolderModal;
