import React, { useState, useEffect } from "react";
import NavBar from "../components/header/NarBar";
import { Tabs, Tab } from "@nextui-org/react";
import MainSidebar from "../components/sidebar/MainSidebar";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import Users from "../components/settings/Users";
import Roles from "../components/settings/Roles";
import UsersSAFF from '../components/settings/SAFFEmp';
import { useUserListStore } from 'src/store/api/useUserListStore';
import { Key } from 'react';
import { useAuthStore } from "src/store/api/useAuthStore";

const Settings: React.FC = () => {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(1);
  const is_super_admin = useAuthStore((state) => state.permissions?.is_supper_admin);
  const is_administrators = useAuthStore((state) => state.permissions?.is_administrators);

  const {
    setPageUsers,
    setRowsPerPageUsers,
    fetchUsers,
    setPageUsersSAFF,
    setRowsPerPageUsersSAFF,
    fetchUsersSAFF,
  } = useUserListStore();

  useEffect(() => {
    if (activeTab === 1) {
      setPageUsers(1);
      setRowsPerPageUsers(10);
      fetchUsers(1, 10);
    } else if (activeTab === 2) {
      setPageUsersSAFF(1);
      setRowsPerPageUsersSAFF(10);
      fetchUsersSAFF(1, 10);
    }
  }, [activeTab, setPageUsers, setRowsPerPageUsers, fetchUsers, setPageUsersSAFF, setRowsPerPageUsersSAFF, fetchUsersSAFF]);

  const handleTabChange = (key: Key) => {
    const tabKey = typeof key === 'string' ? parseInt(key, 10) : Number(key);
    console.log("Tab key type:", typeof key, "Value:", key);
    setActiveTab(tabKey);
  };

  return (
    <div className="bg-main min-h-screen overflow-x-hidden">
      <NavBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex">
        <MainSidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <div className="flex-1 p-4 overflow-x-hidden">
          <Breadcrumbs isDisabled>
            <BreadcrumbItem>{t("SAFFCLOUD")}</BreadcrumbItem>
            <BreadcrumbItem>{t("Settings")}</BreadcrumbItem>
          </Breadcrumbs>

          <div className="text-[33px] capitalize text-navy-700 mt-4 mb-5">
            <div className="font-bold capitalize hover:text-navy-700">
              {t("Settings")}
            </div>
          </div>

          <Tabs
            variant="underlined"
            aria-label="Shared Tabs"
            onSelectionChange={handleTabChange}
            selectedKey={String(activeTab)}
          >
               {is_super_admin || is_administrators ? (
              <Tab key="1" title={t("SaffCloudUsers")}>
                <Users />
              </Tab>
            ) : ''}
                     {is_super_admin  ? (
            <Tab key="2" title={t("SaffEmployees")}>
            <UsersSAFF />
          </Tab>
            ) : null}
                     {is_super_admin  ? (
            <Tab key="3" title={t("Roles")}>
            <Roles />
          </Tab>
            ) : null}
          
          
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Settings;
