import { useCallback, useEffect } from 'react';
import { useDirectoryStore } from '../store/api/useDirectoryStore';


const useListAllDirectories = () => {
  const { listAllDirectories, directories } = useDirectoryStore((state) => ({
    listAllDirectories: state.listAllDirectories,
    directories: state.directories,
  }));

  const fetchDirectories = useCallback(async () => {
    if (!directories || directories.length === 0) {
      await listAllDirectories();
    }
  }, [directories, listAllDirectories]);

  const getDefaultDirectory = () => {
    if (directories && directories.length > 0) {
      return directories[0]; // Select the first directory as the default
    }
    return null;
  };

  // Automatically fetch directories when the hook is used
  useEffect(() => {
    fetchDirectories();
  }, [fetchDirectories]);

  return { directories, fetchDirectories, getDefaultDirectory };
};



const useCreateDirectory = () => {
  const { createDirectory: storeCreateDirectory } = useDirectoryStore();
  const { fetchDirectories } = useListAllDirectories();

  const createDirectory = async (year: string) => {
    try {
      const response = await storeCreateDirectory(year);
      // Fetch the updated list of directories after creating a new one
      await fetchDirectories();
      return { success: true, message: response.message };
    } catch (error) {
      return { success: false, message: (error as Error).message };
    }
  };

  return { createDirectory };
};

export { useListAllDirectories, useCreateDirectory };
