import { NavLink } from 'react-router-dom'
import LanguageSwitcher from '../switchers/LanguageSwitcher'
import SignInModal from '../modals/SignInModal'
import Logo from '../../assets/images/mysaff.jpeg'
import { useTranslation } from 'react-i18next'

export default function Header() {

    const { t } = useTranslation();
    
  return (
    <>
     <header className="sticky top-0 bg-white shadow z-50">
          <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
            <div className="flex items-center text-2xl">
              <div className="flex justify-center items-center gap-5 max-sm:items-center">
                <img
                  className="w-auto h-14 max-sm:h-10 rounded-xl border"
                  src={Logo}
                  alt="Logo"
                />
                <div className="text-[1.8rem] font-bold">{t("SAFFCLOUD")}</div>
              </div>
            </div>
            <div className="flex mt-4 sm:mt-0">
              {/* <NavLink className={({ isActive }) =>
                      `group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-gray-400 duration-300 ease-in-out 
                         hover:text-success  ${isActive ? "text-success" : ""}`
                    } to="/About">
              {t('AboutUs')}
              </NavLink> */}
              <NavLink className={({ isActive }) =>
                      `group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-gray-400 duration-300 ease-in-out 
                      hover:text-success   ${isActive ? "text-success" : ""}`
                    } target="_blank" to="https://saff.sa">
                {t('SAFFWebsite')}
              </NavLink>
              {/* <NavLink className={({ isActive }) =>
                      `group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-gray-400 duration-300 ease-in-out 
                      hover:text-success ${isActive ? "text-success" : ""}`
                    } to="/TermsAndConditions">
              {t('TermsAndConditions')} 
              </NavLink> */}
            </div>

            <div className="max-sm:hidden md:block">
              <div className="flex items-center justify-center gap-5">
                <div>
                  {/* <Button
                    className="bg-success text-background"
                    size="lg"
                    onClick={() => {
                      navigate("/log1");
                    }}
                  >
                    {t("Signin")}
                  </Button> */}
                  <SignInModal />
                </div>

                <div className="mt-3">
                  <LanguageSwitcher />
                </div>
              </div>
            </div>
          </div>
        </header>
    </>
  )
}
