import React from 'react';

interface WebmIconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly as colors are hardcoded in SVG
}

const WebmIcon: React.FC<WebmIconProps> = ({ width = 40, height = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34 50H6C4.4087 50 2.88258 49.3679 1.75736 48.2426C0.63214 47.1174 0 45.5913 0 44V11L9 0H34C35.5913 0 37.1174 0.632141 38.2426 1.75736C39.3679 2.88258 40 4.4087 40 6V44C40 45.5913 39.3679 47.1174 38.2426 48.2426C37.1174 49.3679 35.5913 50 34 50Z"
      fill="#E99C1B"
    />
    <path
      d="M9 9.556C9 9.93897 8.84787 10.3063 8.57706 10.5771C8.30626 10.8479 7.93897 11 7.556 11H0L9 0V9.556Z"
      fill="#FFDCA1"
    />
    <path
      d="M15.78 34.1429C15.3397 34.1429 15 33.7859 15 33.3233V25.8196C15 25.3437 15.3397 25 15.78 25H20.2932C21.4506 25 21.4255 26.7169 20.2932 26.7169H16.6732V28.6998H19.8419C20.96 28.6998 20.96 30.4315 19.8419 30.4315H16.6732V32.4128H20.5087C21.6535 32.4128 21.74 34.1429 20.5087 34.1429H15.78Z"
      fill="white"
    />
    <path
      d="M22 25.7756C22 25.4053 22.2903 25 22.7616 25H25.3535C26.8107 25 28.1235 25.9762 28.1235 27.8485C28.1235 29.6213 26.8107 30.6073 25.3535 30.6073H23.4783V32.0884C23.4783 32.5835 24.2006 33.5274 23.7952 33.5274C23.7952 33.5274 22 34.3344 22 33.8393V25.7756ZM23.4783 26.4138V29.2062H25.3521C26.1024 29.2062 26.6971 28.5442 26.6971 27.8485C26.6971 27.0631 26.1024 26.4138 25.3521 26.4138H23.4783Z"
      fill="white"
    />
    <path
      d="M22.9653 30.1064C22.9652 29.8121 23.2305 29.4898 23.6613 29.4896L26.0306 29.4887C27.3628 29.4882 28.5631 30.2637 28.5637 31.752C28.5642 33.1612 27.3645 33.9454 26.0323 33.9459L24.3181 33.9466L22.4487 33.9761C23.1797 32.6612 23.0053 33.1198 23.694 33.115C23.3555 33.1151 22.449 33.9209 22.4488 33.5273L22.4487 32.6297L22.4489 31.2833L22.9653 30.1064ZM23.6612 30.6126L23.694 33.115L23.6903 32.8322L26.0306 32.8322C26.7166 32.8319 27.26 32.3055 27.2598 31.7525C27.2595 31.1282 26.7157 30.6122 26.0298 30.6125L23.6612 30.6126Z"
      fill="white"
    />
    <path
      d="M11.2757 30.7402L13.1367 25.5918C13.5101 24.5824 15.0226 25.0543 14.6476 26.1418L12.1742 32.8527C11.9492 33.5636 11.7382 33.7777 11.3117 33.7777C10.8632 33.7777 10.7242 33.5011 10.4757 33.0511L8.30231 28.1152L6.22887 32.9761C6.00231 33.4996 5.79294 33.7996 5.34137 33.7996C4.90544 33.7996 4.67887 33.4996 4.48044 32.9496L2.057 26.1402C1.682 25.0168 3.26794 24.6058 3.59137 25.5902L5.44137 30.7386L7.65387 25.5902C7.95231 24.8293 8.73981 24.7902 9.06325 25.5543L11.2757 30.7402Z"
      fill="white"
    />
    <path
      d="M30.6484 28.2734V32.8969C30.6484 33.4469 30.1984 33.7578 29.75 33.7578C29.35 33.7578 29 33.4469 29 32.8969V25.8625C29 25.2125 29.5516 25 29.75 25C30.1109 25 30.3234 25.2125 30.5359 25.45L33.2859 29.1609L36.1328 25.3125C36.5469 24.8 37.5578 25 37.5578 25.8625V32.8969C37.5578 33.4469 37.2063 33.7578 36.8078 33.7578C36.3594 33.7578 36.0078 33.4469 36.0078 32.8969V28.2734L33.9344 30.8984C33.4859 31.4484 32.9234 31.4484 32.5109 30.8984L30.6484 28.2734Z"
      fill="white"
    />
  </svg>
);

export default WebmIcon;
