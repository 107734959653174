import React from 'react';

interface InddIconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly as colors are hardcoded in SVG
}

const InddIcon: React.FC<InddIconProps> = ({ width = 40, height = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34 50H6C4.4087 50 2.88258 49.3679 1.75736 48.2426C0.63214 47.1174 0 45.5913 0 44V11L9 0H34C35.5913 0 37.1174 0.632141 38.2426 1.75736C39.3679 2.88258 40 4.4087 40 6V44C40 45.5913 39.3679 47.1174 38.2426 48.2426C37.1174 49.3679 35.5913 50 34 50Z"
      fill="#A066AA"
    />
    <path
      d="M9 9.556C9 9.93897 8.84787 10.3063 8.57706 10.5771C8.30626 10.8479 7.93897 11 7.556 11H0L9 0V9.556Z"
      fill="#D6AEDD"
    />
    <path
      d="M4 23.8625C4 22.8375 5.64844 22.8 5.64844 23.8625V30.9953C5.64844 32.0312 4 32.0578 4 30.9953V23.8625Z"
      fill="white"
    />
    <path
      d="M7.86401 23.9875C7.86401 23.5375 7.96401 23.1 8.61245 23.1C9.06245 23.1 9.16245 23.2125 9.49995 23.5375L13.6343 28.7094V23.8625C13.6343 23.45 13.9953 23 14.4218 23C14.8703 23 15.3078 23.45 15.3078 23.8625V30.8953C15.3078 31.4469 14.9703 31.6578 14.6453 31.7578C14.2093 31.7578 13.9953 31.6594 13.6343 31.3219L9.49995 26.0734V30.8953C9.49995 31.4469 9.16245 31.7578 8.71245 31.7578C8.26245 31.7578 7.86401 31.4469 7.86401 30.8953V23.9875Z"
      fill="white"
    />
    <path
      d="M18.3406 31.7579C17.9281 31.7579 17.4781 31.5329 17.4781 30.9844V23.8875C17.4781 23.4375 17.9281 23.1125 18.3406 23.1125H21.2016C26.911 23.1125 26.786 31.7579 21.3141 31.7579H18.3406ZM19.1281 24.636V30.2329H21.2016C24.575 30.2329 24.725 24.636 21.2016 24.636H19.1281Z"
      fill="white"
    />
    <path
      d="M28.0422 31.7579C27.6297 31.7579 27.1797 31.5329 27.1797 30.9844V23.8875C27.1797 23.4375 27.6297 23.1125 28.0422 23.1125H30.9031C36.6125 23.1125 36.4875 31.7579 31.0156 31.7579H28.0422ZM28.8297 24.636V30.2329H30.9031C34.2766 30.2329 34.4266 24.636 30.9031 24.636H28.8297Z"
      fill="white"
    />
  </svg>
);

export default InddIcon;
