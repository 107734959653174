import React from 'react';
import { Card, CardHeader, CardBody, Progress } from '@nextui-org/react';
import { useTranslation } from "react-i18next";
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useDownloadStore } from 'src/store/layout/useDownloadStore';
import FileLinkIcon from "../../assets/icons/FileLinkIcon";
import { useUploadFilesHooks } from 'src/hooks/useUploadFilesHooks';

export default function DownloadList() {
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const [isCollapsed, setIsCollapsed] = React.useState(true);
    const [completedDownloads, setCompletedDownloads] = React.useState<{ id: string; name: string; message: string }[]>([]);
    const { files, successMessage, errorMessage } = useUploadFilesHooks();

    const isArabic = i18n.language === 'ar';

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const { downloads } = useDownloadStore(state => ({
        downloads: state.downloads,
    }));

    React.useEffect(() => {
        const message = successMessage || t('DownloadCompletedSuccessfully');
        files.forEach(file => {
            if (file.progress === 100 && !completedDownloads.some(cd => cd.id === file.id)) {
                setCompletedDownloads(prev => [...prev, { id: file.id, name: file.file.name, message }]);
            }
        });
    }, [files, completedDownloads, successMessage, t]);

    return (
        <Card
            className={`fixed ${isArabic ? 'left-9' : 'right-9'} bottom-0 
            bg-[#FAFAFB] shadow-2xl w-[22rem] flex-col transition-all duration-500 z-50 border 
            ${isCollapsed ? "h-[3rem]" : "h-[20rem]"}`} // Adjust height as needed
            style={{ transitionTimingFunction: 'ease-in-out' }}
        >
            <CardHeader
                className="flex justify-between items-center cursor-pointer py-2 px-4"
                onClick={toggleCollapse}
            >
                <h1 className="text-xl font-bold">
                    {isArabic ? "قائمة الملفات المرفوعة" : "Upload List"}
                </h1>
                {isCollapsed ? <FiChevronUp /> : <FiChevronDown />}
            </CardHeader>
            <CardBody
                className={`overflow-y-auto transition-all duration-500 ${isCollapsed ? "hidden" : "block"} py-2 gap-3`}
            >
                <div>
                    {downloads.length === 0 ? (
                        <p className='text-center mt-3'>{isArabic ? "لا يوجد ملفات مرفوعة" : "No uploads"}</p>
                    ) : (
                        files.length > 0 &&
                        files.map((fileWithProgress) => (
                            <div key={fileWithProgress.id} className="flex items-center space-x-2 mb-2">
                                <div className="flex items-center">
                                    <FileLinkIcon width="30" />
                                    <span className="text-xs">{fileWithProgress.file.name}</span>
                                </div>
                                <Progress value={fileWithProgress.progress} />

                            </div>
                        ))
                    )}
                </div>
                <div className="mt-5">
                    {/* <h2 className="text-lg font-semibold">{isArabic ? "التنزيلات المكتملة" : "Completed Downloads"}</h2> */}
                    {completedDownloads.length === 0 ? (
                        <p>{isArabic ? "" : ""}</p>
                    ) : (
                        completedDownloads.map(download => (
                            <div key={download.id} className="flex justify-between items-center border-b py-2">
                                <span className="text-sm">{download.name}</span>
                                <span className="text-sm text-green-600">{download.message}</span>
                                {errorMessage && (
                                    <div style={{ color: 'red', marginBottom: '10px' }}>
                                        {t(errorMessage)}
                                    </div>
                                )}
                            </div>
                        ))
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
