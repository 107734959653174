// src/store/layout/useDownloadStore.ts
import create from 'zustand';

interface Download {
  id: string;
  fileName: string;
  progress: number;
}

interface DownloadStore {
  downloads: Download[];
  addDownload: (file: Download) => void;
  updateProgress: (id: string, progress: number) => void;
}

export const useDownloadStore = create<DownloadStore>((set) => ({
  downloads: [],
  addDownload: (file) => set((state) => ({ downloads: [...state.downloads, file] })),
  updateProgress: (id, progress) =>
    set((state) => ({
      downloads: state.downloads.map((download) =>
        download.id === id ? { ...download, progress } : download
      ),
    })),
  
}));
