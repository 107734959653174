import { useEffect, useState } from 'react';
import { useUserListStore } from 'src/store/api/useUserListStore';

// Hook for managing user actions

// Hook for fetching Users
export const useFetchUsers = () => {
  const { pageUsers, rowsPerPageUsers, fetchUsers, lastFetchedPageUsers } = useUserListStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (lastFetchedPageUsers !== pageUsers) {
      setLoading(true);
      fetchUsers(pageUsers, rowsPerPageUsers).finally(() => {
        setLoading(false);
      });
    }
  }, [pageUsers, rowsPerPageUsers, lastFetchedPageUsers, fetchUsers]);

  return { loading };
};

// Hook for fetching UsersSAFF
export const useFetchUsersSAFF = () => {
  const { pageUsersSAFF, rowsPerPageUsersSAFF, fetchUsersSAFF, lastFetchedPageUsersSAFF } = useUserListStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (lastFetchedPageUsersSAFF !== pageUsersSAFF) {
      setLoading(true);
      fetchUsersSAFF(pageUsersSAFF, rowsPerPageUsersSAFF).finally(() => {
        setLoading(false);
      });
    }
  }, [pageUsersSAFF, rowsPerPageUsersSAFF, lastFetchedPageUsersSAFF, fetchUsersSAFF]);

  return { loading };
};

export const useUserActions = () => {
  const {
    // Users
    setPageUsers,
    setRowsPerPageUsers,
    setFilterValueUsers,
    pageUsers,
    rowsPerPageUsers,
    users,
    totalPagesUsers,
    totalUsersUsers,

    // UsersSAFF
    setPageUsersSAFF,
    setRowsPerPageUsersSAFF,
    setFilterValueUsersSAFF,
    pageUsersSAFF,
    rowsPerPageUsersSAFF,
    usersSAFF,
    totalPagesUsersSAFF,
    totalUsersUsersSAFF,

    // User registration
    nameEn,
    nameAr,
    email,
    phone,
    roles,
    password,
    setNameEn,
    setNameAr,
    setEmail,
    setPhone,
    setPassword,
    setRoles,
    reset, // Access the registerUser function from the Zustand store
    registrationStatus,
    registrationMessage,
    resetRegistrationStatus,
    registerUser, // Access the registerUser function from the Zustand store
  } = useUserListStore();

  const handleRowsPerPageChangeUsers = (value: number) => {
    setRowsPerPageUsers(value);
    setPageUsers(1); // Reset to the first page on rows per page change
  };

  const handleSearchChangeUsers = (value: string) => {
    setFilterValueUsers(value || '');
    setPageUsers(1); // Reset to the first page on search change
  };

  const handleRowsPerPageChangeUsersSAFF = (value: number) => {
    setRowsPerPageUsersSAFF(value);
    setPageUsersSAFF(1); // Reset to the first page on rows per page change
  };

  const handleSearchChangeUsersSAFF = (value: string) => {
    setFilterValueUsersSAFF(value || '');
    setPageUsersSAFF(1); // Reset to the first page on search change
  };

  // Handle registration action
  const handleRegisterUser = async () => {
    await registerUser();
  };

  return {
    // Actions for Users
    setPageUsers,
    handleRowsPerPageChangeUsers,
    handleSearchChangeUsers,
    pageUsers,
    rowsPerPageUsers,
    users,
    totalPagesUsers,
    totalUsersUsers,

    // Actions for UsersSAFF
    setPageUsersSAFF,
    handleRowsPerPageChangeUsersSAFF,
    handleSearchChangeUsersSAFF,
    pageUsersSAFF,
    rowsPerPageUsersSAFF,
    usersSAFF,
    totalPagesUsersSAFF,
    totalUsersUsersSAFF,

    // Actions for user registration
    nameEn,
    nameAr,
    email,
    phone,
    roles,
    password,
    setNameEn,
    setNameAr,
    setEmail,
    setPhone,
    setPassword,
    setRoles,
    reset,  // Access the registerUser function from the Zustand store
   registrationStatus,
    registrationMessage,
    resetRegistrationStatus,
    handleRegisterUser, // Expose the registerUser action as handleRegisterUser
  };
};

