import React from 'react';

interface ListIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const ListIcon: React.FC<ListIconProps> = ({ width = 24, height = 24, color = '#000000' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M8 6.00067L21 6.00139M8 12.0007L21 12.0015M8 18.0007L21 18.0015" />
      <circle cx="3.5" cy="6" r="0.5" />
      <circle cx="3.5" cy="12" r="0.5" />
      <circle cx="3.5" cy="18" r="0.5" />
    </g>
  </svg>
);

export default ListIcon;
