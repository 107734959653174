import React from 'react';

interface PsbIconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly as colors are hardcoded in SVG
}

const PsbIcon: React.FC<PsbIconProps> = ({ width = 40, height = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34 50H6C4.4087 50 2.88258 49.3679 1.75736 48.2426C0.63214 47.1174 0 45.5913 0 44V11L9 0H34C35.5913 0 37.1174 0.632141 38.2426 1.75736C39.3679 2.88258 40 4.4087 40 6V44C40 45.5913 39.3679 47.1174 38.2426 48.2426C37.1174 49.3679 35.5913 50 34 50Z"
      fill="#A6A4ED"
    />
    <path
      d="M9 9.556C9 9.93897 8.84787 10.3063 8.57706 10.5771C8.30626 10.8479 7.93897 11 7.556 11H0L9 0V9.556Z"
      fill="#DDDCFF"
    />
    <path
      d="M8 23.8641C8 23.4516 8.32344 23 8.84844 23H11.7359C13.3594 23 14.8219 24.0875 14.8219 26.1734C14.8219 28.1484 13.3594 29.2469 11.7359 29.2469H9.64687V30.8969C9.64687 31.4484 9.3 31.7594 8.84844 31.7594C8.43594 31.7594 8 31.4484 8 30.8969V23.8641ZM9.64687 24.575V27.6859H11.7344C12.5703 27.6859 13.2328 26.9484 13.2328 26.1734C13.2328 25.2984 12.5703 24.575 11.7344 24.575H9.64687Z"
      fill="white"
    />
    <path
      d="M16.1446 25.2705C16.4321 22.847 20.0914 22.4111 21.8164 23.7361C22.6664 24.422 21.7664 25.5095 21.0164 24.9595C20.0914 24.372 17.993 24.097 17.7305 25.3955C17.393 27.4455 22.8274 26.272 22.7399 29.5939C22.6524 32.7673 18.0555 32.8423 16.3305 31.4173C15.918 31.0814 15.9305 30.533 16.1555 30.1939C16.4805 29.8705 16.8414 29.758 17.268 30.1064C18.2914 30.8064 20.9289 31.3314 21.1164 29.5548C20.9539 27.708 15.7571 28.8205 16.1446 25.2705Z"
      fill="white"
    />
    <path
      d="M25 23.7756C25 23.4053 25.2903 23 25.7616 23H28.3535C29.8107 23 31.1235 23.9762 31.1235 25.8485C31.1235 27.6214 29.8107 28.6073 28.3535 28.6073H26.4783V30.0884C26.4783 30.5835 27.2006 31.5274 26.7952 31.5274C26.7952 31.5274 25 32.3344 25 31.8393V23.7756ZM26.4783 24.4138V27.2062H28.3521C29.1024 27.2062 29.6971 26.5442 29.6971 25.8485C29.6971 25.0631 29.1024 24.4138 28.3521 24.4138H26.4783Z"
      fill="white"
    />
    <path
      d="M25.9653 28.1064C25.9652 27.8121 26.2305 27.4898 26.6613 27.4896L29.0306 27.4887C30.3628 27.4882 31.5631 28.2637 31.5637 29.752C31.5642 31.1612 30.3645 31.9454 29.0323 31.9459L27.3181 31.9466L25.4487 31.9761C26.1797 30.6612 26.0053 31.1198 26.694 31.115C26.3555 31.1151 25.449 31.9209 25.4488 31.5273L25.4487 30.6297L25.4489 29.2833L25.9653 28.1064ZM26.6612 28.6126L26.694 31.115L26.6903 30.8322L29.0306 30.8322C29.7166 30.8319 30.26 30.3055 30.2598 29.7525C30.2595 29.1282 29.7157 28.6122 29.0298 28.6125L26.6612 28.6126Z"
      fill="white"
    />
  </svg>
);

export default PsbIcon;
