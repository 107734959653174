import React from 'react';

interface JpgIconProps {
  width?: number;
  height?: number;
  color?: string; // Not used directly as colors are hardcoded in SVG
}

const JpgIcon: React.FC<JpgIconProps> = ({ width = 40, height = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34 50H6C4.4087 50 2.88258 49.3679 1.75736 48.2426C0.63214 47.1174 0 45.5913 0 44V11L9 0H34C35.5913 0 37.1174 0.632141 38.2426 1.75736C39.3679 2.88258 40 4.4087 40 6V44C40 45.5913 39.3679 47.1174 38.2426 48.2426C37.1174 49.3679 35.5913 50 34 50Z"
      fill="#80CB93"
    />
    <path
      d="M9 9.556C9 9.93897 8.84787 10.3063 8.57706 10.5771C8.30626 10.8479 7.93897 11 7.556 11H0L9 0V9.556Z"
      fill="#AAEEBD"
    />
    <path
      d="M11.3508 23.8876C11.3508 22.7041 13 22.7041 13 23.8876V29.4495C13 31.6615 12.063 33 9.9031 33C8.84113 33 7.95563 32.6742 7.18101 31.5488C6.54383 30.6893 7.74323 29.4512 8.39291 30.3512C8.91765 31.0838 9.47987 31.2529 10.028 31.2247C10.7261 31.1965 11.3399 30.8425 11.3524 29.4495V23.8876H11.3508Z"
      fill="white"
    />
    <path
      d="M16 23.8864C16 23.4624 16.3334 23 16.872 23H19.8326C21.4997 23 23 24.1176 23 26.2596C23 28.2892 21.4997 29.4196 19.8326 29.4196H17.6927V31.1153C17.6927 31.6805 17.3336 32 16.872 32C16.4488 32 16 31.6805 16 31.1153V23.8864ZM17.6927 24.6169V27.8155H19.8326C20.6918 27.8155 21.3714 27.056 21.3714 26.2596C21.3714 25.362 20.6918 24.6169 19.8326 24.6169H17.6927Z"
      fill="white"
    />
    <path
      d="M32.6839 31.8276C31.7818 32.6511 30.6764 33 29.5599 33C26.8916 33 25 31.3269 25 28.0052C25 25.1895 26.9948 23 29.6726 23C30.6764 23 31.7802 23.3751 32.568 24.2264C33.3415 25.0621 32.2249 26.3043 31.4642 25.5663C30.994 25.0639 30.3333 24.687 29.6726 24.687C28.1368 24.687 26.6518 26.0391 26.6518 28.0052C26.6518 30.0708 27.8954 31.3269 29.5599 31.3269C30.3333 31.3269 30.994 31.074 31.4642 30.6989V28.9839H29.5599C28.429 28.9839 28.5434 27.2812 29.5599 27.2812H32.0995C32.5696 27.2812 33 27.672 33 28.1047V31.0757C33 31.3269 32.8999 31.5607 32.6839 31.8276Z"
      fill="white"
    />
  </svg>
);

export default JpgIcon;
