import React from "react";
import { useTranslation } from "react-i18next";
import SignInModal from "src/components/modals/SignInModal";
import img1 from "../assets/images/Landing Page/featureOne.jpg";
import img2 from "../assets/images/Landing Page/featureTwo.jpg";
import img3 from "../assets/images/Landing Page/featureThree.jpg";
import WordRotate from "src/utils/ui/magicui/word-rotate";
import AnimatedGridPattern from "src/utils/ui/magicui/animated-grid-pattern";
import Header from "src/components/landning_page/Header";
import { cn } from "src/utils/Utils";
import { StickyScroll } from "src/utils/ui/sticky-scroll-reveal";
import Loader from "src/components/loaders/Loader";

export default function LandingPage() {
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }

  const content = [
    {
      title: t("EnhancedSecurityAndReliability"),
      description: t("SAFFCloudProjectGoal"),
      content: (
        <div
          className="bg-cover bg-center bg-no-repeat h-full"
          style={{ backgroundImage: `url(${img1})` }}
        ></div>
      ),
    },
    {
      title: t("IncreasedAccessibility"),
      description: t("CrossDeviceAccessibility"),
      content: (
        <div
          className="bg-cover bg-center bg-no-repeat h-full"
          style={{ backgroundImage: `url(${img2})` }}
        ></div>
      ),
    },
    {
      title: t("ControlledSharingWithExternalStakeholders"),
      description: t("ControlledFileSharing"),
      content: (
        <div
          className="bg-cover bg-center bg-no-repeat h-full"
          style={{ backgroundImage: `url(${img3})` }}
        ></div>
      ),
    },
    // {
    //   title: "Running out of content",
    //   description:
    //     "Experience real-time updates and never stress about version control again. Our platform ensures that you're always working on the most recent version of your project, eliminating the need for constant manual updates. Stay in the loop, keep your team aligned, and maintain the flow of your work without any interruptions.",
    //   content: (
    //     <div className="h-full w-full bg-[linear-gradient(to_bottom_right,var(--cyan-500),var(--emerald-500))] flex items-center justify-center text-white">
    //       Running out of content
    //     </div>
    //   ),
    // },
  ];

  return (
    <>
      <div>
        <Header />
        {/* <header className="sticky top-0 bg-white shadow z-50">
          <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
            <div className="flex items-center text-2xl">
              <div className="flex justify-center items-center gap-5 max-sm:items-center">
                <img
                  className="w-auto h-14 max-sm:h-10"
                  src={Logo}
                  alt="Logo"
                />
                <div className="text-[1.8rem] font-bold">{t("SAFFCLOUD")}</div>
              </div>
            </div>
            <div className="flex mt-4 sm:mt-0">
              <NavLink className={({ isActive }) =>
                      `group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-gray-400 duration-300 ease-in-out 
                         hover:text-success  ${isActive ? "text-success" : ""}`
                    } to="/About">
              {t('AboutUs')}
              </NavLink>
              <NavLink className={({ isActive }) =>
                      `group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-gray-400 duration-300 ease-in-out 
                      hover:text-success   ${isActive ? "text-success" : ""}`
                    } target="_blank" to="https://saff.sa">
                {t('SAFFWebsite')}
              </NavLink>
              <NavLink className={({ isActive }) =>
                      `group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-gray-400 duration-300 ease-in-out 
                      hover:text-success ${isActive ? "text-success" : ""}`
                    } to="/TermsAndConditions">
              {t('TermsAndConditions')}
              </NavLink>
            </div>

            <div className="max-sm:hidden md:block">
              <div className="flex items-center justify-center gap-5">
                <div>
            
                  <SignInModal />
                </div>

                <div className="mt-3">
                  <LanguageSwitcher />
                </div>
              </div>
            </div>
          </div>
        </header> */}

        {/* </FadeText> */}
        <div className="relative flex h-[700px] w-full items-center justify-center overflow-hidden rounded-lg bg-background p-20">
          <p className="z-10 whitespace-pre-wrap text-center text-5xl font-medium text-black">
            <div className="flex justify-center items-center flex-wrap">
              {t("hero1")}
              <div>
                <WordRotate
                  className="text-5xl font-medium text-center text-success dark:text-white"
                  words={[t("trusted"), t("secure")]}
                />
              </div>
              {t("hero2")}
            </div>
            <div className="text-xl lg:text-2xl mt-6 font-light">
              {" "}
              {t("OfficialPlatformDescription")}{" "}
            </div>

            <div className="mt-8 md:mt-12">
              <SignInModal />
            </div>
          </p>
          <AnimatedGridPattern
            numSquares={30}
            maxOpacity={0.1}
            duration={2}
            repeatDelay={1}
            className={cn(
              "[mask-image:radial-gradient(500px_circle_at_center,white,transparent)]",
              "inset-x-0 inset-y-[-30%] h-[200%] skew-y-12"
            )}
          />
        </div>
        <div className="p-10 mt-10">
          <StickyScroll content={content} />
        </div>

        <footer className="container mx-auto py-16 px-3 mt-48 mb-8 text-gray-800 border-t-1">
          <div className="flex -mx-3">
            <div className="flex-1 px-3">
              <h2 className="text-lg font-semibold">{t('PoweredBy')}</h2>
              <p className="mt-5">
                {t('PoweredByTechnologyAndInnovationTeam')}
              </p>
            </div>
            {/* <div className="flex-1 px-3">
              <h2 className="text-lg font-semibold">Important Links</h2>
              <ul className="mt-4 leading-loose">
                <li>
                  <a href="https://codebushi.com">Terms &amp; Conditions</a>
                </li>
                <li>
                  <a href="https://codebushi.com">Privacy Policy</a>
                </li>
              </ul>
            </div> */}

            {/* <div className="flex-1 px-3">
              <h2 className="text-lg font-semibold">Social Media</h2>
              <ul className="mt-4 leading-loose">
                <li>
                  <a target="_blank" href="https://saff.sa" rel="noreferrer">
                    saff
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://saff.sa" rel="noreferrer">
                    saff
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://saff.sa" rel="noreferrer">
                    saff
                  </a>
                </li>
              </ul>
            </div> */}
            
          </div>
        </footer>
      </div>
    </>
  );
}
