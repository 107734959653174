import React from "react";

interface IconProps extends React.SVGProps<SVGSVGElement> {
  color?: string;  // Optional color prop
  size?: string | number;  // Optional size prop that affects both width and height
}

export const FavIcon: React.FC<IconProps> = ({ color = "#000000", size = "24px", ...props }) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill={color} // Apply color
    width={size} // Control width
    height={size} // Control height
    {...props}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        fill="none"
        stroke={color}
        strokeWidth="2"
        d="M1,8.4 C1,4 4.5,3 6.5,3 C9,3 11,5 12,6.5 C13,5 15,3 17.5,3 C19.5,3 23,4 23,8.4 C23,15 12,21 12,21 C12,21 1,15 1,8.4 Z"
      ></path>
    </g>
  </svg>
);

export default FavIcon;
