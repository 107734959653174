import { useState, useCallback } from 'react';
import useFileStore, { FileDetails } from 'src/store/layout/filesStore1';

const useFileDetails = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [fileDetails, setFileDetails] = useState<FileDetails | null>(null);

  const getFileDetails = useCallback(async (fileId: string) => {
    setIsProcessing(true);
    setError(null);
    try {
      await useFileStore.getState().getFileDetails(fileId);
      const state = useFileStore.getState();
      setFileDetails(state.fileDetails);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsProcessing(false);
    }
  }, []);

  return {
    getFileDetails,
    setFileDetails,
    isProcessing,
    error,
    fileDetails,
  };
};

export default useFileDetails;
