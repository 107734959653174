// src/store/api/useShareStore.ts
import { create } from 'zustand';
import { useAuthStore } from './useAuthStore';
import { ShareState } from 'src/types/api/Share';
import { createShare, deleteFolderApi, deleteShare, fetchPublicShare, fetchSharedFilesByUser, fetchSharedFilesWithUser, fetchSharedFoldersByUser, fetchSharedFoldersWithUser, getPublicShareLink, listAllShareByUser, listAllShares, listAllShareWithUser, saveShare, updateShare } from 'src/services/api/API';
import { useSidebarStore } from '../layout/useSidebarStore';

const useShareStore = create<ShareState>((set, get) => ({
  isShareByLoading: false,
  isShareWithLoading: false,
  isLoading: false,

  error: null,
  ShareByError: null,
  ShareWithError: null,

  ShareBySuccessMessage: null,
  ShareWithSuccessMessage: null,
  successMessage: null,

  shares: null,
  sharedFoldersByUser: null,
  sharedFilesByUser: null,
  sharedFoldersWithUser: null,
  sharedFilesWithUser: null,

  sharedWithUserItems: null,
  sharedByUserItems: null,

  shareWithCurrentPage:1,
  shareByCurrentPage:1,

  shareWithTotalPages: 0,
  shareByTotalPages: 0,

  totalSharedByItems: 0,
  totalSharedWithItems: 0,

  nonSharedUsers: null,
  owner:null ,
  insideSharedUsers:  null,
  outsideSharedUsers: false,
  outsideSharedLink: '',
  publicShareLink: null,
  publicSharedItem: null,
  responseStatus: null,
  responseMessage: null,
  isDeleting: false,
  errorDeleting: null,
  shareWithlastFetchedItemId: null,
  shareBylastFetchedItemId: null,

  setPublicShareLink: (link) => set({ publicShareLink: link }),

  listAllShares: async (resourseType, resourseId) => {
    set({ isLoading: true, error: null , successMessage: null });
    try {
    const { token } = useAuthStore.getState(); // Assuming you have an auth store
    if (!token) throw new Error('No token found');
    const result = await listAllShares(resourseType, resourseId, token);
    //  set({ shares: result, isLoading: false });
     set({
      nonSharedUsers: result.nonSharedUsers,
      owner: result.owner,
      insideSharedUsers: result.insideSharedUsers,
      outsideSharedUsers: result.outsideSharedUsers,
      outsideSharedLink: result.outsideSharedLink,
      isLoading: false
    });

    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

 

  createShare: async (resourceType: 'folder' | 'file', resourceId: string, shareData: Array<{ shared_with_user_id: string; share_type: string; permissions: string; expiration_date?: string }>) => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState(); // Fetch token from the auth store
      if (!token) throw new Error('No token found');
      const result = await createShare(resourceType, resourceId, shareData, token);
      set({ successMessage: result.message, isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  updateShare: async (resourceType: 'folder' | 'file', resourceId: string, shareData: Array<{ shared_with_user_id: string; share_type: string; permissions: string; expiration_date?: string }>) => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState(); // Fetch token from the auth store
      if (!token) throw new Error('No token found');
      const result = await updateShare(resourceType, resourceId, shareData, token);
      set({ successMessage: result.message, isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  saveShare: async (resourceType: 'folder' | 'file', resourceId: string, shareData: Array<{ shared_with_user_id?: string ; share_type: string; permissions?: string ; expiration_date?: string }>) => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState(); // Fetch token from the auth store
      if (!token) throw new Error('No token found');
      const result = await saveShare(resourceType, resourceId, shareData, token);
      set({ successMessage: result.message, isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },
  
  deleteShare: async (resourceType: 'folder' | 'file', resourceId: string) => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { token } = useAuthStore.getState(); // Fetch token from the auth store
      if (!token) throw new Error('No token found');
      const result = await deleteShare(resourceType, resourceId, token);
      set({ successMessage: result.message, isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },


  FetchSharedFoldersByUser: async () => {
    set({ isLoading: true, error: null , successMessage: null });
    try {
      const { token } = useAuthStore.getState(); // Get the token from the auth store
      if (!token) throw new Error('No token found');
      const result = await fetchSharedFoldersByUser(token);
      set({ sharedFoldersByUser: result, isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  FetchSharedFilesByUser: async () => {
    set({ isLoading: true, error: null , successMessage: null });
    try {
      const { token } = useAuthStore.getState(); // Get the token from the auth store
      if (!token) throw new Error('No token found');
      const result = await fetchSharedFilesByUser(token);
      set({ sharedFilesByUser: result, isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  FetchSharedFoldersWithUser: async () => {
    set({ isLoading: true, error: null , successMessage: null });
    try {
      const { token } = useAuthStore.getState(); // Get the token from the auth store
      if (!token) throw new Error('No token found');
      const result = await fetchSharedFoldersWithUser(token);
      set({ sharedFoldersWithUser: result, isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  FetchSharedFilesWithUser: async () => {
    set({ isLoading: true, error: null , successMessage: null });
    try {
      const { token } = useAuthStore.getState(); // Get the token from the auth store
      if (!token) throw new Error('No token found');
      const result = await fetchSharedFilesWithUser(token);
      set({ sharedFilesWithUser: result, isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  fetchPublicShareLink: async (resourseType, resourseId) => {
    set({ isLoading: true, error: null , successMessage: null });
    try {
    const { token } = useAuthStore.getState(); // Assuming you have an auth store
    if (!token) throw new Error('No token found');
    const link = await getPublicShareLink(resourseType, resourseId, token);
    //  set({ shares: result, isLoading: false });
    set({ publicShareLink: link });
     } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },


  listAllShareByUser: async (page: number, rowsPerPage: number) => {
   
    const { shareByCurrentPage, shareBylastFetchedItemId } = get();
    const selectedItemId = useSidebarStore.getState().selectedItemId;
    
    if (shareByCurrentPage === page && shareBylastFetchedItemId === selectedItemId) {
      console.log(`listAllShareByUser Page ${page} already fetched for the selected item ${selectedItemId}, skipping...`);
      return;  // Do not fetch if the page has already been fetched for the current selected item
    }

    await get().refreshListAllShareByUser(page, rowsPerPage); // Refresh list
  },

  refreshListAllShareByUser: async (page: number, rowsPerPage: number) => {
    const selectedItemId = useSidebarStore.getState().selectedItemId;

    set({ isShareByLoading: true, ShareByError: null, ShareBySuccessMessage: null });
    try {
      const { token } = useAuthStore.getState(); 
      if (!token) throw new Error('No token found');
       const result = await listAllShareByUser(token, selectedItemId, page, rowsPerPage);
      set({
        shareByCurrentPage: page, 
        shareByTotalPages: result.totalPages,
        totalSharedByItems: result.totalSharedItems,
        sharedByUserItems: result.sharedByMeItems,
        shareBylastFetchedItemId: selectedItemId,  // Update the last fetched item ID
        isShareByLoading: false
      });
    } catch (error: any) {
      set({ ShareByError: error.message, isShareByLoading: false });
    }
  },

  listAllShareWithUser: async (page: number, rowsPerPage: number) => {
    const { shareWithCurrentPage, shareWithlastFetchedItemId } = get();
    const selectedItemId = useSidebarStore.getState().selectedItemId;

    if (shareWithCurrentPage === page && shareWithlastFetchedItemId === selectedItemId) {
      console.log(`listAllShareWithUser Page ${page} already fetched for the selected item ${selectedItemId}, skipping...`);
      return;  // Do not fetch if the page has already been fetched for the current selected item
    }
    
    await get().refreshListAllShareWithUser(page, rowsPerPage); // Refresh list
  },

  refreshListAllShareWithUser: async (page: number, rowsPerPage: number) => {
    const selectedItemId = useSidebarStore.getState().selectedItemId;
    
    set({ isShareWithLoading: true, ShareWithError: null, ShareWithSuccessMessage: null });
    try {
      const { token } = useAuthStore.getState(); 
      if (!token) throw new Error('No token found');
      const result = await listAllShareWithUser(token, selectedItemId, page, rowsPerPage);
      set({
        shareWithCurrentPage: page, 
        shareWithTotalPages: result.totalPages,
        totalSharedWithItems: result.totalSharedItems,
        sharedWithUserItems: result.sharedWithMeItems,
        shareWithlastFetchedItemId: selectedItemId,  // Update the last fetched item ID
        isShareWithLoading: false
      });
    } catch (error: any) {
      set({ ShareWithError: error.message, isShareWithLoading: false });
    }
  },


  fetchPublicShare: async (shareId: string) => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const result = await fetchPublicShare(shareId);
       set({ publicSharedItem: result, isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  deleteSharedFolder: async (folderId: string) => {
    set({ isDeleting: true, errorDeleting: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      await deleteFolderApi(folderId, token);
      set({ responseMessage: 'Folder deleted successfully.', responseStatus: 'success', isDeleting: false });
      await get().refreshListAllShareByUser(get().shareByCurrentPage, get().totalSharedByItems); // Refresh list
    } catch (error: any) {
      set({ errorDeleting: error.message, responseMessage: error.message, responseStatus: 'error', isDeleting: false });
    }
  },

  
}));

export default useShareStore;
