// Define the column structures for various tables
const columns1 = [
  { name: "ID", uid: "id", sortable: true },
  { name: "File Name", uid: "name", sortable: true },
  { name: "Type", uid: "type", sortable: true },
  { name: "Actions", uid: "actions" },
];

const columns2 = [
  { name: "ID", uid: "id", sortable: true },
  { name: "File Name", uid: "name", sortable: true },
  { name: "Last Open", uid: "lastOpen", sortable: true },
  { name: "Team", uid: "team" },
  { name: "Email", uid: "email" },
  { name: "Status", uid: "status", sortable: true },
  { name: "Actions", uid: "actions" },
];

const columns3 = [
  { name: "ID", uid: "id", sortable: true },
  { name: "File Name", uid: "name", sortable: true },
  { name: "Status", uid: "status", sortable: true },
];

// Define options for file types
const fileTypeOptions = [
  "docx", "pdf", "txt", "psd", "ai", "jpg", "jpeg", "png", "psb", 
  "svg", "mp4", "avi", "mov", "webm", "xlsx", "csv", "pptx", 
  "indd", "ZIP", "RAR", "ARJ"
].map(type => ({ name: type.toUpperCase(), uid: type.toLowerCase() }));

// Define options for status
const statusOptions = [
  { name: "Active", uid: "active" },
  { name: "Paused", uid: "paused" },
  { name: "Vacation", uid: "vacation" },
  { name: "PPT", uid: "PPT" },
];

const columns4 = [
  {name: "ID", uid: "id", sortable: true},
  {name: "full_name", uid: "name", sortable: true},
  {name: "Type", uid: "type", sortable: true},
  {name: "Duration", uid: "Duration", sortable: true},
  { name: "Actions", uid: "actions" },
];

const fullFileTypesOptions = [
  {name: "MainFileIcon", uid: "MainFileIcon"},
  {name: "docx", uid: "docx"},
  {name: "pdf", uid: "pdf"},
  {name: "txt", uid: "txt"},
  {name: "psd", uid: "psd"},
  {name: "ai", uid: "ai"},
  {name: "jpg", uid: "jpg"},
  {name: "jpeg", uid: "jpeg"},
  {name: "png", uid: "png"},
  {name: "psb", uid: "psb"},
  {name: "svg", uid: "svg"},
  {name: "mp4", uid: "mp4"},
  {name: "avi", uid: "avi"},
  {name: "mov", uid: "mov"},
  {name: "webm", uid: "webm"},
  {name: "xlsx", uid: "xlsx"},
  {name: "csv", uid: "csv"},
  {name: "pptx", uid: "pptx"},
  {name: "indd", uid: "indd"},
  {name: "ZIP", uid: "ZIP"},
  {name: "RAR", uid: "RAR"},
  {name: "folder", uid: "folder"},
];


const columns5 = [
  {name: "ID", uid: "id", sortable: true},
  {name: "FILE NAME", uid: "name", sortable: true},
  {name: "TYPE", uid: "type", sortable: true},
  {name: "Shared with", uid: "SharedWith", sortable: true},
  { name: "Actions", uid: "actions" },
];



// Export the necessary structures for use in other components
export { columns1, columns2, columns3, columns4, columns5, fileTypeOptions, fullFileTypesOptions, statusOptions };
