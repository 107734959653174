 import useNotificationStore from 'src/store/api/useNotificationStore';
import { useSidebarStore } from 'src/store/layout/useSidebarStore';
 
const useListAllNotifications = () => {
  const { selectedItemId } = useSidebarStore((state) => ({
    selectedItemId: state.selectedItemId,
  }));

  const { listAllNotificationsItems, isLoading, error, successMessage, items, currentPage, totalPages, totalItems } = useNotificationStore((state) => ({
    listAllNotificationsItems: state.listAllNotificationsItems,
    isLoading: state.isLoading,
    error: state.error,
    successMessage: state.successMessage,
    items: state.allNotificationsItems,
    currentPage: state.currentPage,
    totalPages: state.totalPages,
    totalItems: state.totalItems,
  }));

  return { listAllNotificationsItems, isLoading, error, successMessage, items, currentPage, totalPages, totalItems, ID: selectedItemId };
};

export default useListAllNotifications;
