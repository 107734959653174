import React from "react";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Homepage from "../pages/Homepage";
import Files from "../pages/Files";
import Shared from "../pages/Shared";
import Settings from "../pages/Settings";
import Favorite from "../pages/Favorite";
import Notifications from "../pages/Notifications";
import LoginForm from "../pages/LoginForm";
import LandingPage from "src/pages/LandingPage";
import Recovery from "src/pages/Recovery";
import PageNotFound404 from "src/erorrs/PageNotFound404";
import LoginSaff from "src/pages/LoginSaff";
import PublicAccessPage from "src/pages/PublicAccessPage";
import ProtectedRoute from "./ProtectedRoute";
import SharedWithFiles from "src/pages/SharedWithFiles";
import SharedByFiles from "src/pages/SharedByFiles";
import { useAuthStore } from "src/store/api/useAuthStore";
import InternalServerError500 from "src/erorrs/InternalServerError500";
import PublicSharedFiles from "src/pages/PublicSharedFiles";

const Routing: React.FC = () => {
  useTranslation();
  const { isSaff, permissions } = useAuthStore((state) => ({
    isSaff: state.isSaff,
    permissions: state.permissions,
  }));

  const { is_supper_admin, is_administrators } = permissions || {};

  return (
    <>
      <Routes>
        <Route path="/LoginMy" element={<LoginSaff />} />
        <Route path="/Home" element={<LandingPage />} />
        <Route path="/log1" element={<LoginForm />} />
        <Route path="/*" element={<PageNotFound404 />} />
        <Route path="/500" element={<InternalServerError500 />} />
        <Route path="/PublicAccessPage" element={<PublicAccessPage />} />
        <Route path="/publicSharedFileList" element={<PublicSharedFiles />} />
        <Route path="/404" element={<PageNotFound404 />} />

        {/* Protected Routes */}
        <Route element={<ProtectedRoute />}>
          {isSaff && (
            <>
              <Route path="/homepage" element={<Homepage />} />
              <Route path="/Favorite" element={<Favorite />} />
              <Route path="/Notifications" element={<Notifications />} />
              <Route path="/fileDetails" element={<Files />} />
              <Route path="/sharedWithFileList" element={<SharedWithFiles />} />
              <Route path="/sharedByFileList" element={<SharedByFiles />} />
              <Route path="/Shared" element={<Shared />} />
              <Route path="/Recovery" element={<Recovery />} />
              {is_supper_admin || is_administrators ? (
                <>
                  {is_supper_admin && <Route path="/Settings" element={<Settings />} />}
                  {is_administrators && <Route path="/Settings" element={<Settings />} />}
                </>
              ) : null}
            </>
          )}
          {!isSaff && (
            <>
              <Route path="/Favorite" element={<Favorite />} />
              <Route path="/Notifications" element={<Notifications />} />
              <Route path="/Shared" element={<Shared />} />
              <Route path="/Recovery" element={<Recovery />} />
              <Route path="/sharedWithFileList" element={<SharedWithFiles />} />
            </>
          )}
        </Route>
      </Routes>
    </>
  );
};

export default Routing;
