import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Button, Input, useDisclosure } from '@nextui-org/react';
import { Pagination } from 'antd'; // Importing Ant Design Pagination
import { PlusIcon } from '../../assets/icons/PlusIcon';
import { SearchIcon } from '../../assets/icons/SearchIcon';
import { useTranslation } from 'react-i18next';
import AddNewFolderModal from '../modals/AddNewFolderModal';
import useListAllFoldersFiles from 'src/hooks/useListAllFolders1';
import useFilesStore from 'src/store/layout/useFilesGridListStoreLayout';
import FileCard from './cards/FileCard';
import FolderCard from './cards/FolderCard';
import { useDropzone } from 'react-dropzone';
import { useUploadFilesHooks } from 'src/hooks/useUploadFilesHooks';

export default function FilesGridList() {
  const { t, i18n } = useTranslation();
  const {
    filterValue,
    setFilterValue,
    selectedKeys,
    rowsPerPage,
    setRowsPerPage,
    sortDescriptor,
    setPage,
  } = useFilesStore();

  const { isOpen: isAddNewOpen, onOpen: onOpenAddNew, onClose: onCloseAddNew } = useDisclosure();
  const [isDragging, setIsDragging] = useState(false);
  const { fetchFolders, totalPages, totalItems, items: folderItems, currentPage } = useListAllFoldersFiles();
  const { onDrop, handleUpload } = useUploadFilesHooks();

  useDropzone({
    onDrop: (acceptedFiles) => {
      onDrop(acceptedFiles);
      handleUpload(); // Automatically start uploading the files after dropping
    },
  });

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    onDrop(droppedFiles);
    handleUpload(); // Automatically upload files after drop
  }, [onDrop, handleUpload]);

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handlePageChange = useCallback((newPage: number) => {
    console.log("Changing page to:", newPage);
    setPage(newPage);
    fetchFolders(newPage);  // Pass the new page directly to fetchFolders
  }, [setPage, fetchFolders]);

  const onRowsPerPageChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRowsPerPage(Number(e.target.value));
      setPage(1); // Reset to the first page when rows per page change
    },
    [setRowsPerPage, setPage]
  );

  const hasSearchFilter = Boolean(filterValue);

  const filteredItems = useMemo(() => {
    if (!folderItems) return [];
    if (!hasSearchFilter) return folderItems;

    return folderItems.filter((folder) =>
      folder?.name?.toLowerCase().includes(filterValue.toLowerCase())
    );
  }, [folderItems, hasSearchFilter, filterValue]);

  const paginatedItems = useMemo(() => filteredItems, [filteredItems]);

  const sortedItems = useMemo(() => {
    return [...paginatedItems].sort((a, b) => {
      const first = a[sortDescriptor.column as keyof typeof a] as number;
      const second = b[sortDescriptor.column as keyof typeof b] as number;
      const cmp = first < second ? -1 : first > second ? 1 : 0;
      return sortDescriptor.direction === 'descending' ? -cmp : cmp;
    });
  }, [sortDescriptor, paginatedItems]);

  const onSearchChange = useCallback((value?: string) => {
    setFilterValue(value || '');
  }, [setFilterValue]);

  useEffect(() => {
    setPage(1);
    fetchFolders(1); // Refetch data for the first page
  }, [i18n.language, setPage, fetchFolders]);

  const topContent = useMemo(() => (
    <div className="flex flex-col gap-4 w-[96.5%]">
      <div className="flex justify-between gap-3 items-end">
        <Input
          isClearable
          classNames={{
            base: 'lg:w-full sm:max-w-[44%]',
            inputWrapper: 'border-1 bg-white',
          }}
          placeholder={t('SearchByFileName')}
          startContent={<SearchIcon className="text-default-300" />}
          value={filterValue}
          variant="bordered"
          onClear={() => setFilterValue('')}
          onValueChange={onSearchChange}
        />
        <div className="flex gap-3">
          <Button
            className="bg-success text-background"
            endContent={<PlusIcon />}
            size="sm"
            onClick={onOpenAddNew}
          >
            {t('AddNew')}
          </Button>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <span className="text-default-400 text-small">
          {t('TotalFiles')}: {totalItems}
        </span>
        {/* not working yet  */}
        {/* <div className="flex items-center">
          <label className="mr-2 text-default-400 text-small">
            {t('RowsPerPage')}
          </label>
          <select
            className="border border-default-200 rounded px-2 py-1"
            value={rowsPerPage}
            onChange={onRowsPerPageChange}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div> */}
      </div>
    </div>
  ), [t, filterValue, onSearchChange, onOpenAddNew, totalItems, rowsPerPage, onRowsPerPageChange, setFilterValue]);

  const bottomContent = useMemo(() => (
    <div className="py-2 px-2 flex justify-between items-center mt-4">
      <Pagination
        align="start"
        defaultCurrent={currentPage}
        total={totalPages * rowsPerPage} // Adjust the total accordingly
        onChange={handlePageChange}
        showSizeChanger={false} // Disable changing page size if not needed
      />
      {/* <span className="text-small text-default-400">
        {selectedKeys === 'all'
          ? `${t('AllItemsSelected')}`
          : `${selectedKeys.size} ${t('of')} ${paginatedItems.length} ${t('selected')}`}
      </span> */}
    </div>
  ), [currentPage, totalPages, rowsPerPage, handlePageChange, selectedKeys, t, paginatedItems.length]);

  return (
    <div
      key={`${i18n.language}-${isDragging}`} // Force re-render of the entire component when the language or drag state changes
      style={{ zIndex: '1000' }}
      className={`w-full p-4 ${isDragging ? 'border-1.5 border-dashed border-success h-full bg-[#2a835f48] rounded-lg' : ''}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {topContent}
      <div className="flex flex-wrap gap-6 justify-start items-center mt-4">
        {sortedItems.map((item) => (
          item.type === 'folder' ? (
            <FolderCard key={item.folder_id} folder={item} />
          ) : (
            <FileCard key={item.file_id} file={item} />
          )
        ))}
      </div>
      {bottomContent}
      <AddNewFolderModal isOpen={isAddNewOpen} onClose={onCloseAddNew} />
    </div>
  );
}