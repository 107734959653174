import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthStore } from 'src/store/api/useAuthStore';

const ProtectedRoute: React.FC = () => {
  const token = useAuthStore((state) => state.token)||'';

  useEffect(() => {
  }, [token]);

  return token ? <Outlet /> : <Navigate to="/home" />;
};

export default ProtectedRoute;