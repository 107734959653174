// src/main.tsx
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { QueryClient, QueryClientProvider } from "react-query";
import "./i18n"; // Import the i18n configuration
import { useLanguageStore } from "./store/layout/useLanguageStore";
import { BrowserRouter as Router } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/react";
import App from "./App";
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root")!);

const Main = () => {
  const { direction } = useLanguageStore();

  React.useEffect(() => {
    document.documentElement.dir = direction;
  }, [direction]);

  return <App />;
};

root.render(
  <Router basename={process.env.PUBLIC_URL}> {/* Wrap with Router and set basename */}
    <NextUIProvider>
      <QueryClientProvider client={queryClient}>
        <Main />
      </QueryClientProvider>
    </NextUIProvider>
  </Router>
);
