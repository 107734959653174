import useTrashStore from 'src/store/api/useTrashStore';
import { useSidebarStore } from 'src/store/layout/useSidebarStore';
 
const useListAllTrash = () => {
  const { selectedItemId } = useSidebarStore((state) => ({
    selectedItemId: state.selectedItemId,
  }));

  const { fetchTrashedItems, isLoading, error, successMessage, items, currentPage, totalPages, totalItems } = useTrashStore((state) => ({
    fetchTrashedItems: state.fetchTrashedItems,
    isLoading: state.isLoading,
    error: state.error,
    successMessage: state.successMessage,
    items: state.trashedItems,
    currentPage: state.currentPage,
    totalPages: state.totalPages,
    totalItems: state.totalItems,
  }));

  return { fetchTrashedItems, isLoading, error, successMessage, items, currentPage, totalPages, totalItems, ID: selectedItemId };
};

export default useListAllTrash;
