import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import MainFileIcon from "../../assets/icons/MainFileIcon";
 
interface PublicFileDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileType: string;
  fileName: string;
  fileOwner: string;
  fileDescription: string;
  fileSize: number;
}

const PublicFileDetailsModal: React.FC<PublicFileDetailsModalProps> = ({ isOpen, onClose, fileType, fileName, fileOwner ,fileSize, fileDescription }) => {
  const { t } = useTranslation();
 
   return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          <div className="flex items-end justify-start">
            <MainFileIcon height={30} width={30} />
            {t('FileDetails')}
          </div>
        </ModalHeader>

        <ModalBody>
            <Table isStriped aria-label="Example static collection table">
            <TableHeader>
              <TableColumn>{t('Type')}</TableColumn>
              <TableColumn>{fileType}</TableColumn>
            </TableHeader>

            <TableBody>
            <TableRow key="1">
                  <TableCell>{t('Name')}</TableCell>
                  <TableCell>{fileName}</TableCell>
                </TableRow>

              <TableRow key="2">
                <TableCell>{t('Size')}</TableCell>
                <TableCell>{fileSize}</TableCell>
              </TableRow>

              <TableRow key="3">
              <TableCell>{t('Owner')}</TableCell>
              <TableCell>{fileOwner}</TableCell>
              </TableRow>

              <TableRow key="4">
              <TableCell>{t('Description')}</TableCell>
              <TableCell>{fileDescription}</TableCell>
              </TableRow>


            </TableBody>
          </Table>
 </ModalBody>

        <ModalFooter>
          <Button color="danger" variant="light" onPress={onClose}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PublicFileDetailsModal;