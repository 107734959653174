import React, { useEffect } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  DropdownSection,
} from "@nextui-org/react";
import UpDownIcon from "../../assets/icons/UpDownIcon";
import { useTranslation } from "react-i18next";
import AddYearFolderModal from "../modals/AddYearFolderModal";
import { useSidebarStore } from "../../store/layout/useSidebarStore";
import { useListAllDirectories } from 'src/hooks/useListAllDirectories';
import { useAuthStore } from 'src/store/api/useAuthStore';

const YearDropdown: React.FC = () => {
  const { t } = useTranslation();
  const {
    selectedItem,
    selectedItemId,
    setSelectedItem,
    setSelectedItemId,
    isModalOpen,
    setIsModalOpen,
  } = useSidebarStore();

  const { directories } = useListAllDirectories();
  const is_super_admin = useAuthStore((state) => state.permissions?.is_supper_admin);
  // Fetch directories only once when the component mounts


  // Set the initial selected item and ID, and fetch folders only once
  useEffect(() => {
    if (directories && directories.length > 0) {
      if (!selectedItemId && !selectedItem) {
        const firstDirectory = directories[directories.length - 1];
        setSelectedItem(firstDirectory.name || ""); // Set the name of the first directory
        setSelectedItemId(firstDirectory.directory_id || ""); // Set the ID of the first directory
        // console.log("Selected item: ", firstDirectory);
      }
      // console.log("wwww")
    }
  }, [directories, selectedItemId, selectedItem, setSelectedItem, setSelectedItemId]);

  return (
    <>
      <div className="p-4">
        <Dropdown placement="top">
          <DropdownTrigger>
            <div className="dropdown-toggle flex items-center justify-between p-2 border rounded-lg cursor-pointer bg-success">
              <div className="flex justify-between items-center w-full">
                <div>
                  <div className="lead-text font-bold text-gray-100">
                    {selectedItem || t("Select Year")}
                  </div>
                  <div className="sub-text text-sm text-gray-100">
                    {t("Year")}
                  </div>
                </div>
                <UpDownIcon color="white" />
              </div>
            </div>
          </DropdownTrigger>
          {is_super_admin ? (<DropdownMenu
            aria-label="Year selection"
            onAction={(key) => {
              if (key === "addFolder") {
                setIsModalOpen(true);
              } else {
                const selectedDirectory = directories?.find((dir) => dir.directory_id === key);
                setSelectedItem(selectedDirectory ? selectedDirectory.name || "1" : "1");
                setSelectedItemId(selectedDirectory?.directory_id || "");
              }
            }}
          >
            <DropdownSection>
              {directories?.map((directory) => (
                <DropdownItem
                  key={directory.directory_id}
                  value={directory.directory_id}
                  className={selectedItemId === directory.directory_id ? "active" : ""}
                >
                  {directory.name}
                </DropdownItem>
              )) || []}
            </DropdownSection>
            <DropdownItem key="addFolder" value="addFolder">
              {t("Add New Year Folder")}
            </DropdownItem>
          </DropdownMenu>) : (<DropdownMenu
            aria-label="Year selection"
            onAction={(key) => {
              if (key === "addFolder") {
                setIsModalOpen(true);
              } else {
                const selectedDirectory = directories?.find((dir) => dir.directory_id === key);
                setSelectedItem(selectedDirectory ? selectedDirectory.name || "1" : "1");
                setSelectedItemId(selectedDirectory?.directory_id || "");
              }
            }}
          >
            <DropdownSection>
              {directories?.map((directory) => (
                <DropdownItem
                  key={directory.directory_id}
                  value={directory.directory_id}
                  className={selectedItemId === directory.directory_id ? "active" : ""}
                >
                  {directory.name}
                </DropdownItem>
              )) || []}
            </DropdownSection>
          </DropdownMenu>)}
        </Dropdown>
      </div>
      <AddYearFolderModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(!isModalOpen)}
      />
    </>
  );
};

export default YearDropdown;
