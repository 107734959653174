import { create } from 'zustand';
import { Selection, SortDescriptor } from '@nextui-org/react';


interface SharedByFilesState {
  filterValue: string;
  setFilterValue: (value: string) => void;
  selectedKeys: Selection;
  setSelectedKeys: (keys: Selection) => void;
  statusFilter: Selection;
  setStatusFilter: (filter: Selection) => void;
  rowsPerPage: number;
  setRowsPerPage: (rows: number) => void;
  sortDescriptor: SortDescriptor;
  setSortDescriptor: (descriptor: SortDescriptor) => void;
  page: number;
  setPage: (page: number) => void;
}

const useSharedByFilesStore = create<SharedByFilesState>((set) => ({
  filterValue: '',
  setFilterValue: (value) => set({ filterValue: value }),
  selectedKeys: new Set([]),
  setSelectedKeys: (keys) => set({ selectedKeys: keys }),
  statusFilter: 'all',
  setStatusFilter: (filter) => set({ statusFilter: filter }),
  rowsPerPage: 10,
  setRowsPerPage: (rows) => set({ rowsPerPage: rows }),
  sortDescriptor: { column: 'id', direction: 'ascending' },
  setSortDescriptor: (descriptor) => set({ sortDescriptor: descriptor }),
  page: 1,
  setPage: (page) => set({ page: page }),
}));

export default useSharedByFilesStore;


interface FolderState {
  liked: boolean;
  setLiked: (liked: boolean) => void;
}

export const useFolderStore = create<FolderState>((set) => ({
  liked: false,
  setLiked: (liked) => set({ liked }),
}));