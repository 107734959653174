import { PermissionsUser } from 'src/pages/LoginSaff';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
interface AuthState {
  token: string | null;
  permissions: PermissionsUser | null;
  nameEn: string | null; // Add a nameEn field
  isSaff: boolean;
  setToken: (token: string) => void;
  setPermissions: (permissions: PermissionsUser, nameEn: string) => void; // Update the method to accept nameEn
  clearToken: () => void;
}

export const useAuthStore = create(persist<AuthState>(
  (set) => ({
    token: '', // Initialize token, can be null or empty
    permissions: null, // Initialize permissions as null
    nameEn: null, // Initialize nameEn as null
    isSaff: false, // Initialize isSaff as false
    setToken: (token: string) => set({ token }), // Setter for token
    setPermissions: (permissions: PermissionsUser, nameEn: string) => set({ 
      permissions, 
      nameEn, // Set the nameEn field
      isSaff: permissions?.is_saff ?? false // Calculate and set isSaff
    }), 
    clearToken: () => set({ token: null, permissions: null, nameEn: null, isSaff: false }), // Clear token, permissions, nameEn, and reset isSaff
  }),
  {
    name: 'auth-storage', // Name for localStorage key
    getStorage: () => localStorage, // Define localStorage as the storage type
  }
));
