import { create } from 'zustand';
import { assignRolesToUser, listUsersAll, listUsersSAFFAll, registerUser, updateUser } from 'src/services/api/API';
import { ListUsersRegister, User } from '../../types/api/UserList';
import { useAuthStore } from './useAuthStore';
import { ResponsCreateRole } from './useRoleStore';

interface UserStore {
  // State for Users
  filterValueUsers: string;
  rowsPerPageUsers: number;
  pageUsers: number;
  totalPagesUsers: number;
  totalUsersUsers: number;
  users: User[];
  lastFetchedPageUsers: number | null;

  // State for UsersSAFF
  filterValueUsersSAFF: string;
  rowsPerPageUsersSAFF: number;
  pageUsersSAFF: number;
  totalPagesUsersSAFF: number;
  totalUsersUsersSAFF: number;
  usersSAFF: User[];
  lastFetchedPageUsersSAFF: number | null;

  // Loading state
  isLoading: boolean;

  // User registration state
  nameEn: string;
  nameAr: string;
  email: string;
  phone: string;
  password: string;
  roles: string[];

  // Registration status and message
  registrationStatus: 'success' | 'error' | null;
  registrationMessage: string;

  assignRolesStatus: 'success' | 'error' | null;
  assignRolesMessage: string;

  // New action for assigning roles to a user
  assignRolesToUser: (userId: string, roles: string[]) => Promise<ResponsCreateRole>;

  // Setters and actions for Users
  setFilterValueUsers: (value: string) => void;
  setRowsPerPageUsers: (value: number) => void;
  setPageUsers: (value: number) => void;
  fetchUsers: (page: number, rowsPerPage: number) => Promise<void>;

  // Setters and actions for UsersSAFF
  setFilterValueUsersSAFF: (value: string) => void;
  setRowsPerPageUsersSAFF: (value: number) => void;
  setPageUsersSAFF: (value: number) => void;
  fetchUsersSAFF: (page: number, rowsPerPage: number) => Promise<void>;

  // Setters and actions for user registration
  setNameEn: (nameEn: string) => void;
  setNameAr: (nameAr: string) => void;
  setEmail: (email: string) => void;
  setPhone: (phone: string) => void;
  setPassword: (password: string) => void;
  setRoles: (roles: string[]) => void;
  reset: () => void;
  registerUser: () => Promise<void>;
  resetRegistrationStatus: () => void;
  updateUser: (userId: string, updatedUser: User) => Promise<ResponsCreateRole>; // Ensure this is properly typed
}

// Create the Zustand store
export const useUserListStore = create<UserStore>((set, get) => ({
  // Initial state for Users
  filterValueUsers: '',
  rowsPerPageUsers: 10,
  pageUsers: 1,
  totalPagesUsers: 1,
  totalUsersUsers: 0,
  users: [],
  lastFetchedPageUsers: null,

  // Initial state for UsersSAFF
  filterValueUsersSAFF: '',
  rowsPerPageUsersSAFF: 10,
  pageUsersSAFF: 1,
  totalPagesUsersSAFF: 1,
  totalUsersUsersSAFF: 0,
  usersSAFF: [],
  lastFetchedPageUsersSAFF: null,

  // Loading state
  isLoading: false,

  // Initial state for user registration
  nameEn: '',
  nameAr: '',
  email: '',
  phone: '',
  password: '',
  roles: [],

  // Initial state for registration status and message
  registrationStatus: null,
  registrationMessage: '',

  assignRolesStatus: null,
  assignRolesMessage: '',
  // Setters and actions for Users
  setFilterValueUsers: (value: string) => set({ filterValueUsers: value }),
  setRowsPerPageUsers: (value: number) => set({ rowsPerPageUsers: value }),
  setPageUsers: (value: number) => set({ pageUsers: value }),

  fetchUsers: async (page, rowsPerPage) => {
    const { lastFetchedPageUsers } = get();
    const token = useAuthStore.getState().token;
    if (!token || lastFetchedPageUsers === page) return;

    set({ isLoading: true });

    try {
      const response = await listUsersAll(token, page, rowsPerPage);
      console.log("Fetched Users Data:", response.users);
      set({
        users: response.users || [],
        totalPagesUsers: response.totalPages || 1,
        totalUsersUsers: response.totalUsers || 0,
        lastFetchedPageUsers: page,
      });
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      set({ isLoading: false });
    }
  },

  // Setters and actions for UsersSAFF
  setFilterValueUsersSAFF: (value: string) => set({ filterValueUsersSAFF: value }),
  setRowsPerPageUsersSAFF: (value: number) => set({ rowsPerPageUsersSAFF: value }),
  setPageUsersSAFF: (value: number) => set({ pageUsersSAFF: value }),

  fetchUsersSAFF: async (page, rowsPerPage) => {
    const { lastFetchedPageUsersSAFF } = get();
    const token = useAuthStore.getState().token;
    if (!token || lastFetchedPageUsersSAFF === page) return;

    set({ isLoading: true });

    try {
      const response = await listUsersSAFFAll(token, page, rowsPerPage);
      console.log("Fetched UsersSAFF Data:", response.users);
      set({
        usersSAFF: response.users || [],
        totalPagesUsersSAFF: response.totalPages || 1,
        totalUsersUsersSAFF: response.totalUsers || 0,
        lastFetchedPageUsersSAFF: page,
      });
    } catch (error) {
      console.error("Failed to fetch usersSAFF:", error);
    } finally {
      set({ isLoading: false });
    }
  },

  // Setters and actions for user registration
  setNameEn: (nameEn) => set({ nameEn }),
  setNameAr: (nameAr) => set({ nameAr }),
  setEmail: (email) => set({ email }),
  setPhone: (phone) => set({ phone }),
  setPassword: (password) => set({ password }),
  setRoles: (roles) => set({ roles }),
  reset: () =>
    set({
      nameEn: '',
      nameAr: '',
      email: '',
      phone: '',
      password: '',
      roles: [],
    }),

    registerUser: async () => {
      const state = get();
      const token = useAuthStore.getState().token || '';
      console.log('Registering user:', token);
    
      const requestData: ListUsersRegister = {
        email: state.email,
        password: state.password,
        full_name_en: state.nameEn,
        full_name_ar: state.nameAr,
        phone: state.phone,
        // role_id: state.roles[0], // Removed role_id as it's not required
      };
    
      try {
        const response = await registerUser(requestData, token);
    
        set({
          registrationStatus: response.success ? 'success' : 'error',
          registrationMessage: response.message || (response.success ? 'User registered successfully!' : 'Failed to register user.'),
        });
    
        if (response.success) {
          console.log('User registered successfully:', response);
          get().reset();
          console.log('Fetching users after registration...');
          
          // Reset lastFetchedPageUsers before fetching users
          set({ lastFetchedPageUsers: null });
          
          await get().fetchUsers(state.pageUsers, state.rowsPerPageUsers); 
          console.log('Users fetched after registration');
        }
    
      } catch (error) {
        console.error('Error registering user:', error);
        set({
          registrationStatus: 'error',
          registrationMessage: 'An error occurred while registering the user.',
        });
      }
    },

  resetRegistrationStatus: () =>
    set({
      registrationStatus: null,
      registrationMessage: '',
    }),

  updateUser: async (userId: string, updatedUser: User) => {
    const state = get();
    const token = useAuthStore.getState().token || '';

    try {
      const response = await updateUser(userId, updatedUser, token);

      set({
        registrationStatus: response.success ? 'success' : 'error',
        registrationMessage: response.message || (response.success ? 'User updated successfully!' : 'Failed to update user.'),
      });

      if (response.success) {
        console.log('User updated successfully:', response);
        get().reset();
        console.log('Fetching users after update...');
        await get().fetchUsers(state.pageUsers, state.rowsPerPageUsers); // Fetch users directly after update
        console.log('Users fetched after update');
      }

      return response;  // Return the response

    } catch (error) {
      console.error('Error updating user:', error);
      const errorResponse = { success: false, message: 'An error occurred while updating the user.' };
      set({
        registrationStatus: 'error',
        registrationMessage: errorResponse.message,
      });
      return errorResponse;  // Return an error response
    }
  },
  assignRolesToUser: async (userId: string, roles: string[]): Promise<ResponsCreateRole> => {
    const token = useAuthStore.getState().token || '';
  
    try {
      const response = await assignRolesToUser(token, userId, roles);
  
      set({
        assignRolesStatus: response.success ? 'success' : 'error',
        assignRolesMessage: response.message || (response.success ? 'Roles assigned successfully.' : 'Failed to assign roles.'),
      });
  
      if (response.success) {
        console.log('Roles assigned successfully:', response);
        get().reset();  // Reset form or state if necessary after assigning roles
      }
  
      return response; // Return the API response
  
    } catch (error) {
      console.error('Error assigning roles:', error);
      set({
        assignRolesStatus: 'error',
        assignRolesMessage: (error as Error).message,
      });
  
      throw new Error(`Failed to assign roles: ${(error as Error).message}`);
    }
  },
  
}));

interface EditRolesModalState {
  isOpen: boolean;
  nameEn: string;
  nameAr: string;
  email: string;
  phone: string;
  roles: string[];
  password: string;
  setIsOpen: (isOpen: boolean) => void;
  setNameEn: (nameEn: string) => void;
  setNameAr: (nameAr: string) => void;
  setEmail: (email: string) => void;
  setPhone: (phone: string) => void;
  setRoles: (roles: string[]) => void;
  setPassword: (password: string) => void;
  reset: () => void;
}

export const useEditRolesModalStore = create<EditRolesModalState>((set) => ({
  isOpen: false,
  nameEn: "",
  nameAr: "",
  email: "",
  phone: "",
  roles: [],
  password: "",
  setIsOpen: (isOpen) => set({ isOpen }),
  setNameEn: (nameEn) => set({ nameEn }),
  setNameAr: (nameAr) => set({ nameAr }),
  setEmail: (email) => set({ email }),
  setPhone: (phone) => set({ phone }),
  setRoles: (roles) => set({ roles }),
  setPassword: (password) => set({ password }),
  reset: () =>
    set({
      nameEn: "",
      nameAr: "",
      email: "",
      phone: "",
      roles: [],
      password: "",
    }),
}));