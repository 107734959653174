import React from "react";
import { useTranslation } from "react-i18next";
import { useLanguageStore } from "../../store/layout/useLanguageStore";
import ArIcon from "../../assets/icons/ArIcon";
import EnIcon from "../../assets/icons/EnIcon";

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const { setLanguage } = useLanguageStore();

  const changeLanguage = (language: string) => {
    const direction = language === 'ar' ? 'rtl' : 'ltr';
    i18n.changeLanguage(language);
    setLanguage(language, direction);
    document.documentElement.dir = direction;
  };

  return (
    <button
      onClick={() => changeLanguage(i18n.language === 'en' ? 'ar' : 'en')}
    >
      {i18n.language === 'en' ? <ArIcon color="#034121" width={30}/> : <EnIcon color="#034121" width={30}/>}
    </button>
  );
};

export default LanguageSwitcher;
